import React, { useState } from "react";
import { Helmet } from 'react-helmet-async';
import "./partners.css";
import IntroductionSection from "../../components/IntroductionSection/IntroductionSection";
import img from "../../assets/png/Rectangle 184.png";
import Section16 from "../../components/section16/section16";
import brandImg from "../../assets/png/brand1.png";
import dataImg1 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 13.png'
import dataImg2 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 7.png'
import dataImg3 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 19.png'
import dataImg4 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 4.png'
import dataImg5 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 21.png'
import dataImg6 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 12.png'
import dataImg7 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 3.png'
import dataImg8 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 9.png'
import dataImg9 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 8.png'
import dataImg10 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 1.png'
import dataImg11 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 14.png'
import dataImg12 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 16.png'
import dataImg13 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 11.png'
import dataImg14 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 17.png'
import dataImg15 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 2.png'
import dataImg16 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 20.png'
import dataImg17 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 10.png'
import dataImg18 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 15.png'
import dataImg19 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 6.png'
import dataImg20 from '../../assets/Logos_Pictures/Partners/Artivatic Partner Logo - 5.png'
import apiImg1 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 13.png'
import apiImg2 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 14.png'
import apiImg3 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 8.png'
import apiImg4 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 24.png'
import apiImg5 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 25.png'
import apiImg6 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 22.png'
import apiImg7 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 19.png'
import apiImg8 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 7.png'
import apiImg9 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 21.png'
import apiImg10 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 15.png'
import apiImg11 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 5.png'
import apiImg12 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 9.png'
import apiImg13 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 11.png'
import apiImg14 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 12.png'
import apiImg15 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 1.png'
import apiImg16 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 4.png'
import apiImg17 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 2.png'
import apiImg18 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 23.png'
import apiImg19 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 20.png'
import apiImg20 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 6.png'
import apiImg21 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 26.png'
import apiImg22 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 18.png'
import apiImg23 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 17.png'
import apiImg24 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 16.png'
import apiImg25 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 3.png'
import apiImg26 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 10.png'
import Services from "../../components/services/services";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import Section14 from "../../components/section14/section14";
import partnerImg from '../../assets/Logos_Pictures/Partners Page.png'
export default function Partners() {
  const [currentSlide, setCurrentSlide] = useState(0);
  var description = "Artivatic makes all your tools work better by letting you integrate \n industry-leading software and custom apps."
  return (
    <>
      <Helmet>
        <title>Artivatic | Partners</title>
        <meta name="description" content="Empowering you to effectively compete in the digital world of new possibilities. Join us to build intelligent solutions together." />
        <meta name="keywords" content="Artivatic,Investors in Innovation,Data Partners,API Partners"/>
        <link rel="canonical" href="/partners" />

         {/* Facebook Meta Tags */}
         <meta property="og:url" content={`https://artivatic.ai/partners`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Artivatic | Partners`} />
        <meta property="og:description" content={`Empowering you to effectively compete in the digital world of new possibilities. Join us to build intelligent solutions together.`} />
        <meta property="og:image" content={`https://artivatic.ai/static/media/Partners%20Page.005c2088.png`}/>
        <meta property="og:image:width" content='1200' />
        <meta property="og:image:height" content='630' />

        {/* Twitter Meta Tags  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="artivatic.ai" />
        <meta property="twitter:url" content={`https://artivatic.ai/partners`} />
        <meta name="twitter:title" content={`Artivatic | Partners`} />
        <meta name="twitter:description" content={`Empowering you to effectively compete in the digital world of new possibilities. Join us to build intelligent solutions together.`} />
        <meta name="twitter:image" content={`https://artivatic.ai/static/media/Partners%20Page.005c2088.png`} />
        <meta property="og:image:width" content='1200' />
        <meta property="og:image:height" content='630' />

      </Helmet>
      <div id="partners">
        <IntroductionSection
          title={{ type: "spanmiddle", Text: "Investors in ", span: "Inn", Text2: "ovation" }}
          description={{ Text1: "Empowering you to effectively compete in the digital world of new possibilities. Join us to build intelligent solutions together. If you are data provider, hospital, clinics, brokers, insurance company, re-insurance, system integrator, Cloud Platform, Consulting Enterprise, Healthcare company or startup, we are here to collaborate together to build new innovations." }}
          button1={{ Text: "Get in Touch Now", Url: "/contact-us" }} image={partnerImg}
          classes={{
            titleClass: 'order-1',
            imageClass: "reactangle99 order-2",
            divClass: 'prod-width',
            descriptionClass: 'order-3',
            buttonsClass: 'order-4',
          }}
        >
        </IntroductionSection>
        <section className="vission">
          <div className="wrapper">
            <h1>
              We created a <span >vision statement</span> which is clear, short, and timeless. Every project gains the benifits of previous projects and experience. <span >Innovation</span> via Automation
            </h1>
          </div>
        </section>
        <section className="partners">
          <div className="wrapper">
            <Services
              title="Meet our partners"
              list={[
                "Data partners",
                "API partners",
              ]}
              button1={{
                Text: "Get in touch",
                Url: "/contact-us"
              }}
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
            ></Services>
          </div>
        </section>
        {
          currentSlide === 0 ? (
            <Section16
              list={[
                dataImg1, dataImg2, dataImg3, dataImg4, dataImg5, dataImg6, dataImg7, dataImg8, dataImg9, dataImg10, dataImg11, dataImg12, dataImg13, dataImg14, dataImg15, dataImg16, dataImg17, dataImg18, dataImg19, dataImg20
              ]}
              title="Data Partners"
              description={description}
              classes={{
                row1Class: 'padding-lr-88 gap-20'
              }}
            ></Section16>
          ) : ('')
        }
        {
          currentSlide === 1 ? (
            <Section16
              list={[
                apiImg1, apiImg2, apiImg3, apiImg4, apiImg5, apiImg6, apiImg7, apiImg8, apiImg9, apiImg10, apiImg11, apiImg12, apiImg13, apiImg14, apiImg15, apiImg16, apiImg17, apiImg18, apiImg19, apiImg20, apiImg21, apiImg22, apiImg23, apiImg24, apiImg25, apiImg26
              ]} title="API Partners" description={description}
              classes={{
                row1Class: 'padding-lr-88 gap-20'
              }}
            ></Section16>
          ) : ('')
        }
        <Section14
          title1="We provide personalized insurance products based on customer goals"
          button1={{ Text: "Know more about Atrivatic", Url: "/about-us" }}
          title2="Wanna know about the work culture at Artivatic office ?"
          button2={{ Text: "Check out our Work Culture", Url: "/teams" }}>
        </Section14>
        <NewsLetter
          title="Do you want to be a part of our Investors community?"
          btn1text="Get in Touch"
          btn1Route="/contact-us"
        >
        </NewsLetter>
      </div>
    </>
  )
}
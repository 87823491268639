import React from "react";

import integration1 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 1.webp'
import integration2 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 2.webp'
import integration3 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 3.webp'
import integration4 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 4.webp'
import integration5 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 5.webp'
import integration6 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 6.webp'
import integration7 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 7.webp'
import integration8 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 8.webp'
import integration9 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 9.webp'
import integration10 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 10.webp'
import integration11 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 11.webp'
import integration12 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 12.webp'
import integration13 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 13.webp'
import integration14 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 14.webp'
import integration15 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 15.webp'
import integration16 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 16.webp'
import integration17 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 17.webp'
import integration18 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 18.webp'
import integration19 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 19.webp'
import integration20 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 20.webp'
import integration21 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 21.webp'
import integration22 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 22.webp'
import integration23 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 23.webp'
import integration25 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 25.webp'
import integration26 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 26.webp'
import integration27 from '../../assets/Logos_Pictures/Native Integrations/Artivatic Integration Logo - 27.webp'

import "./Section5.css";


export default function Section5() {
  const clientList = [
    integration1, integration2, integration3, integration4, integration5, integration6, integration7, integration8, integration9, integration10, integration11, integration12, integration13, integration14, integration15, integration16, integration17, integration18, integration19, integration20, integration21, integration22, integration23, integration25, integration26, integration27
  ]
  return (
    <section className="section-5">
      <div className="wrapper">
        <div className="section-5__content">
          <h1>
            Over <span style={{ color: "var(--primary" }}>20+ </span>native
            integrations out of the box
          </h1>
          <hr className="hr-underline"></hr>
          <p>
            Artivatic makes all your tools work better by letting you integrate
            industry-leading software and custom apps.
          </p>
          <ul>
            <li>Import data from other apps</li>
            <li>Export form submission to other apps</li>
            <li>Trigger emails using other CRMs</li>
            <li>Add Shopify cart, Calendly etc. inside email</li>
          </ul>
        </div>
        <div className="img-container">
          {/* <Logomarquee
            Images={clientList}
            dir={'left'}
            speed={50}
          /> */}
          <div className="integrations-image-row-1">
            {
              clientList.map((c, index) => (
                <div className="client_image mx-height-30 integration-img-width" key={index}>
                  <img src={c} alt="companies-logo" />
                </div>
              ))
            }
          </div>
          
        </div>
      </div>
    </section>
  );
}

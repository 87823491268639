import React from "react";
import phoneIcon from "../../assets/vector/phone.svg";
import tollfreeIcon from "../../assets/vector/tollfree.svg";
import mailIcon from "../../assets/vector/mail.svg";
import faxIcon from "../../assets/vector/ic_fax.svg";

import { useHistory } from "react-router-dom";

import "./Card.css";
export default function Card(props) {
  const history = useHistory();
  const navigate = () => {
    if(ancLink !== '') {
      localStorage.setItem('ingographicfilename', JSON.stringify(title));
      localStorage.setItem('ingographicfilelink', JSON.stringify(ancLink));
      history.push({pathname:`/resources/${resource_type}/view/${tag}/pdf`});
    }
  }
  const { resource_type,type, tag, title, text, btnText, properties, ancLink, cardImg, telephn, mail, tollfree, fax, from ,flag,openframe} = props;
  return (
    <div className={`card ${(type ? type : '')} `}>
      <img src={cardImg} alt="card-img" className="card__img" />
      <div className="card__info">
        {tag && <span className={`card__tag ${(flag==='resource_page' ? 'tage' : '')}`}>{tag}</span>}
        <h1 className="card__title">{title}</h1>
        <p className="card__text">{text}</p>
        {type === "hover" && (
          <div className="card__hover" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${cardImg})` }}>
            <h5 className="card__hover__title">{title}</h5>
            <p className="card__hover__text">{text}</p>
          </div>
        )}
        {type === "contact" && (
          <div className="card__contactdetails">
            {telephn ? (<div className="row aic">
              <img src={phoneIcon} className="icon" alt='contact-icon'></img>
              <label className="contact-label">{telephn}</label>
            </div>) : ''
            }
            {mail ? (<div className="row aic">
              <img src={mailIcon} className="icon" alt='contact-icon'></img>
              <p className="contact-label">{mail}</p>
            </div>) : ''
            }
            {tollfree ? (<div className="row aic">
              <img src={tollfreeIcon} className="icon" alt='contact-icon'></img>
              <label className="contact-label">{tollfree}</label>
            </div>) : ''
            }
            {fax ? (<div className="row aic">
              <img src={faxIcon} className="icon" alt='contact-icon'></img>
              <p className="contact-label">{fax}</p>
            </div>) : ''
            }
          </div>
        )}
        {btnText && !openframe && <a className="card__btn" href={ancLink} target={from === 'product_page_blog_section' ? '_blank' : ''}>{btnText}</a>}
        { openframe && btnText && <a className="card__btn" onClick={navigate}>{btnText}</a>}
      </div>
    </div>
  );
}

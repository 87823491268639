import React from "react";
import { Link } from 'react-router-dom';

import "./ContactStrip.css";
export default function ContactStrip() {


  //  for scrolling effect in Header 
  document.addEventListener('scroll', () => {
    
    
    if (document.documentElement.clientWidth > 1241) {
    
      if (window.pageYOffset !== 0) {
        document.getElementsByClassName('contact-strip')[0].style.display = 'none';
        document.getElementsByClassName('nav')[0].style.top = '0px';
        document.getElementsByClassName('generalmargintop')[0].style.marginTop = '64px';
      }
      else {
        document.getElementsByClassName('contact-strip')[0].style.display = 'block';
        document.getElementsByClassName('nav')[0].style.top = '36px';
        document.getElementsByClassName('generalmargintop')[0].style.marginTop = '100px';
      }
    }


  })
  return (
    <div className="contact-strip">
      <div className="wrapper">
        <div className="contact-strip__search-bar">
          <input name="search" type="text" placeholder="Search" autoComplete='off' />
        </div>
        <div className="contact-strip__details">
          <span className="number">08049719935</span>
          <span className="email">contact@artivatic.ai</span>
          <button className="contact"><Link to="/contact-us">Contact us</Link></button>
          <button className="support">Support</button>
          <select className="language" name="lang">
            <option value="EN">EN</option>
            <option value="JP">JP</option>
            <option value="RS">RS</option>
            <option value="QP">QP</option>
          </select>
        </div>
      </div>
    </div>
  );
}

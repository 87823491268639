import React from "react";
import './Note.css';

const Note = (props) => {
  return (
    <section className="note">
      <p className="text-primary">
        Security: The system is protected against unauthorized access (both physical and logical).
        Availability: The system is available for operation and use as committed or agreed.
        Processing Integrity: System processing is complete, accurate, timely, and authorized.
        Confidentiality: Information designated as confidential is protected as committed or agreed.
        Privacy: Personal information is collected, used, retained, disclosed, and destroyed in conformity with
        the commitments in the entity’s privacy notice and with criteria set forth in generally accepted privacy
        principles issued by the Government Authorities. For more information on our security policy and
        certifications, please contact contact@artivatic.ai
        Vulnerability Reporting
        <br />
        <br />
        <br />
        Artivatic Data Labs values the work done by security researchers in improving the security of our
        service offerings and we are committed to working with the community to verify, reproduce, and respond
        to legitimate reported vulnerabilities. To report a security issue please contact contact@artivatic.ai
      </p>
    </section>
  )
}

export default Note;
import React from 'react';
import './Balls.css';

const Balls = (props) => {
    return (
        <figure className="balls">
            <div className="ball"></div>
            <div className="ball ball-active"></div>
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
        </figure>
    )
}

export default Balls;
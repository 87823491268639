import React from "react";
import { Helmet } from 'react-helmet-async';
import "./customer.css";
import IntroductionSection from "../../components/IntroductionSection/IntroductionSection";
import customerLogo from "../../assets/png/brand1.png";
import Section17 from "../../components/section17/section17";
import introImage from "../../assets/png/Customer Page - Front.webp";
import Carousel from "../../components/Carousel/carousel";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import Testimonial from "../../components/Testimonial/Testimonial";
import client1 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - AEGON Life.webp'
import client2 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - Aditya Birla Life Insurance.webp'
import client3 from '../../assets/Logos_Pictures/Clients/Customer Logo - Bajaj Life.webp'
import client4 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - ICICI Prudential.webp'
import client5 from '../../assets/Logos_Pictures/Clients/Customer Logo - Canera HSBC.webp'
import client6 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - IFFCO TOKIO.webp'
import client7 from '../../assets/Logos_Pictures/Clients/Customer Logo - IDFC FIRST BANK.webp'
import client8 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - RenewBuy.webp'
import sbilogo from '../../assets/Logos_Pictures/Clients/sbilogo.png'
import clientNewgen from '../../assets/Logos_Pictures/Clients/newgen.png'
import Logomarquee from "../../components/Logomarquee/Logomarquee";

export default function Customer() {

  const brandsImg = [
    sbilogo,client1, client2, client3, client4, client5, client6, client7,client8,clientNewgen
  ]

  return (
    <>
      <Helmet>
        <title>Artivatic | Our Customers</title>
        <meta name="description" content="Why Choose Artivatic?.See What Our Customers say." />
        <meta name="keywords" content="Artivatic Customers,Clients."/>
        <link rel="canonical" href={`/customers`} />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={`https://artivatic.ai/customers`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Artivatic | Our Customers`}/>
        <meta property="og:description" content={`Why Choose Artivatic?.See What Our Customers say.`} />
        <meta property="og:image" content={`https://artivatic.ai/static/media/aklesh-singh.94c1b2e0.jpg`} />
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>

        {/* Twitter Meta Tags  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="artivatic.ai" />
        <meta property="twitter:url" content={`https://artivatic.ai/customers`} />
        <meta name="twitter:title" content={`Artivatic | Our Customers`} />
        <meta name="twitter:description" content={`Why Choose Artivatic?.See What Our Customers say.`} />
        <meta name="twitter:image" content={`https://artivatic.ai/static/media/aklesh-singh.94c1b2e0.jpg`} />
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>

      </Helmet>
      <div id="customer">
        <IntroductionSection title={{ type: "nospan", Text: "Artivatic products are super effective omnichannel marketing strategies and testing like no one else. Artivatic’s product has shown promising results during the pilot phase.  " }}
          description={{
            Text1: "Hannibal Mcdaniel - Product Manager & VP Product Management at Google"
          }}
          image={introImage}
          button1={{
            Text: "Request a Demo", Url: "/contact-us"
          }} button2={{
            Text: "Download Case Study", Url: "/contact-us"
          }}
          classes={{
            titleClass: 'quotes order-2 mt-60',
            imageClass: "order-1",


            descriptionClass: 'order-3',
            buttonsClass: 'order-4',
          }}
        >
        </IntroductionSection>
        <section className="customer-courosel">
          <div className="wrapper">
            <Logomarquee
              Images={brandsImg}
              dir={'left'}
              speed={50}
              classes={{
                marqueeClass: 'marquee-margin-bottom-40',
              }}
            />
          </div>
        </section>
        <div className="section17">
          <Section17
            title={{ Text: "Why choose", span: "Artivatic?" }}
            description="Instant On-boarding. Financial Inclusion. Financial Inclusion is made easier by Artivatic."
            list={[{
              Title: "60 Second On-boarding",
              Description: "Our insurance, brokers, distribution partners find navigating the supply & demand side of the industry chaotic.Our insurance, brokers, distribution partners."
            },
            {
              Title: "Indiastack APIs embedded",
              Description: "Our insurance, brokers, distribution partners find navigating the supply & demand side of the industry chaotic.Our insurance, brokers, distribution partners."
            }, {
              Title: "Raise customer conversion",
              className: "margin-left",
              Description: "Our insurance, brokers, distribution partners find navigating the supply & demand side of the industry chaotic.Our insurance, brokers, distribution partners."
            }, {
              Title: "Reduce Cost & Risk",
              Description: "Our insurance, brokers, distribution partners find navigating the supply & demand side of the industry chaotic.Our insurance, brokers, distribution partners."
            }
            ]}>

          </Section17>
        </div>
        <div className="productExperience">
          <NewsLetter
            title="Need to experience the product ?"
            btn1text="Take a quick Product Tour"
            btn1Route="/products/ausis"
          />
        </div>
        <Testimonial
          title={{
            type: 'doublespan',
            Text1: 'believes in ',
            Span1: 'Artivatic ',
            Span2: 'growing ',
            Text2: 'together with all our stakeholders.'
          }}
        />
        <NewsLetter
          title="Lets build next-gen insurance & health products"
          btn1text="Schedule a Demo"
          btn1Route="/contact-us"
          btn2text="Request Pricing"
          btn2Route="/contact-us"
          subscribeBtn="true"
        />
      </div >
    </>
  )
}
import React from "react";
import { Helmet } from 'react-helmet-async';
import IntroductionSection from "../../components/IntroductionSection/IntroductionSection"
import Carousel from "../../components/Carousel/carousel";
import img from "../../assets/png/brand1.png";
import introImage from "../../assets/png/Rectangle 186.png";
import workwithusImg from '../../assets/Logos_Pictures/2nd Image.jpg'
// import customer from "../../assets/customer.png";
import storyimg from "../../assets/png/Rectangle 199.png";
import "./aboutus.css";
import Section3 from "../../components/Section3/Section3";
import NewsLetterSection from "../../components/NewsLetter/NewsLetter";
import nativeImgMob from "../../assets/png/native-section.png";
import nativeImgDesk from "../../assets/png/native-section-desk.png";
import Section16 from "../../components/section16/section16";
import greenCircle from "../../assets/vector/green-circle.svg";
import Logomarquee from "../../components/Logomarquee/Logomarquee";
import sbilogonew from '../../assets/Logos_Pictures/Clients/image 16.png'
import aegonlife from '../../assets/Logos_Pictures/Clients/image 20.png'
import client1 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - AEGON Life.webp'
import client2 from '../../assets/Logos_Pictures/Clients/image 18.png'
import client3 from '../../assets/Logos_Pictures/Clients/image 21.png'
import client4 from '../../assets/Logos_Pictures/Clients/image 17.png'
import client5 from '../../assets/Logos_Pictures/Clients/image 19.png'
import client6 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - IFFCO TOKIO.webp'
import client7 from '../../assets/Logos_Pictures/Clients/Customer Logo - IDFC FIRST BANK.webp'
import client8 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - RenewBuy.webp'
import client9 from '../../assets/Logos_Pictures/Clients/image 23.png'
import client10 from '../../assets/Logos_Pictures/Clients/image 24.png'
import Section11 from "../../components/Section11/Section11";
import joinusImg from '../../assets/Logos_Pictures/Team Pictures/3.jpg'
import investor1 from '../../assets/Logos_Pictures/Investor Images/Gilda Ventures LOGO.png'
import investor2 from '../../assets/Logos_Pictures/Investor Images/IAN.png'
import investor3 from '../../assets/Logos_Pictures/Investor Images/kfintech-logo.png'
import investor4 from '../../assets/Logos_Pictures/Investor Images/logo_spark.png'
import investor5 from '../../assets/Logos_Pictures/Investor Images/Lucida Logo.png'
import investor6 from '../../assets/Logos_Pictures/Investor Images/scale venture_logo.png'
import investor7 from '../../assets/Logos_Pictures/Investor Images/Sense AI.png'
import investor8 from '../../assets/Logos_Pictures/Investor Images/SPARK 10.jpg'
import ourStoryImg from '../../assets/Logos_Pictures/Our Story - About Us.png'
import sbilogo from '../../assets/Logos_Pictures/Clients/sbilogo.png'

export default function Aboutus() {
  var description = "Artivatic makes all your tools work better by letting you integrate \n industry-leading software and custom apps."

  const clientList = [
    {
      file: sbilogonew,
      flag: 'sbi'
    },
    {
      file: aegonlife,
      flag: 'rect'
    },
    {
      file: client2,
      flag: 'square'
    },
    {
      file: client3,
      flag: 'rect'
    },
    {
      file: client4,
      flag: 'rect'
    },
    {
      file: client5,
      flag: 'rect'
    },
    {
      file: client8,
      flag: 'rect'
    },
    {
      file: client9,
      flag: 'square'
    },
    {
      file: client10,
      flag: 'rect'
    },

    // sbilogonew, aegonlife, client2, client3, client4, client5, client8, client9, client10
  ]

  // const vidSrc = 'https://youtu.be/IsyhzGL6vA0'

  return (
    <>
      <Helmet>
        <title>Artivatic | About Us</title>
        <meta name="description" content="Artivatic was born 3 years ago to empower insurance & healthcare businesses and developers to re-imagine insurance & health products for the next billion users. The journey began with the vision of becoming the final word in the insurance ecosystem. The only constant across this journey has been the ‘excellent people’ that have associated their future on a promise and ‘dream’ that brand Artivatic managed to create." />
        <meta name="keywords" content="About Artivatic,Our Story,Journey." />
        <link rel="canonical" href="/about-us" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={`https://artivatic.ai/about-us`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Artivatic | About Us`} />
        <meta property="og:description" content={`Artivatic was born 3 years ago to empower insurance & healthcare businesses and developers to re-imagine insurance & health products for the next billion users. The journey began with the vision of becoming the final word in the insurance ecosystem. The only constant across this journey has been the ‘excellent people’ that have associated their future on a promise and ‘dream’ that brand Artivatic managed to create.`} />
        <meta property="og:image" content={`https://artivatic.ai/static/media/Our%20Story%20-%20About%20Us.66b82d7d.png`} />
        <meta property="og:image:width" content='1200' />
        <meta property="og:image:height" content='630' />

        {/* Twitter Meta Tags  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="artivatic.ai" />
        <meta property="twitter:url" content={`https://artivatic.ai/about-us`} />
        <meta name="twitter:title" content={`Artivatic | About Us`} />
        <meta name="twitter:description" content={`Artivatic was born 3 years ago to empower insurance & healthcare businesses and developers to re-imagine insurance & health products for the next billion users. The journey began with the vision of becoming the final word in the insurance ecosystem. The only constant across this journey has been the ‘excellent people’ that have associated their future on a promise and ‘dream’ that brand Artivatic managed to create.`} />
        <meta name="twitter:image" content={`https://artivatic.ai/static/media/Our%20Story%20-%20About%20Us.66b82d7d.png`} />
        <meta property="og:image:width" content='1200' />
        <meta property="og:image:height" content='630' />

        {/* Organisation Schema    */}
        <script type="application/ld+json">
          {`{
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Artivatic.ai",
              "alternateName": "Artivatic",
              "url": "https://artivatic.ai/",
              "logo": "https://artivatic.ai/favicon.jpeg",
              "contactPoint": {
                    "@type": "ContactPoint",
                    "telephone": "+91 8431969670",
                    "contactType": "customer service",
                    "areaServed": ["IN","US"],
                    "availableLanguage": "en"
              },
                "sameAs": [
                "https://www.facebook.com/artivatic",
                "https://twitter.com/artivatic",
                "https://www.instagram.com/artivatic.ai/?hl=en",
                "https://www.youtube.com/channel/UCErCyc2yw9zKPuLm3WrANZQ",
                "https://www.linkedin.com/company/artivatic"
              ]
            }`
          }
        </script>
      </Helmet>
      <div id="aboutus">
        <IntroductionSection
          title={{ type: "spanend", Text: "Why we ", span: "exist?" }}
          titleSecondary={{ type: 'spanend', Text: 'Enter ', span: 'Artivatic.ai' }}
          description={{
            Text1: "According to Grand View Research, “The global insurtech market size is expected to expand at a compound annual growth rate (CAGR) of 48.8% from 2021 to 2028.”",
            Text2: "Insurance sector is moving from processes to tech driven and software focused. It is growing like never before and transforming its technologies to reach to customers, enhance processes, experience and optimizing risk assessment using data, processes, systems and learning.",
            Text3: "Every business and activities now need insurance in one or other forms. The ‘legacy software, manual driven processes’ way of building and running the insurance business is no longer viable for the present ‘on-demand, need based’ world. This entire ecosystem of insurance needs a new ‘OPERATING SYSTEM’ to run on, and that is INSURTECH.",
          }}
          descriptionSecondary={{
            Text1: 'Artivatic was born 3 years ago to empower insurance & healthcare businesses and developers to re-imagine insurance & health products for the next billion users. The journey began with the vision of becoming the final word in the insurance ecosystem. The only constant across this journey has been the ‘excellent people’ that have associated their future on a promise and ‘dream’ that brand Artivatic managed to create.',

            Text3: 'We at Artivatic.ai are building next gen insurance products and solutions to simplify risk assessment, process automation & digital use to enable access to insurance with our technology driven innovations.',
            Text4: 'Artivatic is transforming legacy insurance into digital, personalized and customer centric while taking affordability of our clients into consideration. We make use of AI and Machine Learning in our daily life, and aim to integrate the same to uncomplicate what is an extremely archaic and manual industry to increase the number of insurance policy holders in the long run.',
            Text5: [
              {
                content: 'Presently, we are all aligning to the new reality in the wake of unprecedented challenges faced by businesses worldwide.\n',
                bold: false,
                link: false
              },
              {
                content: ' Artivatic is now part of larger family with',
                bold: true,
                link: false
              },
              {
                content: ' RenewBuy.com ',
                bold: true,
                link: true
              },
              {
                content: '- A disruptive InsurTech player providing choice and convenience to customers buying Health, Life and Motor Insurance products, delivered through a trusted network of POSP (Point of Sales Person) advisors.',
                bold: false,
                link: false
              }
            ]
          }}
          YTvideo={'https://www.youtube.com/embed/IsyhzGL6vA0'}
          classes={{
            videoClass: 'videoClass order-2'
          }}
        >
        </IntroductionSection>
        <section className="wrapper">

        </section>

        {/* Our Story Section Start  */}
        <section className="ourstory">
          <div className="wrapper">
            <h1>Our story</h1>
            <div className="line"></div>
            <div className='our-Story-Image'>
              <img src={ourStoryImg} alt=''></img>
            </div>
            <p>The brand Artivatic has emerged over the years after traversing through multiple challenges and enlightening lessons. Over the years, we have set gold standards for insurtech in terms of people, process, technology, and innovation – and the aim is to continue delivering unparalleled value to our customers, as well as our people.</p>
            <p>However, to accelerate a long-term strategic plan, <span className="highme">
              <a href="https://www.renewbuy.com/" target="_blank" style={{ 'textDecoration': 'underline' }}>RenewBuy</a> acquired a controlling interest in Artivatic</span> as we believe that a larger organization will allow us to provide a wider array of services. The collaboration will begin a new growth journey for Artivatic.AI and RenewBuy. The alliance of deep tech expertise and product solution capabilities of Artivatic and digital distribution prowess of RenewBuy, will help in providing end-to-end under-writing and claim solutions to RenewBuy’s existing 3 million consumers and potential consumers across the country.</p>
          </div>
        </section>
        {/* Our Story Section End */}

        {/* Our Journey Section Start  */}
        <section className="our-journey wrapper">
          <h1>Our Journey</h1>
          <div className="row">
            <div className="left-md">
              <div className="journey-row">
                <div className="year">
                  <h6>2017</h6>
                </div>
                <div className="desc">
                  <p>This was the year of our Foundation – when Artivatic operation was started by Layak and Puneet. We raised our first cheque from Spark10 UK. Of course, we partied!</p>
                </div>
                <div className="journey-image">
                  {/* <img src={storyimg} alt=''></img> */}
                </div>
              </div>
              <div className="journey-row">
                <div className="year">
                  <h6>2018</h6>
                </div>
                <div className="desc">
                  <p>We launched our first Underwriting Product and started piloting with big league clients in India.</p>
                </div>
                <div className="journey-image">
                  {/* <img src={storyimg} alt=''></img> */}
                </div>
              </div>
              <div className="journey-row">
                <div className="year">
                  <h6>2019</h6>
                </div>
                <div className="desc">
                  <p>This was the year when we signed our first customer and raised Sees Investment from Indian Angel Network & IAN Fund</p>
                </div>
                <div className="journey-image">
                  {/* <img src={storyimg} alt=''></img> */}
                </div>
              </div>
              <div className="journey-row">
                <div className="year">
                  <h6>2020</h6>
                </div>
                <div className="desc">
                  <p>We bagged 7 successful live clients and started generating regular revenue. We also launched 8 Core products & 400+ APIs platform. By this time, we had expanded among APAC & USA Market</p>
                </div>
              </div>
              <div className="journey-row">
                <div className="year">
                  <h6>2021</h6>
                </div>
                <div className="desc">
                  <p>Our technology offering took a major leap. Incorporated in USA- Artivatic, Inc. <br />
                    Launched B2C ASPIRE Platform, Launched Health System, Property & Auto Insurance Sector, Distribution & Buy Policy Platforms Micro, Byte sized or Risk Based Product Design Platform, MiO Sales Platform, INFRD Insurance API Cloud. Also bagged Pre-Series A Fund from KFintech (Backed by General Atlantic) <br />
                    We are in process to incorporate Artivatic office in Singapore.</p>
                </div>
              </div>
              <div className="journey-row">
                <div className="year">
                  <h6>2022</h6>
                </div>
                <div className="desc">
                  <p>Enhanced our Product Portfolio- Introduced ASPIRE HEALTH, SME/Commercial, Employee Health 360, MiO Sales, MiO Banca, MiO Communication, D2C Platform, Customer On-Boarding, PRODX Design and PRODX Distribution.<br /><br />
                    <a href="https://www.renewbuy.com/" target="_blank" style={{ 'textDecoration': 'underline' }} className="highme">RenewBuy.com</a> acquired a controlling interest in Artivatic.</p>
                </div>
              </div>
            </div>
          </div>


        </section>
        {/* Our Journey Section End  */}

        {/* Award Section Start  */}
        <Section3></Section3>
        {/* Award Section End */}

        {/* What We Do Section Start */}
        <section className="what-we-do">
          <IntroductionSection
            title={{ type: "nospan", Text: "What we do " }}
            description={{
              Text1: "Artivatic’s AI- Native SaaS Solutions for insurance & health services as modular building blocks powered by best-in-class API gateway. New age product offerings, dynamic pricing, automation, smart underwriting, smart claims processing, enhanced customer experience, persistence & collections."
            }}
            button1={{
              Text: "Work with us",
              Url: '/career'
            }}
            image={workwithusImg}
            classes={{
              buttonClass: 'detail-demo',
              rightClass: 'community-image',
            }}>
          </IntroductionSection>
        </section>
        {/* What We Do Section Start */}

        {/* Changing the way Section */}
        <section className="change-the-way">
          <section className="section-10 feature-section">
            <div className="wrapper">
              <h1 className="heading">
                Changing the way we <span style={{ color: "var(--primary)" }}>work</span>
              </h1>
              <hr className="hr-underline"></hr>
              <p className="para">
                Future is about need based, instant, personalised & risk focused.
                Artivatic makes that happen with powerful AI driven platform.
              </p>
              <div className="grid">
                <div className="cell">
                  <img src={greenCircle} alt="cell-img" />
                  <h3>With innovative technology</h3>
                  <p>
                    Bleeding-edge technology innovations that leverage Natural
                    Language Processing, Deep Learning and Predictive Algorithms to
                    build a scalable and reliable financial, healthcare & Insurtech
                    systems.
                  </p>
                </div>

                <div className="cell">
                  <img src={greenCircle} alt="cell-img" />
                  <h3>With love for customers</h3>
                  <p>
                    Helps businesses use automated decision engines to reduce labour
                    costs, enforce policies and improve the quality of the customer
                    experience.
                  </p>
                </div>

                <div className="cell">
                  <img src={greenCircle} alt="cell-img" />
                  <h3>With a great team</h3>
                  <p>
                    Making inspiration from real life & science, our AI system evolves
                    millions of scenarios and responses constantly selecting the best
                    outcome in a given scenario.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </section>
        {/* Changing the way Section */}

        {/* Join our Community Section */}
        <section className="join-community">
          <IntroductionSection
            title={{ type: "nospan", Text: "Join our Community" }}
            description={{
              Text1: "We unlock hidden potential when we come together. We unite insurance and healthcare people to help their businesses to achieve their goals, solve their challenges and celebrate their success by providing our technology driven platforms.",
              Text2: 'WE personalize & automate the InsurTech Industry for better tomorrow.',
            }}
            button1={{
              Text: "Partner with us",
              Url: '/partners'
            }}
            image={joinusImg}
            classes={{
              buttonClass: 'detail-demo',
              rightClass: 'community-image',
            }}></IntroductionSection>
        </section>
        {/* Join our Community Section */}

        {/* Our customers Section Start a */}
        {/* <section className="our-customer">
        <div className="wrapper">
          <div className="left-md">
            <h1>Our customers are<br /> <span>superheroes,</span> – we are their growth partners</h1>
            <div className="line"></div>
            <p>Righting the wrongs of insurance technology for global insurance organizations.Leading organizations in the world utilize Artivatic’s suite of solutions
              Artivatic believes in collaborative growth with all of its stakeholders.</p>
          </div>
          <div className="right-md">
            <img src={nativeImgDesk} alt=''></img>
            <div className="fade"></div>
          </div>
        </div>
      </section> */}
        <Section11
          title={{
            type: "spanend", Text: "Our customers are", span: "superheroes ", Text2: '– we are their growth partners'
          }}
          description={{
            Text: 'Righting the wrongs of insurance technology for global insurance organizations.Leading organizations in the world utilize Artivatic’s suite of solutions Artivatic believes in collaborative growth with all of its stakeholders.'
          }}

          link={{
            Text: 'Read More',
            LinkRoute: '/partners',
          }}
          clientList={clientList}
        />
        {/* Our customers Section End */}
        <Section16
          title="Our Investors"
          // description={description}
          investorCarousel={false}
        // button={
        //   {
        //     Url: '/investors',
        //     Text: 'View all Investors'
        //   }
        //}
        ></Section16>
        {/* Background Banner Section */}

        <NewsLetterSection
          title="Lets build next-gen insurance & health products"
          btn1text="Schedule a Demo"
          btn2text="Request Pricing"
          btn1Route="/contact-us"
          btn2Route="/contact-us"


        ></NewsLetterSection>

        {/* Background Banner Section */}
      </div>
    </>
  );
}
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import Card from "../../components/Card/Card";
import Carousel from "../../components/Carousel/carousel";
import './Resources.css';
import RectangleDesk from "../../assets/png/Rectangle 184.png";
import RectangleMob from '../../assets/png/Rectangle 187.png';

import a1 from '../../assets/Blog Section - Images/115.png';
import a2 from '../../assets/Blog Section - Images/114.png';
import a3 from '../../assets/Blog Section - Images/113.png';
import a4 from '../../assets/Blog Section - Images/112.png';
import a5 from '../../assets/Blog Section - Images/111.png';
import a6 from '../../assets/Blog Section - Images/110.png';
import a7 from '../../assets/Blog Section - Images/109.png';
import a8 from '../../assets/Blog Section - Images/108.png';
import a9 from '../../assets/Blog Section - Images/107.png';
import a10 from '../../assets/Blog Section - Images/106.png';
import a11 from '../../assets/Blog Section - Images/105.png';
import b1 from '../../assets/Blog Section - Images/1.png';
import b2 from '../../assets/Blog Section - Images/2.png';
import b3 from '../../assets/Blog Section - Images/3.png';
import b4 from '../../assets/Blog Section - Images/4.png';
import b5 from '../../assets/Blog Section - Images/5.png';
import b6 from '../../assets/Blog Section - Images/6.png';
import b7 from '../../assets/Blog Section - Images/7.png';
import b8 from '../../assets/Blog Section - Images/8.png';
import b9 from '../../assets/Blog Section - Images/9.png';
import b10 from '../../assets/Blog Section - Images/10.png';
import b11 from '../../assets/Blog Section - Images/11.png';
import b12 from '../../assets/Blog Section - Images/12.png';

import b13 from '../../assets/Blog Section - Images/13.png';
import b14 from '../../assets/Blog Section - Images/14.png';
import b15 from '../../assets/Blog Section - Images/15.png';
import b16 from '../../assets/Blog Section - Images/16.png';
import b17 from '../../assets/Blog Section - Images/17.png';
import b18 from '../../assets/Blog Section - Images/18.png';
import b19 from '../../assets/Blog Section - Images/19.png';
import b20 from '../../assets/Blog Section - Images/20.png';
import b21 from '../../assets/Blog Section - Images/21.png';
import b22 from '../../assets/Blog Section - Images/22.png';
import b23 from '../../assets/Blog Section - Images/23.png';
import b24 from '../../assets/Blog Section - Images/24.png';
import b25 from '../../assets/Blog Section - Images/25.png';
import b26 from '../../assets/Blog Section - Images/26.png';
import b27 from '../../assets/Blog Section - Images/27.png';
import b28 from '../../assets/Blog Section - Images/28.png';
import b29 from '../../assets/Blog Section - Images/29.png';
import b30 from '../../assets/Blog Section - Images/30.png';
import b31 from '../../assets/Blog Section - Images/31.png';
import b32 from '../../assets/Blog Section - Images/32.png';
import b33 from '../../assets/Blog Section - Images/33.png';
import b34 from '../../assets/Blog Section - Images/34.png';
import b35 from '../../assets/Blog Section - Images/35.png';
import b36 from '../../assets/Blog Section - Images/36.png';
import b37 from '../../assets/Blog Section - Images/37.png';
import b38 from '../../assets/Blog Section - Images/38.png';
import b39 from '../../assets/Blog Section - Images/39.png';
import b40 from '../../assets/Blog Section - Images/40.png';
import b41 from '../../assets/Blog Section - Images/41.png';
import b42 from '../../assets/Blog Section - Images/42.png';
import b43 from '../../assets/Blog Section - Images/43.png';
import b44 from '../../assets/Blog Section - Images/44.png';
import b45 from '../../assets/Blog Section - Images/45.png';
import b46 from '../../assets/Blog Section - Images/46.png';
import b47 from '../../assets/Blog Section - Images/47.png';
import b48 from '../../assets/Blog Section - Images/48.png';
import b49 from '../../assets/Blog Section - Images/49.png';
import b50 from '../../assets/Blog Section - Images/50.png';
import b51 from '../../assets/Blog Section - Images/51.png';
import b52 from '../../assets/Blog Section - Images/52.png';
import b53 from '../../assets/Blog Section - Images/53.png';
import b54 from '../../assets/Blog Section - Images/54.png';
import b55 from '../../assets/Blog Section - Images/55.png';
import b56 from '../../assets/Blog Section - Images/56.png';
import b57 from '../../assets/Blog Section - Images/57.png';
import b58 from '../../assets/Blog Section - Images/58.png';
import b59 from '../../assets/Blog Section - Images/59.png';
import b60 from '../../assets/Blog Section - Images/60.png';
import b61 from '../../assets/Blog Section - Images/61.png';
import b62 from '../../assets/Blog Section - Images/62.png';
import b63 from '../../assets/Blog Section - Images/63.png';
import b64 from '../../assets/Blog Section - Images/64.png';
import b65 from '../../assets/Blog Section - Images/65.png';
import b66 from '../../assets/Blog Section - Images/66.png';
import b67 from '../../assets/Blog Section - Images/67.png';
import b68 from '../../assets/Blog Section - Images/68.png';
import b69 from '../../assets/Blog Section - Images/69.png';
import b70 from '../../assets/Blog Section - Images/70.png';
import b71 from '../../assets/Blog Section - Images/71.png';
import b72 from '../../assets/Blog Section - Images/72.png';
import b73 from '../../assets/Blog Section - Images/73.png';
import b74 from '../../assets/Blog Section - Images/74.png';
import b75 from '../../assets/Blog Section - Images/75.png';
import b76 from '../../assets/Blog Section - Images/76.png';
import b77 from '../../assets/Blog Section - Images/77.png';
import b78 from '../../assets/Blog Section - Images/78.png';
import b79 from '../../assets/Blog Section - Images/79.png';
import b80 from '../../assets/Blog Section - Images/80.png';
import b81 from '../../assets/Blog Section - Images/81.png';
import b82 from '../../assets/Blog Section - Images/82.png';
import b83 from '../../assets/Blog Section - Images/83.png';
import b84 from '../../assets/Blog Section - Images/84.png';
import b85 from '../../assets/Blog Section - Images/85.png';
import b86 from '../../assets/Blog Section - Images/86.png';
import b87 from '../../assets/Blog Section - Images/87.png';
import b88 from '../../assets/Blog Section - Images/88.png';
import b89 from '../../assets/Blog Section - Images/89.png';
import b90 from '../../assets/Blog Section - Images/90.png';
import b91 from '../../assets/Blog Section - Images/91.png';
import b92 from '../../assets/Blog Section - Images/92.png';
import b93 from '../../assets/Blog Section - Images/93.png';
import b94 from '../../assets/Blog Section - Images/94.png';
import b95 from '../../assets/Blog Section - Images/95.png';
import b96 from '../../assets/Blog Section - Images/96.png';
import b97 from '../../assets/Blog Section - Images/97.png';
import b98 from '../../assets/Blog Section - Images/98.png';
import b99 from '../../assets/Blog Section - Images/99.png';
import b100 from '../../assets/Blog Section - Images/100.png';
import b101 from '../../assets/Blog Section - Images/101.png';
import b102 from '../../assets/Blog Section - Images/102.png';
import b103 from '../../assets/Blog Section - Images/103.png';
import b104 from '../../assets/Blog Section - Images/104.png';

import c1 from '../../assets/Infographic - images/1.png';
import c2 from '../../assets/Infographic - images/2.png';
import c3 from '../../assets/Infographic - images/3.png';
import c4 from '../../assets/Infographic - images/4.png';
import c5 from '../../assets/Infographic - images/5.png';
import c6 from '../../assets/Infographic - images/6.png';
import c7 from '../../assets/Infographic - images/7.png';
import c8 from '../../assets/Infographic - images/8.png';
import c9 from '../../assets/Infographic - images/9.png';
import c10 from '../../assets/Infographic - images/10.png';
import c11 from '../../assets/Infographic - images/11.png';
import c12 from '../../assets/Infographic - images/12.png';
import c13 from '../../assets/Infographic - images/13.png';
import c14 from '../../assets/Infographic - images/14.png';
import c15 from '../../assets/Infographic - images/15.png';

import pdf1 from '../../assets/resources/23.11.21 - INSURTECH 2022.pdf';
import pdf2 from '../../assets/resources/15.12.21 - The Rise of Automation in Employee Health Benefits.pdf';
import pdf3 from '../../assets/resources/15.02.22 - Insurtech 2.0.pdf';
import pdf4 from '../../assets/resources/17.03.22 - KYC Video.pdf';
import pdf5 from '../../assets/resources/11.03.22 - Digital Insurance.pdf';
import pdf6 from '../../assets/resources/28.02.22 - Insurance Agents.pdf';
import pdf7 from '../../assets/resources/07.01.22 - Redefining Underwriting with Automation.pdf';
import pdf8 from '../../assets/resources/28.01.22 - Insurtech Startup Ecosystem in India.pdf';
import pdf9 from '../../assets/resources/28.12.21 - Insurance Selling - Automation.pdf';
import pdf10 from '../../assets/resources/29.11.21 - Insurance Sector Transformation through Open API Ecosystem.pdf';
import pdf11 from '../../assets/resources/08.12.21 -  AI & Technology is enabling Health Claims.pdf';
import pdf12 from '../../assets/resources/20.01.22 - Blockchain Adoption in Healthcare Sector.pdf';
import pdf13 from '../../assets/resources/08.02.22 - The changing scenario of Automated Healthcare Sector.pdf';
import pdf14 from '../../assets/resources/10.02.22 - Chatbots The Game Changer in Insurance Sector.pdf';
import pdf15 from '../../assets/resources/12.01.22 - Insurtech- Digital Vision 2022.pdf';


const Resources = () => {
    const { type } = useParams();
    let all_data1 = [];
    let all_data2 = [];
    let all_data3 = [];
    let all_data4 = [];
    let all_data5 = [];
    let all_data6 = [];
    let all_data7 = [];
    let all_data8 = [];
    let containHead = '';

    if (type === 'blogs') {
        all_data1 = [
            {
                cardImg: a1,
                shareimg:'http://localhost:3000/static/media/115.2461bb47.png',
                cardTag: 'Mar 10,2022',
                cardDes: 'Artivatic has launched a Video KYC product called INFRD which helps users submit KYC information & get themselves verified via video. Customer experience is improved through digital video while saving on several KYC costs & reducing onboarding time to 10 minutes.',
                cardTtitle: 'Artivatic’s Video KYC Solution- Onboarding customers & Partners Remotely',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/artivatics-video-kyc-solution-onboarding?s=r',
                new_page_open: true
            },
            {
                cardImg: a2,
                cardTag: 'Mar 08,2022',
                cardTtitle: 'The power of IoT in Healthcare Sector',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/the-power-of-iot-in-healthcare-sector?s=r',
                new_page_open: true
            },
            {
                cardImg: a3,
                cardTag: 'Feb 28,2022',
                cardTtitle: 'Stronger Together! Artivatic - The way forward!',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/stronger-together-artivatic-the-way?s=r',
                new_page_open: true
            },
            {
                cardImg: a4,
                cardTag: 'Feb 22,2022',
                cardTtitle: 'Life Insurance Trends for 2022 and beyond',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/life-insurance-trends-for-2022-and?s=r',
                new_page_open: true
            },
            {
                cardImg: a5,
                cardTag: 'Feb 11,2022',
                cardTtitle: 'Benefits of Insurance for Gig Workers',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/benefits-of-insurance-for-gig-workers?s=r',
                new_page_open: true
            },
            {
                cardImg: a6,
                cardTag: 'Feb 10,2022',
                cardTtitle: 'Importance of Chatbots for insurance providers in improving the Customer Journey',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/importance-of-chatbots-for-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: a7,
                cardTag: 'Feb 02,2022',
                cardTtitle: 'Mobile Apps- The Game changer in Insurance Industry',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/mobile-apps-the-game-changer-in-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: a8,
                cardTag: 'Feb 01,2022',
                cardTtitle: 'Chatbots: New Sales Agent in Insurance Industry',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/chatbots-new-sales-agent-in-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: a9,
                cardTag: 'Jan 28,2022',
                cardTtitle: 'Chatbots: New phenomena in improving Patient Experience',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/chatbots-new-phenomena-in-improving?s=r',
                new_page_open: true
            },
            {
                cardImg: a10,
                cardTag: 'Jan 28,2022',
                cardTtitle: 'Robotic Process Automation powers digital Transformation in Insurance Industry',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/robotic-process-automation-powers?s=r',
                new_page_open: true
            },
            {
                cardImg: a11,
                cardTag: 'Jan 25,2022',
                cardTtitle: 'Insurance Innovation through Microservices',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/insurance-innovation-through-microservices?s=r',
                new_page_open: true
            },
            {
                cardImg: b104,
                cardTag: 'Jan 24,2022',
                cardTtitle: 'Podcast: How AI can streamline health insurance claims processing?',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/podcast-how-ai-can-streamline-health?s=r',
                new_page_open: true
            },
            {
                cardImg: b103,
                cardTag: 'Jan 21,2022',
                cardTtitle: 'Automation: A Necessity in Healthcare’s Digital Transformation',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/automation-a-necessity-in-healthcares?s=r',
                new_page_open: true
            },

            {
                cardImg: b102,
                cardTag: 'Jan 21,2022',
                cardTtitle: 'Automating KYC extraction through AI APIs',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/automating-kyc-extraction-through?s=r',
                new_page_open: true
            },
            {
                cardImg: b101,
                cardTag: 'Jan 19,2022',
                cardTtitle: 'Opportunities and challenges for healthcare APIs',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/opportunities-and-challenges-for?s=r',
                new_page_open: true
            },

        ];
        all_data2 = [
            {
                cardImg: b100,
                cardTag: 'Jan 14,2022',
                cardTtitle: 'Impact of Blockchain on Insurance Industry',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/impact-of-blockchain-on-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b99,
                cardTag: 'Jan 11,2022',
                cardTtitle: 'How Automation is reshaping the Insurance workforce in 2022',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/how-automation-is-reshaping-the-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b98,
                cardTag: 'Jan 10,2022',
                cardTtitle: 'Top Insurtech trends to look forward to in 2022',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/top-insurtech-trends-to-look-forward?s=r',
                new_page_open: true
            },
            {
                cardImg: b97,
                cardTag: 'Jan 07,2022',
                cardTtitle: 'Role of Insurance Agents in the AI Age',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/role-of-insurance-agents-in-the-ai?s=r',
                new_page_open: true
            },
            {
                cardImg: b96,
                cardTag: 'Jan 07,2022',
                cardTtitle: 'Insurance Insights: Intelligent Underwriting Workbench',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/insurance-insights-intelligent-underwriting?s=r',
                new_page_open: true
            },
            {
                cardImg: b95,
                cardTag: 'Jan 05,2022',
                cardTtitle: 'REWIND 2021- Reminiscing Artivatic’s Best Moments and Achievements!',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/rewind-2021-reminiscing-artivatics?s=r',
                new_page_open: true
            },
            {
                cardImg: b94,
                cardTag: 'Dec 30, 2021',
                cardTtitle: 'What APIs mean for an open and connected insurance industry',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/what-apis-mean-for-an-open-and-connected?s=r',
                new_page_open: true
            },
            {
                cardImg: b93,
                cardTag: 'Dec 29, 2021',
                cardTtitle: 'Insurance Sales Revolution',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/insurance-sales-revolution?s=r',
                new_page_open: true
            },
            {
                cardImg: b92,
                cardTag: 'Dec 22, 2021',
                cardTtitle: 'Web 3.0 and its massive use cases for Healthcare Sector',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/web-30-and-its-massive-use-cases?s=r',
                new_page_open: true
            },
            {
                cardImg: b91,
                cardTag: 'Dec 17, 2021',
                cardTtitle: 'Insurance Selling through MiO Sales',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/insurance-selling-through-mio-sales?s=r',
                new_page_open: true
            },
            {
                cardImg: b90,
                cardTag: 'Dec 10, 2021',
                cardTtitle: "Reigniting Insurance Distribution: One API for All your distribution 'Insurance-in-a-Box'",
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/reigniting-insurance-distribution?s=r',
                new_page_open: true
            },
            {
                cardImg: b89,
                cardTag: 'Dec 09, 2021',
                cardTtitle: 'How Web 3.0 will transform the insurance sector?',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/how-web-30-will-transform-the-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b88,
                cardTag: 'Dec 09, 2021',
                cardTtitle: 'Bancassurance ! Its time to go digital. Reigniting Bancassurance',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/bancassurance-its-time-to-go-digital?s=r',
                new_page_open: true
            },

            {
                cardImg: b87,
                cardTag: 'Nov 29, 2021',
                cardTtitle: 'Artivatic launches smart AI underwriting platform named AUSIS to provide faster life and health insurance- Financial Express',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/artivatic-launches-smart-ai-underwriting?s=r',
                new_page_open: true
            },
            {
                cardImg: b86,
                cardTag: 'Nov 18, 2021',
                cardTtitle: 'Use of Advanced Analytics in Insurance',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/use-of-advanced-analytics-in-insurance?s=r',
                new_page_open: true
            },
        ];
        all_data3 = [
            {
                cardImg: b85,
                cardTag: 'Nov 12, 2021',
                cardTtitle: 'Future of SMBs and Commercial Insurance',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/future-of-smbs-and-commercial-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b84,
                cardTag: 'Oct 29, 2021',
                cardTtitle: 'Benefits of AI in Insurance Sales',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/benefits-of-ai-in-insurance-sales?s=r',
                new_page_open: true
            },
            {
                cardImg: b83,
                cardTag: 'Oct 22, 2021',
                cardTtitle: 'How Artificial Intelligence helps in Risk management in Insurance Industry',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/how-artificial-intelligence-helps?s=r',
                new_page_open: true
            },
            {
                cardImg: b82,
                cardTag: 'Oct 14, 2021',
                cardTtitle: 'Insurtech Boom- Reshaping the Insurance Market',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/insurtech-boom-reshaping-the-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b81,
                cardTag: 'Sep 29, 2021',
                cardTtitle: 'The Future of Insurance Claim in the Era of Technology Disruption',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/the-future-of-insurance-claim-in?s=r',
                new_page_open: true
            },
            {
                cardImg: b80,
                cardTag: 'Sep 21, 2021',
                cardTtitle: 'Digital and AI-powered Underwriting in Insurance Sector',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/digital-and-ai-powered-underwriting?s=r',
                new_page_open: true
            },
            {
                cardImg: b79,
                cardTag: 'Sep 16, 2021',
                cardTtitle: 'The Untapped Potential of APIs in the Insurance Sector',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/the-untapped-potential-of-apis-in?s=r',
                new_page_open: true
            },
            {
                cardImg: b78,
                cardTag: 'Jul 27, 2021',
                cardTtitle: 'Artivatic redefines Sales & Marketing: Launches AI Based MiOSales for Insurance',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/artivatic-redefines-sales-and-marketing?s=r',
                new_page_open: true
            },
            {
                cardImg: b77,
                cardTag: 'Jun 30, 2021',
                cardTtitle: 'India gears up for next-gen insurance solutions leveraging InsurTech',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/india-gears-up-for-next-gen-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b76,
                cardTag: 'Jun 30, 2021',
                cardTtitle: 'Dr. Abhijeet speaks about Artivatic and its Future for Insurance with IIA',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/dr-abhijeet-speaks-about-artivatic?s=r',
                new_page_open: true
            },
            {
                cardImg: b75,
                cardTag: 'Jun 23, 2021',
                cardTtitle: 'Artivatic Launches INFRD - API Insurance Cloud Platform to Build Future of Insurance',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/artivatic-launches-infrd-api-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b74,
                cardTag: 'Jun 10, 2021',
                cardTtitle: 'Automating End to End Health Claims using AI & Data: ALFRED HEALTH CLAIMS',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/automating-end-to-end-health-claims?s=r',
                new_page_open: true
            },
            {
                cardImg: b73,
                cardTag: 'Jun 04, 2021',
                cardTtitle: 'Insurtech is the New Software!',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/insurtech-is-the-new-software?s=r',
                new_page_open: true
            },

            {
                cardImg: b72,
                cardTag: 'May 28, 2021',
                cardTtitle: 'Delight Your Customers with Enhanced Buying Journey Experience',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/delight-your-customers-with-enhanced?s=r',
                new_page_open: true
            },
            {
                cardImg: b71,
                cardTag: 'May 26, 2021',
                cardTtitle: 'Simplifying Group Health & Commercial Insurance for Businesses, SMEs & Start-ups',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/simplifying-group-health-and-commercial?s=r',
                new_page_open: true
            },
        ];
        all_data4 = [
            {
                cardImg: b70,
                cardTag: 'May 24, 2021',
                cardTtitle: 'Alfred AI Health Claims Platform- 6 Months Free for Processing COVID-19 Claims',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/alfred-ai-health-claims-platform?s=r',
                new_page_open: true
            },
            {
                cardImg: b69,
                cardTag: 'May 18, 2021',
                cardTtitle: 'Dr. Abhijeet Kanetkar Joins Artivatic as Chief Business Officer, Head UW & Claims',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/dr-abhijeet-kanetkar-join-artivatic?s=r',
                new_page_open: true
            },
            {
                cardImg: b68,
                cardTag: 'May 11, 2021',
                cardTtitle: 'Artivatic Raises Pre-Series A Fund from KFintech to build future of insurtech',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/kfintech-enters-into-insurtech-with?s=r',
                new_page_open: true
            },
            {
                cardImg: b67,
                cardTag: 'May 06, 2021',
                cardTtitle: 'Cybersecurity Tips for Insurance Companies',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/cybersecurity-tips-for-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b66,
                cardTag: 'May 04, 2021',
                cardTtitle: 'Alfred AI Health Claims - 6 Months Free for Processing Claims for COVID Claims',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/alfred-ai-health-claims-6-months/comments?s=r',
                new_page_open: true
            },
            {
                cardImg: b65,
                cardTag: 'Apr 29, 2021',
                cardTtitle: 'AI & Browser Driven Real-time Motor Pre Inspection & Damage Assessment : CARSURE',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/ai-and-browser-driven-real-time-motor?s=r',
                new_page_open: true
            },
            {
                cardImg: b64,
                cardTag: 'Apr 26, 2021',
                cardTtitle: 'Underwriting 3.0: AI Based Dynamic & Smart decision making in real-time',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/underwriting-30-ai-based-dynamic?s=r',
                new_page_open: true
            },
            {
                cardImg: b63,
                cardTag: 'Apr 12, 2021',
                cardTtitle: 'AUSIS AI UW: Helping Insurance Businesses to Grow',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/ausis-ai-uw-helping-insurance-businesses?s=r',
                new_page_open: true
            },
            {
                cardImg: b62,
                cardTag: 'Mar 31, 2021',
                cardTtitle: 'Intelligent and Smart Digital Transformation for Insurance',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/intelligent-and-smart-digital-transformation?s=r',
                new_page_open: true
            },
            {
                cardImg: b61,
                cardTag: 'Mar 31, 2021',
                cardTtitle: 'MiO Sales: Accelerating Sales, Lead Conversion & Distribution using AI',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/accelerating-digital-operations-sales?s=r',
                new_page_open: true
            },
            {
                cardImg: b60,
                cardTag: 'Mar 27, 2021',
                cardTtitle: 'We are building Team @Artivatic ! Hiring.',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/we-are-building-team-artivatic-hiring?s=r',
                new_page_open: true
            },
            {
                cardImg: b59,
                cardTag: 'Mar 22, 2021',
                cardTtitle: 'Browser Based Real-time Motor Pre Inspection & Claims Estimation : CARSURE',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/carsure-ai-motor-claims-preinspection?s=r',
                new_page_open: true
            },
            {
                cardImg: b58,
                cardTag: 'Mar 16, 2021',
                cardTtitle: 'ALFRED: Transforming Health Claims using AI',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/alfred-transforming-health-claims?s=r',
                new_page_open: true
            },

            {
                cardImg: b57,
                cardTag: 'Mar 09, 2021',
                cardTtitle: 'Transformation in Insurance markets post COVID-19',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/transformation-in-insurance-markets?s=r',
                new_page_open: true
            },
            {
                cardImg: b56,
                cardTag: 'Mar 03, 2021',
                cardTtitle: 'AUSIS - Underwriting 3.0: AI Based Dynamic & Smart decision making in real-time',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/ausis-ai-smart-underwriting-system?s=r',
                new_page_open: true
            },

        ];
        all_data5 = [
            {
                cardImg: b55,
                cardTag: 'Feb 25, 2021',
                cardTtitle: 'MiO Sales - Enabling Next-Gen AI Sales Engine for Insurance & Distributors',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/mio-sales-enabling-next-gen-ai-sales?s=r',
                new_page_open: true
            },
            {
                cardImg: b54,
                cardTag: 'Feb 03, 2021',
                cardTtitle: 'The InsurTech Biz- An Overview',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/the-insurtech-biz-an-overview?s=r',
                new_page_open: true
            },
            {
                cardImg: b53,
                cardTag: 'Jan 14, 2021',
                cardTtitle: 'Revolution HealthTech- How it changed Mankind',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/revolutionhealthtech?s=r',
                new_page_open: true
            },
            {
                cardImg: b52,
                cardTag: 'Jan 13, 2021',
                cardTtitle: 'Revolution HealthTech- How it changed Mankind',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/revolution-healthtech-how-it-changed-mankind?s=r',
                new_page_open: true
            },
            {
                cardImg: b51,
                cardTag: 'Jan 13, 2021',
                cardTtitle: 'MiO Property: Artivatic Launches Real Time Property Risk Inspection Tool',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/mio-property-artivatic-launches-real?s=r',
                new_page_open: true
            },
            {
                cardImg: b50,
                cardTag: 'Jan 08, 2021',
                cardTtitle: "Artivatic's ALFRED now on Microsoft Azure Marketplace",
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/artivatics-alfred-now-on-microsoft-azure-marketplace?s=r',
                new_page_open: true
            },
            {
                cardImg: b49,
                cardTag: 'Jan 07, 2021',
                cardTtitle: "Artivatic's AUSIS is now on Microsoft Azure Marketplace",
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/artivatics-ausis-is-now-on-microsoft-azure-marketplace?s=r',
                new_page_open: true
            },
            {
                cardImg: b48,
                cardTag: 'Dec 17, 2020',
                cardTtitle: 'WhatsAppening to Insurance?',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/whatsappening-to-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b47,
                cardTag: 'Dec 17, 2020',
                cardTtitle: 'Information, insights, news about insurance, healthcare.',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/coming-soon?s=r',
                new_page_open: true
            },
            {
                cardImg: b46,
                cardTag: 'Dec 10, 2020',
                cardTtitle: 'Telemedicine- Yay or Nay?',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/telemedicine-yay-or-nay?s=r',
                new_page_open: true
            },
            {
                cardImg: b45,
                cardTag: 'Nov 25, 2020',
                cardTtitle: 'Artivatic Insurtech Guiding Insurance in to Digital Age.',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/artivatic-insurtech-guiding-insurance-in-to-digital-age?s=r',
                new_page_open: true
            },
            {
                cardImg: b44,
                cardTag: 'Oct 22, 2020',
                cardTtitle: 'Artivatic.ai Hiring for Multiple Profiles',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/artivatic-ai-hiring-for-multiple-profiles?s=r',
                new_page_open: true
            },
            {
                cardImg: b43,
                cardTag: 'Oct 21, 2020',
                cardTtitle: 'Role of Telehealth During COVID-19',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/role-of-telehealth-during-covid-19?s=r',
                new_page_open: true
            },

            {
                cardImg: b42,
                cardTag: 'Oct 07, 2020',
                cardTtitle: 'Artivatic wins RAISE 2020 - AI in Healthcare & Insurance',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/artivatic-wins-raise-2020-ai-in-healthcare-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b41,
                cardTag: 'Sep 29, 2020',
                cardTtitle: 'ALFRED - Unified AI Health Claims Automation, Risk, Fraud & Decision Platform',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/alfred-unified-ai-health-claims-automation-risk-fraud-decision-platform?s=r',
                new_page_open: true
            },

        ];
        all_data6 = [
            {
                cardImg: b40,
                cardTag: 'Sep 21, 2020',
                cardTtitle: 'IRDA Allows Insures to Accept Video KYC',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/irda-allows-insures-to-accept-video-kyc?s=r',
                new_page_open: true
            },
            {
                cardImg: b39,
                cardTag: 'Sep 01, 2020',
                cardTtitle: 'Digital adoption in insurance ecosystem',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/digital-adoption-in-insurance-ecosystem?s=r',
                new_page_open: true
            },
            {
                cardImg: b38,
                cardTag: 'Jul 19, 2020',
                cardTtitle: 'CARSURE - Vehicle Damage Inspection & Claims Automation is on IBM',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/carsure-vehicle-damage-inspection-claims-automation-is-on-ibm?s=r',
                new_page_open: true
            },
            {
                cardImg: b37,
                cardTag: 'Jul 06, 2020',
                cardTtitle: 'Artivatic featured by @CBInsights among Top Global Insurtech',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/artivatic-featured-by-cbinsights-among-top-global-insurtech?s=r',
                new_page_open: true
            },
            {
                cardImg: b36,
                cardTag: 'Jul 01, 2020',
                cardTtitle: 'Role of AI Solving Insurance Fraud & Claims Automation at scale',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/role-of-ai-solving-insurance-fraud-claims-automation-at-scale?s=r',
                new_page_open: true
            },
            {
                cardImg: b35,
                cardTag: 'Jun 20, 2020',
                cardTtitle: "MiO by Artivatic Partners with IBM Cognitive Systems",
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/mio-by-artivatic-partners-with-ibm-cognitive-systems?s=r',
                new_page_open: true
            },
            {
                cardImg: b34,
                cardTag: 'Jun 20, 2020',
                cardTtitle: "Alfred’s Health Claims Automation Platform Partners with IBM Cognitive Business",
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/alfreds-health-claims-automation-platform-partners-with-ibm-cognitive-business?s=r',
                new_page_open: true
            },
            {
                cardImg: b33,
                cardTag: 'Jun 16, 2020',
                cardTtitle: 'Redefine service through Connected Field Service',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/redefine-service-through-connected-field-service?s=r',
                new_page_open: true
            },
            {
                cardImg: b32,
                cardTag: 'Jun 16, 2020',
                cardTtitle: 'Customer story: Rochester Institute of Technology levels the playing field with AI for students who are deaf',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/customer-story-rochester-institute-of-technology-levels-the-playing-field-with-ai-for-students-who-are-deaf?s=r',
                new_page_open: true
            },
            {
                cardImg: b31,
                cardTag: 'Jun 16, 2020',
                cardTtitle: '5 Signs a Company has Outgrown its Accounting Solution',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/5-signs-a-company-has-outgrown-its-accounting-solution?s=r',
                new_page_open: true
            },
            {
                cardImg: b30,
                cardTag: 'Jun 10, 2020',
                cardTtitle: 'Grade assignments in Teams',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/grade-assignments-in-teams?s=r',
                new_page_open: true
            },
            {
                cardImg: b29,
                cardTag: 'Jun 09, 2020',
                cardTtitle: "Don't miss another post! Subscribe now.",
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/dont-miss-another-post-subscribe-now?s=r',
                new_page_open: true
            },
            {
                cardImg: b28,
                cardTag: 'Jun 09, 2020',
                cardTtitle: 'Technology: How it Benefits Small Business',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/technology-how-it-benefits-small-business?s=r',
                new_page_open: true
            },

            {
                cardImg: b27,
                cardTag: 'Jun 09, 2020',
                cardTtitle: 'How Airbus is transforming aerospace with Microsoft Azure Stack',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/how-airbus-is-transforming-aerospace-with-microsoft-azure-stack?s=r',
                new_page_open: true
            },
            {
                cardImg: b26,
                cardTag: 'Jun 09, 2020',
                cardTtitle: 'Opportunity Heats Up as Honeywell Connects with Homeowners',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/opportunity-heats-up-as-honeywell-connects-with-homeowners?s=r',
                new_page_open: true
            },

        ];
        all_data7 = [
            {
                cardImg: b25,
                cardTag: 'Jun 09, 2020',
                cardTtitle: 'Customer Story: NQ Cranes customers come first with Microsoft Dynamics 365 Business Central',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/customer-story-nq-cranes-customers-come-first-with-microsoft-dynamics-365-business-central?s=r',
                new_page_open: true
            },
            {
                cardImg: b24,
                cardTag: 'Jun 07, 2020',
                cardTtitle: 'thyssenkrupp transforms its home mobility solutions business with Microsoft HoloLens',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/thyssenkrupp-transforms-its-home-mobility-solutions-business-with-microsoft-hololens?s=r',
                new_page_open: true
            },
            {
                cardImg: b23,
                cardTag: 'Jun 05, 2020',
                cardTtitle: 'AUSIS – Partners with Microsoft Azure',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/ausis-partners-with-microsoft-azure?s=r',
                new_page_open: true
            },
            {
                cardImg: b22,
                cardTag: 'Jun 05, 2020',
                cardTtitle: 'Steve Gleason on technology and making ideas come to life',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/steve-gleason-on-technology-and-making-ideas-come-to-life?s=r',
                new_page_open: true
            },
            {
                cardImg: b21,
                cardTag: 'Jun 03, 2020',
                cardTtitle: 'AUSIS - AI Underwriting by Artivatic - Available on IBM Cloud & Power AI Platform',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/ausis-ai-underwriting-by-artivatic-available-on-ibm-cloud-power-ai-platform?s=r',
                new_page_open: true
            },
            {
                cardImg: b20,
                cardTag: 'Jun 02, 2020',
                cardTtitle: "MiO - Video Branch & App for PoS, Policy, Payment, Sales & On-Boarding",
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/mio-video-branch-app-for-pos-policy-payment-sales-on-boarding?s=r',
                new_page_open: true
            },
            {
                cardImg: b19,
                cardTag: 'May 26, 2020',
                cardTtitle: "Adoption of Technologies for Claims Management in the Health Insurance Sectors",
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/adoption-of-technologies-for-claims-management-in-the-health-insurance-sector?s=r',
                new_page_open: true
            },
            {
                cardImg: b18,
                cardTag: 'May 21, 2020',
                cardTtitle: 'Press Release | NMC Genetics Partners with Artivatic to build innovative solutions for insurance and preventive healthcare.',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/nmc-genetics-artivatic-partnership?s=r',
                new_page_open: true
            },
            {
                cardImg: b17,
                cardTag: 'May 20, 2020',
                cardTtitle: 'Digital Innovations in Agri Insurance',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/digital-innovations-in-agri-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b16,
                cardTag: 'May 17, 2020',
                cardTtitle: 'Insurance Agents needs re-skilling & new techs to learn for survival.',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/insurance-agents-needs-re-skilling-new-techs-to-learn-for-survival?s=r',
                new_page_open: true
            },
            {
                cardImg: b15,
                cardTag: 'May 17, 2020',
                cardTtitle: 'How Insurance Agent are struggling with Sales & Payment due to COVID-19?',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/how-insurance-agent-are-struggling-with-sales-payment-due-to-covid-19?s=r',
                new_page_open: true
            },
            {
                cardImg: b14,
                cardTag: 'May 17, 2020',
                cardTtitle: "Artivatic reduced failure rate for health insurance claims denial for USA based RCM Company.",
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/claims-denial-us-revenue-cycle-company-ai-artivatic?s=r',
                new_page_open: true
            },
            {
                cardImg: b13,
                cardTag: 'May 17, 2020',
                cardTtitle: 'Personal Accidental Claims & Investigation Audit Automation Case Study for SBI General Insurance by Artivatic',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/personal-accidental-claims-sbi-general-ai?s=r',
                new_page_open: true
            },

            {
                cardImg: b12,
                cardTag: 'May 15, 2020',
                cardTtitle: 'Health Insurance Access in Rural Areas',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/health-insurance-access-in-rural-areas?s=r',
                new_page_open: true
            },
            {
                cardImg: b11,
                cardTag: 'May 14, 2020',
                cardTtitle: "What You Should Know About India’s Rs. 20 Lakh Crore Package.",
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/what-you-should-know-about-indias-rs-20-lakh-crore-package?s=r',
                new_page_open: true
            },

        ];
        all_data8 = [
            {
                cardImg: b10,
                cardTag: 'May 14, 2020',
                cardTtitle: 'Top Immunity Boosting Foods | Foods to Eat Now !',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/top-immunity-boosting-foods-foods-to-eat-now?s=r',
                new_page_open: true
            },
            {
                cardImg: b9,
                cardTag: 'May 12, 2020',
                cardTtitle: 'Top 6 Reasons to Adopt Insurance Technology due to COVID - 19',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/top-6-reasons-to-adopt-insurance-technology-due-to-covid-19?s=r',
                new_page_open: true
            },
            {
                cardImg: b8,
                cardTag: 'May 11, 2020',
                cardTtitle: 'ALFERD (AI Claims Automation) helped a general Insurance Giant to enable automated claims (damage assessment) under 180 seconds.',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/alfered-claims-automation-ai?s=r',
                new_page_open: true
            },
            {
                cardImg: b7,
                cardTag: 'May 07, 2020',
                cardTtitle: 'AUSIS (AI Underwriting Platform) helped a Life Insurance Giant in India to improve their complex underwriting journey to be simple, automated & in real- time.',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/ai-underwriting-life-health-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b6,
                cardTag: 'May 06, 2020',
                cardTtitle: 'Insurance Innovation Using AI Tech: How Artivatic is disrupting Insurance?',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/insurance-innovation-using-ai-tech-how-artivatic-is-disrupting-insurance?s=r',
                new_page_open: true
            },
            {
                cardImg: b5,
                cardTag: 'May 06, 2020',
                cardTtitle: "Access to Insurance by Billions!",
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/access-to-insurance-by-billions?s=r',
                new_page_open: true
            },
            {
                cardImg: b4,
                cardTag: 'May 05, 2020',
                cardTtitle: "AI Based Smart Digital Branch /Office Platform — MiO By Artivatic",
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/ai-based-smart-digital-branch-office-platform-mio-by-artivatic?s=r',
                new_page_open: true
            },
            {
                cardImg: b3,
                cardTag: 'May 05, 2020',
                cardTtitle: '5 Remote Working Tips during COVID-19 by Artivatic',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/5-remote-working-tips-during-covid-19-by-artivatic?s=r',
                new_page_open: true
            },
            {
                cardImg: b2,
                cardTag: 'May 05, 2020',
                cardTtitle: 'Covid-19: How Artivatic is ensuring Business Continuity for Insurance Companies?',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/covid-19-how-artivatic-is-ensuring-business-continuity-for-insurance-companies?s=r',
                new_page_open: true
            },
            {
                cardImg: b1,
                cardTag: 'May 05, 2020',
                cardTtitle: 'Insurtech Innovation is transforming businesses in India.',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/insurtech-innovation-is-transforming-businesses-in-india?s=r',
                new_page_open: true
            },
        ];
        containHead = 'Explore All Our Blog Resources';
    }
    if (type === 'infographics') {
        all_data1 = [
            {
                cardImg: c14,
                shareimg:'http://localhost:3000/static/media/14.c1758caf.png',
                cardTag: 'Mar 17,2022',
                cardDes: 'Artivatic’s Video KYC solution provides instantly onboard users & customers with AI-powered Digital/Video KYC.',
                cardTtitle: 'Infographic: Video based KYC in Insurance- Trend to watch out for!',
                cardBtnText: 'Read More',
                link: pdf4,
                new_page_open: true,
                openframe: true,

            },
            {
                cardImg: c15,
                cardTag: 'Mar 11,2022',
                cardTtitle: 'Infographic- The Future of Digital Insurance Agency',
                cardBtnText: 'Read More',
                link: pdf5,
                new_page_open: true,
                openframe: true
            },
            {
                cardImg: c13,
                cardTag: 'Feb 28,2022',
                cardTtitle: 'Infographic- Insurance Agent Training- Road to Excellence',
                cardBtnText: 'Read More',
                link: pdf6,
                new_page_open: true,
                openframe: true
            },
            {
                cardImg: c12,
                cardTag: 'Feb 15,2022',
                cardTtitle: 'Infographic: Insurtech 2.0- How IoT will transform the industry',
                cardBtnText: 'Read More',
                link: pdf3,
                new_page_open: true,
                openframe: true
            },
            {
                cardImg: c11,
                cardTag: 'Feb 10,2022',
                cardTtitle: 'Chatbots: The Game Changer in Insurance Sector',
                cardBtnText: 'Read More',
                link: pdf14,
                new_page_open: true,
                openframe: true
            },
            {
                cardImg: c10,
                cardTag: 'Feb 8,2022',
                cardTtitle: 'Infographic- The changing scenario of Automated Healthcare Sector',
                cardBtnText: 'Read More',
                link: pdf13,
                new_page_open: true,
                openframe: true
            },
            {
                cardImg: c9,
                cardTag: 'Jan 28,2022',
                cardTtitle: 'Infographic: Insurtech Startup Ecosystem in India',
                cardBtnText: 'Read More',
                link: pdf8,
                new_page_open: true,
                openframe: true
            },
            {
                cardImg: c8,
                cardTag: 'Jan 20,2022',
                cardTtitle: 'Blockchain Adoption in Healthcare Sector',
                cardBtnText: 'Read More',
                link: pdf12,
                new_page_open: true,
                openframe: true
            },
            {
                cardImg: c7,
                cardTag: 'Jan 12,2022',
                cardTtitle: 'Insurtech- Digital Vision 2022',
                cardBtnText: 'Read More',
                link: pdf15,
                new_page_open: true,
                openframe: true
            },
            {
                cardImg: c6,
                cardTag: 'Jan 07,2022',
                cardTtitle: 'Redefining Underwriting with Automation',
                cardBtnText: 'Read More',
                link: pdf7,
                new_page_open: true,
                openframe: true
            },
            {
                cardImg: c5,
                cardTag: 'Dec 28, 2021',
                cardTtitle: 'Insurance Selling- Automation Advancing the Process',
                cardBtnText: 'Read More',
                link: pdf9,
                new_page_open: true,
                openframe: true
            },
            {
                cardImg: c4,
                cardTag: 'Dec 15, 2021',
                cardTtitle: 'The Rise of Automation in Employee Health Benefits',
                cardBtnText: 'Read More',
                link: pdf2,
                new_page_open: true,
                openframe: true
            },
            {
                cardImg: c3,
                cardTag: 'Dec 08, 2021',
                cardTtitle: 'AI & Technology is enabling Health Claims, Patients & Provider Ecosystem',
                cardBtnText: 'Read More',
                link: pdf11,
                new_page_open: true,
                openframe: true
            },

            {
                cardImg: c2,
                cardTag: 'Nov 29, 2021',
                cardTtitle: 'Insurance Sector Transformation through Open API Ecosystem',
                cardBtnText: 'Read More',
                link: pdf10,
                new_page_open: true,
                openframe: true
            },
            {
                cardImg: c1,
                cardTag: 'Nov 23, 2021',
                cardTtitle: 'Insurtech 2022- Your Success Essential Tool Kit',
                cardBtnText: 'Read More',
                link: pdf1,
                new_page_open: true,
                openframe: true
            },

        ];
        containHead = 'Explore All Our Infographics';
    }
    if (type === 'podcasts') {
        all_data1 = [
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'Success Story of Artivatic: AI-driven Automation Service for Insurance & Health',
                cardBtnText: 'Read More',
                link: 'https://startuptalky.com/artivatic-success-story/',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'HR leaders’ nightmare: Lack of proper Background Verifications and its aftermath',
                cardBtnText: 'Read More',
                link: 'https://hr.economictimes.indiatimes.com/news/workplace-4-0/recruitment/hr-leaders-nightmare-lack-of-proper-background-verifications-and-its-aftermath/89311192',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'Union Budget 2022 - what are startups expecting from FM Nirmala Sitharaman ahead of this budget',
                cardBtnText: 'Read More',
                link: 'https://www.zeebiz.com/hindi/video-union-budget-2022-what-are-startups-expecting-from-fm-nirmala-sitharaman-ahead-of-this-budget-72533',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'Is Tech driving transformation of Buying & Selling Insurance by simplification?',
                cardBtnText: 'Read More',
                link: 'https://successinsightsindia.com/is-tech-driving-transformation-of-buying-selling-insurance-by-simplification/#_ftn1',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'IOT is the Future of Insurance.The InsurTech segment has been attracting lots of funding in recent years, both in India and overseas.',
                cardBtnText: 'Read More',
                link: 'https://www.healthcareradius.in/features/technology/iot-is-the-future-of-insurance-%ef%bf%bc',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'Artivatic.AI launches integrated platform for insurance sales, customer engagement',
                cardBtnText: 'Read More',
                link: 'https://www.livemint.com/companies/news/artivaticai-launches-integrated-platform-11641989946644.html',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: '“National Startup day is a validation of start-ups’ role in the growth of India’s GDP”',
                cardBtnText: 'Read More',
                link: 'https://www.adgully.com/national-startup-day-is-a-validation-of-start-ups-role-in-the-growth-of-india-s-gdp-113230.html',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: '#startupoftheday 96- Artivatic.AI , an AI-powered digital, risk & decision Insurtech platform',
                cardBtnText: 'Read More',
                link: 'https://www.biospectrumindia.com/features/85/20365/startupoftheday-96-artivaticai.html',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'InsurTech – the Future of Insurance',
                cardBtnText: 'Read More',
                link: 'https://thesmeindia.com/smes-will-see-growth-on-the-back-of-better-insurance-plans-that-are-customised-to-their-particular-needs-cost-savings-and-automated-quality-backend-services/',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'Top Insurtech trends to look forward to in 2022',
                cardBtnText: 'Read More',
                link: 'https://timesofindia.indiatimes.com/blogs/voices/top-insurtech-trends-to-look-forward-to-in-2022/',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'How & why year-end is the right time to choose health insurance?',
                cardBtnText: 'Read More',
                link: 'https://health.economictimes.indiatimes.com/news/industry/how-why-year-end-is-the-right-time-to-choose-health-insurance/88617985',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'Artivatic.AI launches shared employee benefit placement system',
                cardBtnText: 'Read More',
                link: 'https://www.livemint.com/insurance/news/artivaticai-launches-shared-employee-benefit-placement-system-11640264483332.html',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'Job opportunities in the insurance sector',
                cardBtnText: 'Read More',
                link: 'https://www.deccanherald.com/supplements/dh-education/job-opportunities-in-the-insurance-sector-1060957.html',
                new_page_open: true
            },

            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'How technology can help start-ups, businesses & SMEs choose right group health & commercial insurance',
                cardBtnText: 'Read More',
                link: 'https://timesofindia.indiatimes.com/blogs/voices/how-technology-can-help-start-ups-businesses-smes-choose-right-group-health-commercial-insurance/',
                new_page_open: true
            },
            {
                cardImg: RectangleMob,
                cardTag: '',
                cardTtitle: 'Artivatic launches smart AI underwriting platform named AUSIS-Financial Express',
                cardBtnText: 'Read More',
                link: 'https://blog.artivatic.ai/p/artivatic-launches-smart-ai-underwriting',
                new_page_open: true
            },

        ];
        containHead = 'Explore All Our Podcasts';
    }
    const history = useHistory();
    const navigate = () => {
        if (all_data1[0].link !== '') {
            localStorage.setItem('ingographicfilename', JSON.stringify(all_data1[0].cardTtitle));
            localStorage.setItem('ingographicfilelink', JSON.stringify(all_data1[0].link));
            history.push({ pathname: `/resources/${type}/view/${all_data1[0].cardTag}/pdf` });
        }
    }

    return (
        <>
            <Helmet>
                <title>Artivatic | Resources</title>
                <meta name="description" content="Explore All Our Blogs,Infographics,Podcasts and Webinars" />
                <meta name="keywords" content={`${type} | Artivatic | Resources`} />
                <link rel="canonical" href={`/resources/${type}`} />

                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`https://artivatic.ai/resources/${type}/`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`Artivatic | ${type}`} />
                <meta property="og:description" content={`${all_data1[0].cardDes}`} />
                <meta property="og:image" content={`${all_data1[0].shareimg}`} />
                <meta property="og:image:width" content='1200' />
                <meta property="og:image:height" content='630' />

                {/* Twitter Meta Tags  */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="artivatic.ai" />
                <meta property="twitter:url" content={`https://artivatic.ai/resources/${type}/`} />
                <meta name="twitter:title" content={`Artivatic | ${type}`} />
                <meta name="twitter:description" content={`${all_data1[0].cardDes}`} />
                <meta name="twitter:image" content={`${all_data1[0].shareimg}`} />
                <meta property="og:image:width" content='1200' />
                <meta property="og:image:height" content='630' />
            </Helmet>
            <div className="newsandmedia">
                <div className='main2'>
                    <section className="home">
                        <img className="home-img" src={all_data1[0].cardImg} alt="" />
                        <div className="home-info">
                            <h1 className="heading-tertiary heading-tertiary--green" style={{ 'textTransform': 'capitalize' }}>{type}</h1>
                            <h1 className="heading-primary">{all_data1[0].cardTtitle}</h1>
                            <p className="text-primary">{all_data1[0].cardDes}</p>
                            <p className="text-primary text-date">{all_data1[0].cardTag}</p>
                            {type === 'blogs' && (<a className="btn news-view-btn" href={all_data1[0].link} target='_blank'>
                                Read more
                                <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.30347 7.98935L18.5111 7.98935L14.7306 11.4998C14.2092 11.984 14.2092 12.7103 14.7306 13.1945C15.2521 13.6787 16.0342 13.6787 16.5557 13.1945L22.5523 7.6262C23.0737 7.14201 23.0737 6.41571 22.5523 5.93152L16.5557 0.36325C16.0342 -0.120947 15.2521 -0.120947 14.7306 0.36325C14.2092 0.847447 14.2092 1.57374 14.7306 2.05794L18.5111 5.56837L1.30348 5.56837C0.651671 5.56837 -0.000130951 6.05256 -0.000131014 6.77886C-0.000131078 7.50515 0.651671 7.98935 1.30347 7.98935Z"
                                        fill="#22BB66" />
                                </svg>
                            </a>
                            )}
                            {type === 'infographics' && (<a className="btn news-view-btn" onClick={navigate}>
                                Read more
                                <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.30347 7.98935L18.5111 7.98935L14.7306 11.4998C14.2092 11.984 14.2092 12.7103 14.7306 13.1945C15.2521 13.6787 16.0342 13.6787 16.5557 13.1945L22.5523 7.6262C23.0737 7.14201 23.0737 6.41571 22.5523 5.93152L16.5557 0.36325C16.0342 -0.120947 15.2521 -0.120947 14.7306 0.36325C14.2092 0.847447 14.2092 1.57374 14.7306 2.05794L18.5111 5.56837L1.30348 5.56837C0.651671 5.56837 -0.000130951 6.05256 -0.000131014 6.77886C-0.000131078 7.50515 0.651671 7.98935 1.30347 7.98935Z"
                                        fill="#22BB66" />
                                </svg>
                            </a>
                            )}
                        </div>

                    </section>
                </div>
            </div>
            <div className="blogbox">
                <section className="blogscontainer">
                    <p className="exl">{containHead}</p>
                    <div className="line3"></div>
                    <div className="sliderboxr">
                        {all_data1 && all_data1.length > 0 && (<Carousel
                            id="resource-section__carousel1"
                            containerClassName="resource-section__carousel__container"
                            prevControlClassName="resource-section__carousel__prev-controlr"
                            nextControlClassName="resource-section__carousel__next-controlr"
                            fade="primary-vl"
                            navColor="#22b966"
                            dotNav={false}
                        >
                            {all_data1 ? all_data1.map((item, index) => {
                                return (
                                    <Card
                                        key={index}
                                        from="product_page_blog_section"
                                        tag={item.cardTag}
                                        title={item.cardTtitle}
                                        ancLink={item.link}
                                        btnText={item.cardBtnText}
                                        cardImg={item.cardImg}
                                        flag='resource_page'
                                        openframe={item.openframe ? item.openframe : false}
                                        resource_type={type}
                                    />
                                )
                            }) : ''}
                        </Carousel>
                        )}

                        {all_data2 && all_data2.length > 0 && (<Carousel
                            id="resource-section__carousel2"
                            containerClassName="resource-section__carousel__containerr"
                            prevControlClassName="resource-section__carousel__prev-controlr"
                            nextControlClassName="resource-section__carousel__next-controlr"
                            fade="primary-vl"
                            navColor="#22b966"
                            dotNav={false}
                        >
                            {all_data2 ? all_data2.map((item, index) => {
                                return (
                                    <Card
                                        key={index}
                                        from="product_page_blog_section"
                                        tag={item.cardTag}
                                        title={item.cardTtitle}
                                        ancLink={item.link}
                                        btnText={item.cardBtnText}
                                        cardImg={item.cardImg}
                                        flag='resource_page'
                                        openframe={item.openframe ? item.openframe : false}
                                        resource_type={type}
                                    />
                                )
                            }) : ''}
                        </Carousel>
                        )}

                        {all_data3 && all_data3.length > 0 && (<Carousel
                            id="resource-section__carousel3"
                            containerClassName="resource-section__carousel__containerr"
                            prevControlClassName="resource-section__carousel__prev-controlr"
                            nextControlClassName="resource-section__carousel__next-controlr"
                            fade="primary-vl"
                            navColor="#22b966"
                            dotNav={false}
                        >
                            {all_data3 ? all_data3.map((item, index) => {
                                return (
                                    <Card
                                        key={index}
                                        from="product_page_blog_section"
                                        tag={item.cardTag}
                                        title={item.cardTtitle}
                                        ancLink={item.link}
                                        btnText={item.cardBtnText}
                                        cardImg={item.cardImg}
                                        flag='resource_page'
                                        openframe={item.openframe ? item.openframe : false}
                                        resource_type={type}
                                    />
                                )
                            }) : ''}
                        </Carousel>
                        )}

                        {all_data4 && all_data4.length > 0 && (<Carousel
                            id="resource-section__carousel4"
                            containerClassName="resource-section__carousel__containerr"
                            prevControlClassName="resource-section__carousel__prev-controlr"
                            nextControlClassName="resource-section__carousel__next-controlr"
                            fade="primary-vl"
                            navColor="#22b966"
                            dotNav={false}
                        >
                            {all_data4 ? all_data4.map((item, index) => {
                                return (
                                    <Card
                                        key={index}
                                        from="product_page_blog_section"
                                        tag={item.cardTag}
                                        title={item.cardTtitle}
                                        ancLink={item.link}
                                        btnText={item.cardBtnText}
                                        cardImg={item.cardImg}
                                        flag='resource_page'
                                        openframe={item.openframe ? item.openframe : false}
                                        resource_type={type}
                                    />
                                )
                            }) : ''}
                        </Carousel>
                        )}

                        {all_data5 && all_data5.length > 0 && (<Carousel
                            id="resource-section__carousel5"
                            containerClassName="resource-section__carousel__containerr"
                            prevControlClassName="resource-section__carousel__prev-controlr"
                            nextControlClassName="resource-section__carousel__next-controlr"
                            fade="primary-vl"
                            navColor="#22b966"
                            dotNav={false}
                        >
                            {all_data5 ? all_data5.map((item, index) => {
                                return (
                                    <Card
                                        key={index}
                                        from="product_page_blog_section"
                                        tag={item.cardTag}
                                        title={item.cardTtitle}
                                        ancLink={item.link}
                                        btnText={item.cardBtnText}
                                        cardImg={item.cardImg}
                                        flag='resource_page'
                                        openframe={item.openframe ? item.openframe : false}
                                        resource_type={type}
                                    />
                                )
                            }) : ''}
                        </Carousel>
                        )}

                        {all_data6 && all_data6.length > 0 && (<Carousel
                            id="resource-section__carousel6"
                            containerClassName="resource-section__carousel__containerr"
                            prevControlClassName="resource-section__carousel__prev-controlr"
                            nextControlClassName="resource-section__carousel__next-controlr"
                            fade="primary-vl"
                            navColor="#22b966"
                            dotNav={false}
                        >
                            {all_data6 ? all_data6.map((item, index) => {
                                return (
                                    <Card
                                        key={index}
                                        from="product_page_blog_section"
                                        tag={item.cardTag}
                                        title={item.cardTtitle}
                                        ancLink={item.link}
                                        btnText={item.cardBtnText}
                                        cardImg={item.cardImg}
                                        flag='resource_page'
                                        openframe={item.openframe ? item.openframe : false}
                                        resource_type={type}
                                    />
                                )
                            }) : ''}
                        </Carousel>
                        )}

                        {all_data7 && all_data7.length > 0 && (<Carousel
                            id="resource-section__carousel7"
                            containerClassName="resource-section__carousel__containerr"
                            prevControlClassName="resource-section__carousel__prev-controlr"
                            nextControlClassName="resource-section__carousel__next-controlr"
                            fade="primary-vl"
                            navColor="#22b966"
                            dotNav={false}
                        >
                            {all_data7 ? all_data7.map((item, index) => {
                                return (
                                    <Card
                                        key={index}
                                        from="product_page_blog_section"
                                        tag={item.cardTag}
                                        title={item.cardTtitle}
                                        ancLink={item.link}
                                        btnText={item.cardBtnText}
                                        cardImg={item.cardImg}
                                        flag='resource_page'
                                        openframe={item.openframe ? item.openframe : false}
                                        resource_type={type}
                                    />
                                )
                            }) : ''}
                        </Carousel>
                        )}

                        {all_data8 && all_data8.length > 0 && (<Carousel
                            id="resource-section__carousel8"
                            containerClassName="resource-section__carousel__containerr"
                            prevControlClassName="resource-section__carousel__prev-controlr"
                            nextControlClassName="resource-section__carousel__next-controlr"
                            fade="primary-vl"
                            navColor="#22b966"
                            dotNav={false}
                        >
                            {all_data8 ? all_data8.map((item, index) => {
                                return (
                                    <Card
                                        key={index}
                                        from="product_page_blog_section"
                                        tag={item.cardTag}
                                        title={item.cardTtitle}
                                        ancLink={item.link}
                                        btnText={item.cardBtnText}
                                        cardImg={item.cardImg}
                                        flag='resource_page'
                                        openframe={item.openframe ? item.openframe : false}
                                        resource_type={type}
                                    />
                                )
                            }) : ''}
                        </Carousel>
                        )}


                    </div>
                </section>

            </div>


        </>
    )
}

export default Resources
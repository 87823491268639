import React from "react";
import { useParams } from 'react-router';
// Images
import attach from "../../assets/vector/aattach.svg"

import "./jobsubmit.css";

export default function JobSubmit() {
  const params = useParams();
  let jobhead;
  if(params.jobid==='1') {
    jobhead = <h1>Backend Developer</h1>;
  }
  if(params.jobid ==='2') {
    jobhead = <h1>Client Relations & Inbound sales</h1>;
  }
  return (
    <div id="JobSubmit">
      <section className="job-details">
        {jobhead}
        <div className="line"></div>
        <div className="box">
          <h6>Submit your Application</h6>
          <div className="col-md">
            <label>Resume/CV</label>
            <div className="upload-btn-wrapper">
              <button><img src={attach} alt=''></img>Attach your resume/CV</button>
              <input type="file" name="myfile" />
            </div>
          </div>
          <div className="col-md">
            <label className="required">Full name</label>
            <input type="text" name="fullname"></input>
          </div>
          <div className="col-md">
            <label className="required">Email</label>
            <input type="email" name="email"></input>
          </div>
          <div className="col-md">
            <label className="required">phone</label>
            <input type="number" name="phone"></input>
          </div>
          <div className="col-md">
            <label>Current company (If any)</label>
            <input type="text" name="company"></input>
          </div>
          <h6>Links</h6>
          <div className="col-md">
            <label className="required">LinkedIn URL</label>
            <input type="text" name="linkedin"></input>
          </div>
          <div className="col-md">
            <label>Portfolio URL</label>
            <input type="text" name="portfolio"></input>
          </div>
          <div className="col-md">
            <label>Other website</label>
            <input type="text" name="website"></input>
          </div>
          <h6>Addtional Information</h6>
          <div className="col-md">
            <textarea name="message" rows="8" placeholder="Add a cover letter or anything else you want to share.">
            </textarea>
          </div>
        </div>
        <div className="applyjob2">
          <a href="/career">Apply for this Job</a>
        </div>
      </section>
    </div>
  );
}
import React from 'react';
import styles from './APIs.module.css';

const APIs = () => {

    const api_list = [
        {
            category: 'Analytics APIs',
            sublist: [
                { name: 'Analytics Pincode', des: 'Provide Analytics pincode wise data' },
                { name: 'Analytics Financial', des: 'Provide Analytics Financial data' },
                { name: 'Analytics Diagnostics', des: 'Provide Analytics Diagnostics data' },
                { name: 'Analytics Income', des: 'Provide Analytics of Income data' },
                { name: 'Journey Data', des: 'Provide Journey data' },
                { name: 'Fraud Prediction', des: 'Provide Fraud prediction data' },
                { name: 'Civil Fraud', des: 'Provide Civil Fraud data' },
                { name: 'Married Status', des: 'Provide married status data' },
                { name: 'Nl Document', des: 'Provide NL Document' },
                { name: 'Analytics State', des: 'Provide Analytics State data' },
                { name: 'Analytics Cities', des: 'Provide Analytics City data' },
                { name: 'Company News Data', des: 'Provide company news data' }

            ]
        },
        {
            category: 'Healthcare APIs',
            sublist: [
                { name: 'AQI Data', des: 'Provide AQI Details of each city' },
                { name: 'Device Health', des: 'Provide Device Health Data' },
                { name: 'Location Health', des: 'Provide Location Health Data' },
                { name: 'Medical Documents', des: 'Provide Medical Documents related Data Insights' },
                { name: 'Onboarding', des: 'For Signing up process on Healthcare platform' },

            ]
        },
        {
            category: 'Underwriting',
            sublist: [
                { name: 'Health Risk', des: 'Provide Health Risk Data' },
                { name: 'Financial Risk', des: 'Provide Financial Risk Data' },
                { name: 'Alternative Data', des: '' },
                { name: 'Insurance Scoring', des: '' },
                { name: 'Decision Making', des: '' }
            ]
        },
        {
            category: 'OCR APIs',
            sublist: [
                { name: 'KYC APIs', des: 'Provide KYC Data for each customer' },
                { name: 'Document Classifier', des: 'Classify the documents' },
                { name: 'Medical Bills', des: 'Provide Medical Bills Data' },
                { name: 'Health Policy', des: 'Provide Health Policy Documents' },
                { name: 'Motor Policy', des: 'Provide Motor Policy Documents' },
                { name: 'General Invoice Extract', des: 'Provide OCR Extraction Data from Invoice' },
                { name: 'Kyc Masking', des: 'KYC Masking OCR API' },
                { name: 'Discharge Summary', des: 'Provide Discharge Summary Documents' },
                { name: 'Financial Docs Reader Cheque', des: 'OCR Extraction Data from Cheque' }

            ]
        },
        {
            category: 'Govt. API',
            sublist: [
                { name: 'Verify Bank', des: 'Verify Bank  Details' },
            ]
        },
        {
            category: 'Face APIs',
            sublist: [
                { name: 'Age Predict', des: "Age Predictor from user's face" },
                { name: 'Smoker Detector', des: "Smoker Detector from user's face" },
                { name: 'BMI Predict', des: "BMI Predictor from user's face" },
                { name: 'Face Match', des: "Face Match API" },
            ]
        },

        {
            category: 'Claims APIs',
            sublist: [
                { name: 'Document Processing', des: 'Provide all kinds of Claim Documents Processing' },
                { name: 'Fraud Intelligence', des: 'Provide Insights on Fraud Percentage' },
                { name: 'Investigation', des: 'Provide Investigation Report end-to-end Process' },
                { name: 'Automation', des: 'Fully Automated end-to-end Claim Filing Process' },

            ]
        },
        {
            category: 'Sales APIs',
            sublist: [
                { name: 'Lead Conversion', des: 'Provide Lead Conversion analytics Data' },
                { name: 'Lead Scoring', des: 'Provide Lead Conversion analytics Data' },
                { name: 'Communication', des: 'Communications via APIs' },
            ]
        },


    ]

    return (
        <React.Fragment>
            <div className={styles.wrap}>
                <div className={styles.topsec}>
                    <h1 className={styles.exploretext}>Explore All 400+ Cloud APIs</h1>
                    <div className={styles.line3}></div>
                </div>
                {api_list.map((item, index) => (
                    <div className={styles.sec} key={index}>
                        <h1 className={styles.catname}>{item.category}</h1>
                        <p className={styles.line}></p>
                        <div className={styles.box}>
                            {item.sublist.map((value, index2) => (
                                <div className={styles.card} key={index2}>
                                    <p className={styles.name}>{value.name}</p>
                                    <span className={styles.des}>{value.des}</span>

                                </div>
                            ))}
                        </div>
                    </div>

                ))}
                <div className={styles.btnbox}>
                    <button className={styles.brbtn}><a href='https://infrd.artivatic.ai/main' target='_blank'>Browse More</a>
                    </button>
                </div>
            </div>

            <div className={styles.line2}></div>
        </React.Fragment>
    )
}

export default APIs;
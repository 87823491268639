//Images
import solutionHealthFrontimg from '../../assets/Solution Page Health Insurance Part Image.svg';
import solutionSMEFrontimg from '../../assets/Front Image.png';
import solutionSmeImg from '../../assets/solutions/solutionsme2.png';
import solutionmifrontImg from '../../assets/solutions/mainmi.webp';
import solutionmisubImg from '../../assets/solutions/submi.webp';
import solutionHCImg from '../../assets/solutions/Hospital And clinics - Main Image.webp';
import solutionHCsecImg from '../../assets/solutions/Hospital And clinics - Second Image.webp';
import solutionTPAFrontImg from '../../assets/Main Image.png';
import solutionTPA1Img from '../../assets/solutions/Second Image 1.png';
import solutionTPA2Img from '../../assets/solutions/Second Image 2.png';
import RectangleDesk from "../../assets/png/Rectangle 184.png";
import RectangleMob from '../../assets/png/Rectangle 187.png';
import b1img from '../../assets/png/resources34/71 - Front Page.png';
import aspirebs5 from '../../assets/products/Aspire/4.png';
import aspirebs6 from '../../assets/products/Aspire/7.png';
import ausisImg from '../../assets/solutions/ausis1.png';
import alfredImg from '../../assets/solutions/Alfred.png';
import aspireImg from '../../assets/solutions/SME Page - Aspire Image.png';
import prodxImg from '../../assets/solutions/ProdX.png';
import tariffImg from '../../assets/solutions/Tariff Contract.png';
import dbfrontImg from '../../assets/solutions/Distribution And Broker - Front.png';
import dbImg from '../../assets/solutions/Distribution - (2).png';
import blog1 from '../News and Media/Assets/png/1.png';
import r9 from '../News and Media/Assets/png/10.png';
import n4 from '../News and Media/Assets/png/n4.png';
import salesFrontImg from '../../assets/solutions/Sales Front Image.png';
import salesManImg from '../../assets/solutions/Sales Management.png';
import salesMarImg from '../../assets/solutions/Marketing Management.png';
import b45 from '../../assets/Blog Section - Images/45.png';
import b90 from '../../assets/Blog Section - Images/90.png';
import b91 from '../../assets/Blog Section - Images/91.png';
import b97 from '../../assets/Blog Section - Images/97.png';
import b84 from '../../assets/Blog Section - Images/84.png';
import b41 from '../../assets/Blog Section - Images/41.png';
import b74 from '../../assets/Blog Section - Images/74.png';
import b58 from '../../assets/Blog Section - Images/58.png';
import c3 from '../../assets/Infographic - images/3.png';
import b94 from '../../assets/Blog Section - Images/94.png';
import c2 from '../../assets/Infographic - images/2.png';
import b79 from '../../assets/Blog Section - Images/79.png';
import b92 from '../../assets/Blog Section - Images/92.png';
import b71 from '../../assets/Blog Section - Images/71.png';
import b89 from '../../assets/Blog Section - Images/89.png';
import b65 from '../../assets/Blog Section - Images/65.png';
import b38 from '../../assets/Blog Section - Images/38.png';
import b59 from '../../assets/Blog Section - Images/59.png';
import solutionghfrontImg from '../../assets/solutions/Group Health - Front.webp';
import solutionghsecondImg from '../../assets/solutions/Group Health - Second Image.webp';
import solutionghthirdImg from '../../assets/solutions/Group Health - Third.webp';

import solutionboxfrontImg from '../../assets/solutions/Insurance In a Box - Front.webp';
import solutionboxsecondImg from '../../assets/solutions/Insurance In a Box - Second.webp';

import solutiongaragefrontImg from '../../assets/solutions/solfrontgarage.webp';
import solutiongarage2Img from '../../assets/solutions/garage2.webp';

import solutionOEMfrontImg from '../../assets/solutions/OEMmain.webp';
import solutionOEM1Img from '../../assets/solutions/OEM1.webp';
import solutionOEM2Img from '../../assets/solutions/OEM2.webp';

import solutionREIFrontImg from '../../assets/solutions/Front.webp';
import solutionREI2Img from '../../assets/solutions/Second.webp';

import solEFmain from '../../assets/solutions/solefmain.webp';
import solEF2 from '../../assets/solutions/solef2.webp';




export const solution_list = [
  {
    head: 'Health Insurance Solutions',
    logoimg: 'https://artivatic.ai/static/media/Solution%20Page%20Health%20Insurance%20Part%20Image.6bc86ee6.svg',
    imgintro: solutionHealthFrontimg,
    tagline: 'Simplify your customers’ healthcare journeys with a complete core administration health insurance platform.',
    productsolhead: 'Comprehensive health insurance solutions for all insurers!',
    box1heading1: 'AI Smart Underwriting Platform',
    box1head1: 'A customised AI built & 3rd party data driven smart, real-time & personalized Underwriting Platform for Insurance',
    p1img: ausisImg,
    p1pointers: [
      {
        c1 : [
          { 
            name : 'Connected ecosystem for teleMER for automation',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Integrated platform for medical diagnostics, ECG, TMT scheduling and reporting',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Platform for patients, employers and employees for comprehensive health ecosystem as policy buying claims, OPD, Doctor consulting',
            bold : false
          }
        ],
      }
    ],
    p2img: alfredImg,
    box1head2: 'An integrated smart AI driven platform easing and automating all your claims processing requirements. The Engine Provides end to end claims automation, assessment and decisioning',
    box1heading2: 'Transforming Health Insurance & Health Claims',
    p2pointers: [
      'Integrated Health, Wellness, Fitness & Mental Health Platform',
      'Ecosystem for Hospital Network, Clinics and Doctors',
      'Connected platform for TPA, Insurers, brokers, hospitals & patients',
      'Health recommendation, disease management and connected health fitness devices'
    ],
    box1head3: 'A holistic Next-Gen rapid product designing and a customised B2B2C distribution and embedded insurance platform for businesses',
    box1heading3: 'Next-gen Rapid Product Designing & Distribution with Risk Insights',
    p3img: prodxImg,
    p3pointers: [
      'Byte sized, transactional, pay as you go/use, stackable, regular product design in rapid way.',
      'Combine products with health loyalty, credit cards and more in Realtime',
      'Create rules, rules libraries, design journey, create riders, export products API for distribution.',
      'Helps insurance partners to manage their revenue, policies, connect for self-claims to its customers. Monitor all API status, product status, product info, benefits all in single platform.',
      'Risk & Insights Driven, Need Based Product Design',
      'New Age, Data Driven Faster Product Design',
      'Reduce Cost Up to 50%'
    ],
    impactperhead: 'Proven Impact On Our Health Insurance Customers',
    impactdata: [
      { val: '28%', text: [{ t1: 'Uplift in', bold: false }, { t1: 'Customer Engagement', bold: true }] },
      { val: '64%', text: [{ t1: 'Improvement in Sales', bold: false }, { t1: 'Conversion rate', bold: true }] },
      { val: '90%', text: [{ t1: 'Uplift in', bold: false }, { t1: 'Intelligence & Automation', bold: true }] }
    ],
    journeyhead: 'A modern technology platform for healthcare insurance is finally here',
    journeydata: [
      { name: 'Understand Customers', text: 'Improve your understanding of customer behaviour and risk' },
      { name: 'Ecosystem', text: 'Complete insurtech ecosystem' },
      { name: 'End to end Claim Processing', text: 'Reduce operational costs and simplify claims administration with improved claims pricing and intelligent, automated claims adjudication. Efficiently manage provider contracts, getting complete traceability of your provider contract configurations and corresponding claims pricing.' },
      { name: 'Omnichannel Distribution', text: "Enables products to be sold online, through brokers, or from insurer's internal staff." }
    ],
    impact2data: [],
    special_section_head: 'Tariff Contract Management and Digitization',
    special_section_subhead: 'We model, update and manage your contracts set-up. Easy set-up and use',
    special_section_data: [
      'Automated tariff upload journey',
      'Automated Tariff Negotiation and Freezing contracts by E -Digital signing',
      'Tariff price cross matching and verification with billing prices',
      'Tariff digitization, standardization and storage for new tariff and renewals',
      'AI decisioning for tariff and analytics'
    ],
    special_section_img: tariffImg,
    resource_list: [
      {
        cardImg: blog1,
        cardTag: 'Blog',
        cardTtitle: 'Artivatic launches smart AI underwriting platform named AUSIS-Financial Express',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/artivatic-launches-smart-ai-underwriting',
        new_page_open: true
      },
      {
        cardImg: r9,
        cardTag: 'News',
        cardTtitle: 'Artivatic Launches ALFRED AI HEALTH CLAIMS Solution for Automating End to end Health Claims',
        cardBtnText: 'Read More',
        link: 'https://www.prnewswire.com/in/news-releases/artivatic-launches-alfred-ai-health-claims-solution-for-automating-end-to-end-health-claims-869305036.html',
        new_page_open: true
      },
      {
        cardImg: n4,
        cardTag: 'News',
        cardTtitle: 'Is Tech driving transformation of Buying & Selling Insurance by simplification?',
        cardBtnText: 'Read More',
        link: 'https://successinsightsindia.com/is-tech-driving-transformation-of-buying-selling-insurance-by-simplification/#_ftn1',
        new_page_open: true
      }
    ]
  },
  {
    head: 'Insurance Solutions for SME/Commercial Businesses',
    logoimg: 'https://artivatic.ai/static/media/Front%20Image.f21a24cb.png',
    imgintro: solutionSMEFrontimg,
    tagline: 'Customize. Cover. Claim -The ultimate SME insurance solution to protect your SME Business.',
    productsolhead: 'Package Solutions for Small and Medium - sized Businesses',
    box1head1: 'SaaS Based, Self-Managed, Data Driven Personalized Insurance Platform for SME/Businesses for Buy, Renewal, Manage: Employees + Indemnity',
    p1img: aspireImg,
    p1pointers: [
      {
        c1 : [
          { 
            name : 'Platform allows to buy personalized insurance based on risk for employees, liability, property and more.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Helps businesses to manage their various locations (warehouse, manufacturing location, office etc.) to ensure have right insurance personalized to every location.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Manage employees, employee health, wellness, addons, claims insights',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Realtime Group Health & SME/Business Underwriting system connect for personalized policy issuance. Analytics & Insights.',
            bold : false
          }
        ],
      }
    ],
    p2img: solutionSmeImg,
    box1head2: 'Rapid, Self-managed: Business Insurance Platform',
    p2pointers: [
      'Full stack API Based self-managed business insurance platform that allows create account, buy policies digitally, connect to 3rd parties, build risk and more.',
      'Transparent payment, claims management system for SME/Businesses',
      'Ensure low manual intervention and allow partners to use, buy, manage as they want',
      'Assess the vast new-to-insurance and thin-file segment of partners /distributors /SME/Businesses'
    ],
    impactperhead: 'Proven Impact on our SME/Commercial Insurance Customers',
    impactdata: [
      { val: '100X', text: [{ t1: 'Reduce', bold: false }, { t1: 'Policy Buying time ', bold: true }] },
      { val: '50%', text: [{ t1: 'Reduce', bold: false }, { t1: 'Cost', bold: true }] },
      { val: '80%', text: [{ t1: 'Automated', bold: false }, { t1: 'Decision Policy', bold: true }] }
    ],
    journeyhead: 'How Our product ASPIRE HEALTH helps to manage the entire health policy & claim management process in one shared system',
    journeydata: [
      { name: '', text: 'Improve task management workflow' },
      { name: '', text: 'Dynamically customize your offering' },
      { name: '', text: 'Expand your customer base' }
    ],
    impact2data: [],
    resource_list: [
      {
        cardImg: b1img,
        cardTag: 'Blog',
        cardTtitle: 'Simplifying Group Health & Commercial Insurance for Businesses, SMEs & Start-ups',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/simplifying-group-health-and-commercial?s=w',
        new_page_open: true
      },
      {
        cardImg: aspirebs5,
        cardTag: 'Blog',
        cardTtitle: 'Future of SMBs and Commercial Insurance',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/future-of-smbs-and-commercial-insurance?s=w',
        new_page_open: true
      },
      {
        cardImg: aspirebs6,
        cardTag: 'Blog',
        cardTtitle: 'Introducing A Shared & Automated Employee Benefits Placement Platform : ASPIRE HEALTH',
        cardBtnText: 'Read More',
        link: 'https://blog.aspirebq.com/p/introducing-a-shared-and-automated',
        new_page_open: true
      }
    ]
  },
  {
    head: 'Insurance Sales & Marketing Evolved',
    logoimg: 'https://artivatic.ai/static/media/Sales%20Front%20Image.9f6ed79b.png',
    imgintro: salesFrontImg,
    tagline: 'Unleash the power of automation to streamline insurance sales & marketing management.',
    productsolhead: 'Empowering insurance players with a specialized AI based Sales & Marketing Platform to turn every opportunity into ROI.',
    box1head1: 'Comprehensive platform to automate sales team onboarding and process monitoring through rich contextual data. Key features include :',
    p1img: salesManImg,
    box1head1tag: 'Sales Management',
    p1pointers: [
      {
        c1 : [
          { 
            name : 'Agent Prospecting',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Agent Onboarding',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Sales tracking',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Segmentation',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Commission management',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : '360-degree visibility',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Smart Admin Panel',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Customizable access control',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Mobile platform for agents',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Behavioural insights',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Customer portal',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Insurance Quote generation',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Insurance Need analysis',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Customizable sales process',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Sales Insights',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Smart views',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Intelligent targeting',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Real time Task allocation',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Geo-tracking',
            bold : false
          }
        ],
      }
    ],
    p2img: salesMarImg,
    box1head2: 'Now you can create and share social media content on our platform enabling improved information dissemination with customers. Key features include :',
    p2pointers: [
      'Create content',
      'Share content',
      'Social media integrations',
      'Campaign management',
      'Smart views',
      'Performance insights'

    ],
    box1head2tag: 'Marketing Management',
    impactperhead: 'Proven Impact on our insurance & financial services providers',
    impactdata: [
      { val: '80%', text: [{ t1: 'Lead analysis, lead profiling, lead scoring, data enrichment', bold: false },] },
      { val: '60%', text: [{ t1: 'Lead Conversion', bold: true }, { t1: 'using AI based decision system', bold: false }] },
      { val: '45%', text: [{ t1: 'Reduce', bold: false }, { t1: 'Tat', bold: true }] }
    ],
    journeyhead: 'Our product MiO Sales Platform is new age sales, marketing & customer relations platform that drives efficiency, scale, customer intelligence, performance and ROI to businesses',
    journeydata: [
      { name: 'Connect Anytime, Anywhere', text: 'Connect with your client over multitude of communication channels at click of a button' },
      { name: 'AI powerhouse', text: 'Optimise your sales strategy through system recommendations on customer profile, product recommendations, process, workflows, Data sharing' },
      { name: 'Call Analytics', text: 'Capture critical information from voice conversations between policyholders and insurance advisors, or contact centre representatives' }
    ],
    impact2data: [],
    resource_list: [
      {
        cardImg: b91,
        cardTag: 'Blog',
        cardTtitle: 'Insurance Selling through MiO Sales',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/insurance-selling-through-mio-sales',
        new_page_open: true
      },
      {
        cardImg: b97,
        cardTag: 'Blog',
        cardTtitle: 'Role of Insurance Agents in the AI Age',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/role-of-insurance-agents-in-the-ai',
        new_page_open: true
      },
      {
        cardImg: b84,
        cardTag: 'Blog',
        cardTtitle: 'Benefits of AI in Insurance Sales',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/benefits-of-ai-in-insurance-sales',
        new_page_open: true
      }
    ]
  },
  {
    head: 'Distributor and Broker Management for Insurance',
    logoimg: 'https://artivatic.ai/static/media/Distribution%20And%20Broker%20-%20Front.49ac8e89.png',
    imgintro: dbfrontImg,
    tagline: 'Configurable solutions that improve efficiency, reduce costs, and increase revenue',
    productsolhead: 'Powerful, Modern, Scalable',
    impactperhead: 'Proven Impact on our insurance partners and distribution companies',
    impactdata: [
      { val: '100X', text: [{ t1: 'Reduce', bold: true }, { t1: 'decisioning time', bold: false }] },
      { val: '50%', text: [{ t1: 'Reduce', bold: true }, { t1: 'cost', bold: false }] }
    ],
    journeyhead: 'Byte-sized, stackable, regular and pay as you go product design and distribution in a BOX',
    journeydata: [
      { name: 'Enhance user-experience', text: 'Empowering your agents and brokers with superior technology allows them to deliver a modern and delightful customer experience' },
      { name: 'Agency self-service', text: 'Enabling self-service for your agents to ensure that your team has more bandwidth to focus on more critical aspects of the business' },
      { name: 'Greater reach and speed', text: 'Modern distribution technology provides a wider distribution network and faster speed to market through an effective multi-channel approach' }
    ],
    impact2data: [],
    special_section_head: 'Insurance Distributor Management Platform',
    special_section_subhead: 'Your distribution strategy is key to growing your business. Providing a single, scalable platform delivering speed, improved information management and modern technology to your agents and brokers is vital for both business growth and improving operating efficiencies. Digitalizing distribution channels ensures a more rapid expansion of your distribution network, greater speed to market and an efficient multi-channel strategy.',
    special_section_data: [
      'A central system to manage policy products, sales and revenue streams, claims, payments etc and distribute them across partners via APIs',
      'Multi user login platform for insurance companies, insurance brokers, enterprises & Fintech/Banks',
      'Insurance in a Box uses a SaaS (Software as a Solution) implementation and deployment model, leveraging the power of cloud-based delivery to be quick off the blocks.',
      'Management of policy contracts between partners',
      'Automated onboarding Digital Journey planner through Data Mapping',
      'Manage regulatory changes in an agile way. Compliance gaps are eliminated with our continually market-ready modular insurance solutions'
    ],
    special_section_img: dbImg,
    resource_list: [
      {
        cardImg: b45,
        cardTag: 'Blog',
        cardTtitle: 'Artivatic Insurtech Guiding Insurance in to Digital Age',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/artivatic-insurtech-guiding-insurance-in-to-digital-age',
        new_page_open: true
      },
      {
        cardImg: b90,
        cardTag: 'Blog',
        cardTtitle: "Reigniting Insurance Distribution: One API for All your distribution 'Insurance-in-a-Box'",
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/reigniting-insurance-distribution',
        new_page_open: true
      }
    ]
  },
  {
    head: 'Third Party Administrator for Health Insurance',
    logoimg: 'https://artivatic.ai/static/media/Main%20Image.b26d93c8.png',
    imgintro: solutionTPAFrontImg,
    tagline: 'Leverage the power of Artivatic’s Healthcare TPA solutions to perform key business processes better and faster',
    productsolhead: 'New Age Digital TPA to Delight Customers',
    productsolsubhead: 'Artivatic offers a comprehensive array of healthcare TPA solutions, or third-party administrator solutions, services, and workflow processes that deliver uncompromising value and sustainable cost savings. Our high-performance approach to outsourcing brings improved methodologies, state-of-the-art IT infrastructures, and a knowledgeable staff made available through a world-class shared service center.',
    box1head1: 'Explore our Healthcare TPA Solutions',
    p1img: solutionTPA1Img,
    p1pointers: [
      {
        c1 : [
          { 
            name : 'Unified Provider Platform',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Patient & Doctor CRM',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Schedule of Charges /SOC update/communication in real-time',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Medical History of Patients',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Claims Filing, Update, Approval, History & Tracking',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Patient Health Advisory',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Doctor Medical Decision Making',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Loyalty Cards /Health Cards & Discounts Management',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Wellness, Mental Community Platform',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Lab tests & Packages',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Loyalty Cards',
            bold : false
          }
        ],
      }
    ],
    p2img: solutionTPA2Img,
    box1head2: 'Value Added Services',
    p2pointers: [
      'Health Claims Filing & Intimation',
      'Patient Profiling & Digital Consent',
      'Digital Claims Process',
      'Alert & Update for Health Claims',
      'Authorize Claims Digitally',
      'Instant Claims Payout'
    ],
    impactperhead: 'Proven Impact on our Health Insurance Customers',
    impactdata: [
      { val: '97%', text: [{ t1: 'Settlement Ratio', bold: true }] },
      { val: '40%', text: [{ t1: 'Conversion', bold: true }, { t1: 'and engagement', bold: false }] }
    ],
    journeyhead: 'Smart Technology, Smart People',
    journeysubhead: 'Our Third-Party Administrator is a 360 platform that focusses on our twin strengths of People and Technology to provide personalized yet efficient services to our customers',
    journeydata: [
      { name: 'Fast', text: 'Make each interaction before, during and after hospitalization quick and efficient' },
      { name: 'Smart', text: "Advance Data Analytics for lower ICR's" },
      { name: 'Reliable', text: '24×7×365 access to Health insurance benefits' },
      { name: 'Extensive', text: 'Allow members to plan their healthcare spend efficiently to enjoy better care at lesser costs' },
      { name: 'Emphatic', text: 'Personalized & predictable service' }
    ],
    impact2data: [],
    resource_list: [
      {
        cardImg: b41,
        cardTag: 'Blog',
        cardTtitle: 'ALFRED - Unified AI Health Claims Automation, Risk, Fraud & Decision Platform',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/alfred-unified-ai-health-claims-automation-risk-fraud-decision-platform',
        new_page_open: true
      },
      {
        cardImg: b74,
        cardTag: 'Blog',
        cardTtitle: 'Automating End to End Health Claims using AI & Data: ALFRED HEALTH CLAIMS',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/automating-end-to-end-health-claims',
        new_page_open: true
      },
      {
        cardImg: b58,
        cardTag: 'Blog',
        cardTtitle: 'ALFRED: Transforming Health Claims using AI',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/alfred-transforming-health-claims',
        new_page_open: true
      }
    ]
  },
  {
    head: 'Artivatic’s Healthcare ALFRED PROVIDER Networking Solution',
    logoimg: '',
    imgintro: solutionHCImg,
    tagline: 'AI-Driven 360-degree platform that connects the 3P’s- Patients, Providers, and Payers',
    productsolhead: 'A Provider Network Management Platform for Healthcare Insurance',
    productsolsubhead: 'It’s a 360-degree platform which has 15000+ network hospitals/clinics/OPD/diagnostic labs under one roof connecting them with payers and patients for ease of real time auto claim intimation/processing, updates and settling finances related to claims. It also supports Provider empanelment along with Tariff Digitization and Negotiations, providing its AI driven insights',
    box1head1: 'Alfred - Provider Network Solution offers :',
    p1img: solutionHCsecImg,
    p1pointers: [
      {
        c1 : [
          { 
            name : 'Process and settle claims faster and minimize productivity Loss',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Pre-mapped and tested processes for health plans/payers',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Managed care organizations',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : '15000+ network of hospitals/ clinic/OPD/Diagnostic Labs',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Provides single Dashboard for Hospital, Clinic and Diagnostic labs',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Provider Empanelment and Auto Provider ID generation',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Auto Tariff Management/Digitization and Negotiations',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Digi signing or E Signing of MOU’s and Tariff contracts',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Hospital Network and Profiling  including Patients/Doctors/Nurses/Staff /Management and Pharmacy network integrations',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Provider Analytics for insights on Fraudulent Providers',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Wellness Program/ Analytics and setups for better healthcare',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Access to Patients for live booking for Tests/order Medicine/intimate claims and track them through integrated partners in place',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Integrated Physician Networks',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Integrated Diagnostic laboratory Network',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Integrated OPD network and Access to OPD',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Integrated Nurses/Staff/management and pharmaceutical network',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Health/Medical Data structuring and Analytics',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Third-Party Administrators (TPAs) integrations and analytics',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Health claims financing and Payments',
            bold : false
          }
        ],
      }
    ],
    impactperhead: 'Proven Impact on our Health Insurance Customers',
    impactdata: [
      { val: '15000+', text: [{ t1: 'Network', bold: true }, { t1: 'of Hospitals/Clinic/OPD/Diagnostic Labs', bold: false }] },
      { val: '10%', text: [{ t1: 'Manual Intervention', bold: false }] },
      { val: '50%', text: [{ t1: 'Increase in', bold: false }, { t1: 'Customer Satisfaction', bold: true }] }
    ],
    impact2data: [],
    resource_list: [
      {
        cardImg: c3,
        cardTag: 'Blog',
        cardTtitle: 'AI & Technology is enabling Health Claims, Patients & Provider Ecosystem',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/technology-enabling-health-claims?s=w',
        new_page_open: true
      },
      {
        cardImg: b41,
        cardTag: 'Blog',
        cardTtitle: 'ALFRED - Unified AI Health Claims Automation, Risk, Fraud & Decision Platform',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/alfred-unified-ai-health-claims-automation-risk-fraud-decision-platform?s=w',
        new_page_open: true
      },
      {
        cardImg: b58,
        cardTag: 'Blog',
        cardTtitle: 'ALFRED: Transforming Health Claims using AI',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/alfred-transforming-health-claims',
        new_page_open: true
      }
    ]
  },
  {
    head: 'Powering Micro /Sachet /Gig Insurance Using Technology',
    logoimg: '',
    imgintro: solutionmifrontImg,
    tagline: 'Need Based Risk Assessment for Building future insurance products with Single API for Everyone',
    productsolhead: 'Smart Pricing for Multiple Insurance, Based on Realtime Data based Risk Assessment',
    productsolsubhead: 'Artivatic’s existing API Risk assessment platform will enable to manufacture new products in quick time with individual risk assessment for any insurance category Using 3rd parties, govt historical, environmental, social, location, device, activities, weather, claims, IoT, Farming, satellite and more types of data-based intelligence risk to ensure providing the right price and benefits to each category based on need',
    box1head1: 'Explore Our Digital Solution',
    p1img: solutionmisubImg,
    p1pointers: [
      {
        c1 : [
          { 
            name : 'Micro insurance; Sachet / Need based Insurance',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Insurance on Tap –policy issuance & claims processing TAT <3 Min',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Economical Insurance –Reduces premium with based on real time data from over 100+ different sources',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Digital claims journey to process claims within minutes and make it hassle free',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'No Touch Insurance End to end policy issuance with the help of digital branch connected with video and speech',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Enables customer to get access to insurance in Realtime based on their needs',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Health Micro Insurance (For ex, dengue, mosquito, COVID, Daily Cash etc.)',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Life Micro Insurance (Credit linked, funerals, low cover, disability etc.)',
            bold : false
          }
        ],
      }
    ],
    impactperhead: 'Proven Impact on Our Health Insurance Customers',
    impactdata: [
      { val: '100%', text: [{ t1: 'Real Time', bold: true }, { t1: 'Data', bold: false }] },
      { val: '<3 Min', text: [{ t1: 'TAT Policy issuance & claims processing', bold: false }] },

    ],
    journeyhead: 'On Demand Insurance Platform in Single API',
    journeysubhead: 'From quoting to claims, our technology platform powers the most innovative insurance solutions on the planet',
    journeydata: [
      { name: 'Smart Pricing', text: 'Dynamic pricing based on real-time data customized for any customer or product' },
      { name: 'Customer Happiness', text: 'Access to billions low income and need based customer. Protection. Happiness' },
      { name: 'Access.Increase in ROI', text: "Experience higher profitability, at the same levels of approval with reducing loss of claims" }
    ],
    impact2data: [],
    resource_list: [
      {
        cardImg: b94,
        cardTag: 'Blog',
        cardTtitle: 'What APIs mean for an open and connected insurance industry',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/what-apis-mean-for-an-open-and-connected',
        new_page_open: true
      },
      {
        cardImg: c2,
        cardTag: 'Blog',
        cardTtitle: 'Insurance Sector Transformation through Open API Ecosystem',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/insurance-sector-transformation-through',
        new_page_open: true
      },
      {
        cardImg: b79,
        cardTag: 'Blog',
        cardTtitle: 'The Untapped Potential of APIs in the Insurance Sector',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/the-untapped-potential-of-apis-in',
        new_page_open: true
      }
    ]
  },
  {
    head: 'Group Health Insurance Customized for Business',
    logoimg: '',
    imgintro: solutionghfrontImg,
    tagline: 'Enabling SME/Enterprises to Self-Buy, Manage Personalized Insurance for Employees /Group Health',
    productsolhead: 'Supercharge your Health Benefits',
    productsolsubhead: 'AI & Data Analytics focused first Employee Benefit & Group Health System (EBHS), a new category of enterprise software for brokers and carriers',
    box1head1: 'Explore Our GROUP HEALTH Solutions',
    p1img: solutionghsecondImg,
    p1pointers: [
      {
        c1 : [
          { 
            name : 'An effective EBHS accounts for the specific workflows and dynamic relationships across stakeholders—brokers, carriers, third-parties, and the client',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Whether collecting a renewal or marketing for new business, a EBHS addresses end-to-end needs: from data collection to underwriting review and client presentation to implementation',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'A EBHS is one system that provides real-time, shared visibility and enables brokers and carriers to deliver the best possible outcomes for their mutual clients',
            bold : false
          }
        ],
      },
      
      
      

    ],
    p2img: solutionghthirdImg,
    box1head2: 'Value Added Benefits',
    p2pointers: [
      'Connect to multiple partners for providing value added services for employees',
      'Manage employees, endowment, employee health, wellness, addons, claims insights',
      'Realtime Group Health Underwriting system for personalized policy issuance. Analytics & Insights',
      'Platform allows to buy personalized insurance based on risk for employees group health with       customization and addons of teleconsulting, wellness, mental health etc',
      'Connect APIs to build onboarding journey, claims journey within few hrs'

    ],
    impactperhead: 'Proven Impact on our Health Insurance Customers',
    impactdata: [
      { val: '50%', text: [{ t1: 'Faster', bold: true }, { t1: 'Issuance', bold: false }] },
      { val: '100X', text: [{ t1: 'Reduce', bold: true }, { t1: 'Policy Buying', bold: false }] },
      { val: '100%', text: [{ t1: 'Paperless', bold: true }, { t1: 'Process', bold: false }] }
    ],
    journeyhead: 'Give your team Health benefits that matter',
    journeydata: [
      { name: 'Self-Managed Products & Claims Platform', text: 'Ensure low manual intervention and allow partners to use, buy, manage as they want' },
      { name: 'Decrease Turn-around time', text: 'Start selling insurance under 24 hrs using data driven insights and risk scoring' },
      { name: 'Employee Health Platform', text: 'Employee network platform for policy/e-card manage, wellness, claims, mental health etc.' },

    ],
    impact2data: [],
    resource_list: [
      {
        cardImg: b89,
        cardTag: 'Blog',
        cardTtitle: 'How Web 3.0 will transform the insurance sector?',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/how-web-30-will-transform-the-insurance',
        new_page_open: true
      },
      {
        cardImg: b71,
        cardTag: 'Blog',
        cardTtitle: 'Simplifying Group Health & Commercial Insurance for Businesses, SMEs & Start-ups',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/simplifying-group-health-and-commercial',
        new_page_open: true
      },
      {
        cardImg: b92,
        cardTag: 'Blog',
        cardTtitle: 'Web 3.0 and its massive use cases for Healthcare Sector',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/web-30-and-its-massive-use-cases',
        new_page_open: true
      }
    ]
  },
  {
    head: 'Gamifying Motor Buying Insurance Experience using AI & Telematics',
    logoimg: '',
    imgintro: solutionOEMfrontImg,
    tagline: 'Based on CRM Data building 360 profiling of customers including digital footprints to find if they need to buy new insurance',
    productsolhead: 'Next-Gen Dealer Management Systems (DMS) in the Automotive / Manufacturing space',
    productsolsubhead: 'Top automotive CRM software offers all the necessary tools for manufacturers, specialized automotive companies, and dealerships to attract and keep leads and increase sales conversions. Artivatic’s Dealership CRM software focuses on providing a unique experience for car buyers and more',
    box1head1: 'A centralized CRM in automotive creates opportunities for more personalized customer interactions and relationship management for all involved parties',
    box1head1tag: 'OEMs',
    p1img: solutionOEM1Img,
    p1pointers: [
      {
        c1 : [
          { 
            name : 'Enables agents or customers to directly upload images or videos to claims under 180 seconds',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Reduces time & operational cost significantly',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Reduces fraud & risk propensity',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Finds part-based claims pricing for replacement, repair or cleaning ',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Enables automated image labelling /annotation and re training.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'APIs can be connected with OEM data or insurance companies having sensor, odometer & other past data with combination of third-party APIs, to develop risk scoring, patterns and more to provide insurance premium.',
            bold : false
          }
        ],
      }
    ],
    p2img: solutionOEM2Img,
    box1head2: 'Independent auto dealer CRM can best organize workflow - and ultimately sell more vehicles. They distribute leads on a cyclical schedule, optimizing test drives and setting up the entire sales process',
    box1head2tag: 'Dealership Management',
    p2pointers: [
      'Improves online customer engagement months before the actual purchase.',
      'Enables dealers to provide the most personalized customer service possible.',
      'Minimizes the incidence of missed leads, as dealers can always look into the CRM and contact the customer to proceed and complete the deal.',
      'Assists in the scheduling of test drive requests from interested customers. It also provides automated email confirmation and adds a prospect to your daily schedule.',
      'Helps dealers track the car delivery process.',
      'Permits salespeople to automate lead distribution and manage scheduled tasks and customer appointments.',
      'Profiling customers based on past data, payments, policies etc. to make sure for renewals of polices to reduce their policy costs.'

    ],
    impactperhead: 'Proven Impact on our Insurance Customers',
    impactdata: [
      { val: '30%', text: [{ t1: 'reduction in', bold: false }, { t1: 'Operational Process', bold: true }] },
      { val: '50%', text: [{ t1: 'increase in', bold: false }, { t1: 'Customer Satisfaction', bold: true }] },
      { val: '90%', text: [{ t1: 'reduction in ', bold: false }, { t1: 'Time Processing', bold: true }] }
    ],
    journeyhead: 'Get complete funnel visibility for your dealership',
    journeysubhead: 'Track your marketing, sales, dealer & partner activities - in one, easy-to-use platform',
    journeydata: [
      { name: 'Ensure proper Follow-ups', text: 'To qualify & convert more leads to opportunities' },
      { name: 'Ensure Dealer Accountability', text: "Get a consolidated view of all the partners & dealerships" },
      { name: 'Engage meaningfully with prospects', text: 'And increase inquiry to conversion rates' },
      { name: 'Track Offline interactions', text: 'with easy-to-use Mobile CRM' },

    ],
    impact2data: [],
    resource_list: [
      {
        cardImg: b65,
        cardTag: 'Blog',
        cardTtitle: 'AI & Browser Driven Real-time Motor Pre Inspection & Damage Assessment : CARSURE',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/ai-and-browser-driven-real-time-motor',
        new_page_open: true
      },
      {
        cardImg: b38,
        cardTag: 'Blog',
        cardTtitle: 'CARSURE - Vehicle Damage Inspection & Claims Automation is on IBM',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/carsure-vehicle-damage-inspection-claims-automation-is-on-ibm',
        new_page_open: true
      },
      {
        cardImg: b59,
        cardTag: 'Blog',
        cardTtitle: 'Browser Based Real-time Motor Pre Inspection & Claims Estimation : CARSURE',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/carsure-ai-motor-claims-preinspection',
        new_page_open: true
      }
    ]
  },
  {
    head: 'AI Based Garage Management System',
    logoimg: '',
    imgintro: solutiongaragefrontImg,
    tagline: 'Building digitally enabled eco-systems to transform the auto service experience!',
    productsolhead: 'GARAGE MANAGEMENT SYSTEM',
    productsolsubhead: 'Artivatic’s Garage Management System for automating end to end automobile dealerships and independent garages. By automating tasks related to the garage, we help insurance companies to improve the overall efficiency of motor claims management and provide customers with real-time information and status of motor repairs',
    p1img: solutiongarage2Img,
    box1head1: 'PLATFORM HIGHLIGHTS',
    p1pointers: [
      {
        c1 : [
          { 
            name : 'Easy Onboarding and Management of Garages -',
            bold : true
          },
          { 
            name : 'The platform is available on-demand and needs no additional software to be implemented which makes it easy for onboarding garages into it.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Detailed Vehicle Damage Information to Garages -',
            bold : true
          },
          { 
            name : 'Garages will get real-time access to information on vehicle damage as filed in the surveyor report. They will also have access to supporting images and videos, which will allow the garage to assess the repair more accurately and submit the best quote online.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Garage Panel Configuration by Insurers -',
            bold : true
          },
          { 
            name : 'To make the selection process easier for insurers by classifying garages based on location, quality of service, price etc the platform allows insurers to select and group multiple garages to create a preferred garage panel for work allocation.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Spare Parts Price List Management -',
            bold : true
          },
          { 
            name : 'To cut down fraudulent practices in quotation and invoicing for insurers, and to ensure availability of spare parts for garages, the platform maintains the spare parts inventory and price list for easy reference.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Invoicing covers, Invoicing for customer and insurance, part invoice for customers and Insurance, detailed Invoice with tax, spares cost, labour charges.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Inventory Management -',
            bold : true
          },
          { 
            name : 'Vehicles and Spares in transit, real-time stock, visibility on sales, stock value, multiple garage transfer, aging and more.',
            bold : false
          }
        ],
      }
    ],
    impactperhead: 'Proven Impact on our Insurance Customers',
    impactdata: [
      { val: '300%', text: [{ t1: 'Improvement in', bold: false }, { t1: 'Claims Processing Time', bold: true }] },
      { val: '40%', text: [{ t1: 'Reduction in', bold: false }, { t1: 'Fraud Image detection', bold: true }] },
      { val: '20%', text: [{ t1: 'Improvement in', bold: false }, { t1: 'Automated Claims Issuance', bold: true }] }
    ],
    journeyhead: 'Complete Garage Management, 360-degree platform!',
    journeysubhead: '',
    journeydata: [
      { name: 'Vehicle Damage Assessment', text: 'AI Based Mobile & Web Platform for Vehicle damage detection using Video & Photo under 60 seconds' },
      { name: 'Claims Price Estimation', text: "Using ML, Loss estimation for vehicle damages using historical learning and vendor, parts, vehicle based" },
      { name: 'Service Invoice Processing', text: 'Using OCR/ICR, Automating QC for Service Invoices and proceed for quick pay out to customers' },
      { name: 'Fraud Detection', text: 'Detect fraudulent inspections, including old damages, missing parts, blurry and low light photos or videos' },
      { name: 'Self-Training', text: 'Automated Damage, Parts Labelling Platform to train and provide accurate results in terms of claims & price estimation' },

    ],
    impact2data: [],
    resource_list: [
      {
        cardImg: b65,
        cardTag: 'Blog',
        cardTtitle: 'AI & Browser Driven Real-time Motor Pre Inspection & Damage Assessment : CARSURE',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/ai-and-browser-driven-real-time-motor',
        new_page_open: true
      },
      {
        cardImg: b38,
        cardTag: 'Blog',
        cardTtitle: 'CARSURE - Vehicle Damage Inspection & Claims Automation is on IBM',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/carsure-vehicle-damage-inspection-claims-automation-is-on-ibm',
        new_page_open: true
      },
      {
        cardImg: b59,
        cardTag: 'Blog',
        cardTtitle: 'Browser Based Real-time Motor Pre Inspection & Claims Estimation : CARSURE',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/carsure-ai-motor-claims-preinspection',
        new_page_open: true
      }
    ]
  },
  {
    head: "One API for all your distribution 'Insurance-in-a-Box'",
    logoimg: '',
    imgintro: solutionboxfrontImg,
    tagline: 'Fast-track insurance solution with a cost-effective and efficient full-service insurance platform, fresh out of the box',
    productsolhead: 'NEW AGE DIGITAL INSURACE DISTRIBUTION SOLUTION TO DELIGHT CUSTOMERS',
    productsolsubhead: "The Insurance-in-a-Box toolkit takes a partner-driven approach, enabling you to bring best-in-class products to market, simply and efficiently. By paying only for the ready-made insurance components you choose, you can increase in scale without the need for large investment capital.",
    productsolsubhead2: 'The platform covers all core processes, from sales and underwriting to collections and claims. Solutions are pre-integrated to minimise time to market, while maximising integration with open APIs. General insurance products can be launched in under two months, and life and pensions products in fewer than 30 days.',
    p1img: solutionboxsecondImg,
    box1head1: 'Explore Platform Benefits',

    p1pointers: [
      {
        c1 : [
          { 
            name : 'Expertly configured -',
            bold : true
          },
          { 
            name : 'Our white label insurance solution uses standard industry interfaces and ready-made templates, covering sales, underwriting, claims, billing, collection processes and policy lifecycle management.',
            bold : false
          },
        ],
      },
      {
        c1 : [
          { 
            name : 'Flexible software -',
            bold : true
          },
          { 
            name : 'The insurance platform requires minimal coding while providing a high level of flexibility and customisation. All components are available as modules and you only pay for the ones you select.',
            bold : false
          },
        ],
      },
      {
        c1 : [
          { 
            name : 'Tailored pricing -',
            bold : true
          },
          { 
            name : 'We offer a range of pricing models, including rental, flat rate, usage based, tiered, user count and component based, enabling you to match your payments to your revenue streams and manage costs efficiently.',
            bold : false
          },
        ],
      },
      {
        c1 : [
          { 
            name : 'SaaS-ified -',
            bold : true
          },
          { 
            name : 'Insurance in a Box uses a SaaS (Software as a Solution) implementation and deployment model, leveraging the power of cloud-based delivery to be quick off the blocks.',
            bold : false
          },
        ],
      },
      {
        c1 : [
          { 
            name : 'Innovative accelerators -',
            bold : true
          },
          { 
            name : 'The latest technology accelerators and plug-ins enable you to benefit from innovation in the fields of analytics, machine learning, big data, blockchain and IoT (internet of things), helping you enhance your revenue streams.',
            bold : false
          },
        ],
      },
      {
        c1 : [
          { 
            name : 'Compliance ready -',
            bold : true
          },
          { 
            name : 'Manage regulatory changes in an agile way. Compliance gaps are eliminated with our continually market-ready modular insurance solutions.',
            bold : false
          },
        ],
      }
    ],
    impactperhead: 'Proven Impact on our Insurance Customers',
    impactdata: [
      { val: '97%', text: [{ t1: 'Settlement Ratio', bold: false }] },
      { val: '40%', text: [{ t1: 'Conversion and Engagement', bold: false }] }

    ],
    journeyhead: 'Smart Modules to get you started in no time',
    journeysubhead: 'PRODX DISTRIBUTION is a SaaS Product Distribution Software a new kind of enterprise software. Brokers, Banks and other partners spend enormous amount of time, efforts in connecting insurance products pipeline and managing partners. Insurers spend millions on these managing partners, products, APIs and documents. Entire process is broken with huge list of documents, and managing multiple partners, products etc. PRODX DISTRIBUTION System provides ‘Insurance in a BOX’ to connect to multiple partners with SINGLE API',
    journeydata: [
      { name: 'Save Time', text: 'Eliminate costly time to market delay with a highly configurable solution for product design and management' },
      { name: 'Reduce Cost', text: "Efficient cost control and management is assured through a pay-as-you-use pricing model" },
      { name: 'Competitive Edge', text: 'Gain competitive advantage and capture new market share, by leveraging the latest innovations in technology and distribution' },

    ],
    impact2data: [],
    resource_list: [
      {
        cardImg: b90,
        cardTag: 'Blog',
        cardTtitle: "Reigniting Insurance Distribution: One API for All your distribution 'Insurance-in-a-Box'",
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/reigniting-insurance-distribution',
        new_page_open: true
      },
      {
        cardImg: b45,
        cardTag: 'Blog',
        cardTtitle: 'Artivatic Insurtech Guiding Insurance in to Digital Age.',
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/artivatic-insurtech-guiding-insurance-in-to-digital-age',
        new_page_open: true
      }

    ]
  },
  {
    head: "Policy Management for insurance company and brokers",
    logoimg: '',
    imgintro: solEFmain,
    tagline: 'Integrate All Policies and Procedure Documents on a Centralized Insurance Policy Administration System for Enterprise and Fintech',
    productsolhead: 'Policy Management',
    productsolsubhead: "PRODX policy management module offers one holistic technological platform that handles the entire insurance policy administration life cycle for all lines of business. It allows insurance companies to have one seamless, centralized location for policy updates, policy approval workflow, reviews, and communication. Our insurance policy management system completely integrates the business processes into policy management workflows for optimum results.",
    p1img: solEF2,
    box1head1: 'Key Benefits :',

    p1pointers: [
      {
        c1 : [
          { 
            name : 'Platform connects to core insurance platforms (Polict Management System and Underwriting System) to easily allow to provide info, APIs,and underwriting policy.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Allow partners to self-manage products,APIs,claims,and monitoring of revenue, premium, and policies in all real-time.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Allows having a digital agreement process to reduce the time for compilance.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Allows designing new-age products too based on customer needs that can be approved by the insurance company.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Design new products based on customer need, get approval, integrate and sell.',
            bold : false
          }
        ],
      },
      
    ],
    impactperhead: 'Proven Impact on our insurance & financial services providers',
    impactdata: [
      { val: '100X', text: [{ t1: 'Reduce decision time', bold: false }] },
      { val: '50%', text: [{ t1: 'Reduce cost', bold: false }] }

    ],
    journeyhead: 'Powering Insurance Policy Administration System with Legacy Modernization Services',

    journeydata: [
      { name: 'Connect to core system via APIs', text: 'Multiple products, Simple Underwriting, Multiple Rules' },
      { name: 'Self service integration & Monitoring Platform', text: "Self-service product APIs Management" },
      { name: 'Use as based on need, anywhere', text: 'Reduction in infra cost, time and efforts' },

    ],
    impact2data: [],
    resource_list: [
      {
        cardImg: b45,
        cardTag: 'Blog',
        cardTtitle: "Artivatic Insurtech Guiding Insurance in to Digital Age",
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/artivatic-insurtech-guiding-insurance-in-to-digital-age',
        new_page_open: true
      },
      {
        cardImg: b90,
        cardTag: 'Blog',
        cardTtitle: "Reigniting Insurance Distribution: One API for All your distribution 'Insurance-in-a-Box'",
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/reigniting-insurance-distribution',
        new_page_open: true
      }

    ]
  },
  {
    head: "Reinsurance solution for our Digital Age",
    logoimg: '',
    imgintro: solutionREIFrontImg,
    tagline: 'Empowering enhanced decision making through real-time Analytics information and AI driven Technology platforms',
    productsolhead: 'How Artivatic’s full stack AI driven products help Reinsurers in a better way',
    productsolsubhead: "Artivatic’s full stack AI driven products help Reinsurers in providing insights on Analytics and overall risk score/assessment around claims/TPA/Providers/Payers and End customers and its related data, thereby helping understand the factual as well as the future foreseen risks to help in better informed and concrete decisions with respect to Reinsurance",
    p1img: solutionREI2Img,
    box1head1: 'Key Benefits :',

    p1pointers: [
      {
        c1 : [
          { 
            name : 'Data Digitization and automated AI based claims processing and Decision insights',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'AI based Analytics and insights on Health Claims and summary',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Provider Empanelment and Tariff negotiation and Tariff Digitization',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Provider Analytics',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Platform allows to buy personalized insurance policy products, based on risk for employees, liability, property and more.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'File claims, tracking and manage claims. Manage activities, alerts, renewals, and payment.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Full stack API Based self-managed business insurance platform that allows create account, buy policies digitally, connect to 3rd parties, build risk scores and more.',
            bold : false
          }
        ],
      },
      {
        c1 : [
          { 
            name : 'Digitizing and auto classification of medical documents, billing, discharge summary and more through its smart ICR/OCR technology.',
            bold : false
          }
        ],
      }
    ],
    impactperhead: 'Proven impact on our Insurers and Customers',
    impactdata: [
      { val: '100X', text: [{ t1: 'Reduce decision time', bold: true }] },
      { val: '50%', text: [{ t1: 'Reduce cost', bold: true }] }

    ],
    journeyhead: 'A Powerful Reinsurance Solution for Future',

    journeydata: [
      { name: 'Data Analytics', text: 'Visually display KPI’s and provide full drill-down capability, ensuring fingertip control across all operations' },
      { name: 'Digital Reinsurance', text: "Benefit from improved operational efficiency through a solution which supports digital messaging/Calling " },
      { name: 'End to end lifecycle', text: 'End-to-end capabilities across treaty and facultative business support. Full policy life cycle of quote, negotiation, renew and claim management' },

    ],
    impact2data: [],
    resource_list: [
      {
        cardImg: b58,
        cardTag: 'Blog',
        cardTtitle: "ALFRED: Transforming Health Claims using AI",
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/alfred-transforming-health-claims',
        new_page_open: true
      },
      {
        cardImg: b41,
        cardTag: 'Blog',
        cardTtitle: "ALFRED - Unified AI Health Claims Automation, Risk, Fraud & Decision Platform",
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/alfred-unified-ai-health-claims-automation-risk-fraud-decision-platform',
        new_page_open: true
      },
      {
        cardImg: b71,
        cardTag: 'Blog',
        cardTtitle: "Simplifying Group Health & Commercial Insurance for Businesses, SMEs & Start-ups",
        cardBtnText: 'Read More',
        link: 'https://blog.artivatic.ai/p/simplifying-group-health-and-commercial',
        new_page_open: true
      }


    ]
  },
];
import React from 'react';
import './Details.css';
import detailsImg from '../../assets/png/details.png';
import Input from '../../components/Input/Input';
import Comment from '../../components/Comment/Comment';
import NewsLetter from '../../components/NewsLetter/NewsLetter';
import News from '../News and Media/News/News';
import Carousel from '../../components/Carousel/carousel';
import Card from '../../components/Card/Card';

const Details = () => {
  const leads = [
    'In - built webinar meetings and onboarding',
    'Lead profiling & scoring',
    'Managing partners FLS Sales team',
    'Location based intelligent for partners sales team FLS',
    'Manage customers, understand their behaviours',
    'Real - time communication, video and calling',
    'Recording, sharing and collaboration',
    'Automated Onboarding Process',
    'Training and Learning Systems',
    'Quote Management',
    'Sales Insights, performance and dashboard',
    'Individual sales team performance',
    'Product wise insights',
    'Recommendation and Activities',
    'Customer, Location wise insights',
    'Location Navigation and more'
  ];

  const distributionPlatforms = [
    'Improving customer experience',
    'Enhancing sales process',
    'Better insights and performance',
    'Reducing cost',
    'Increase revenue',
    'Automated Operations',
    'Lets build together smart sales engine to delight customers and increase revenue.'
  ];

  const comments = [
    {
      username: "Rohith R",
      date: "July 24",
      comment: "So can i get this product by contacting you through email. Also this blog is useful and has lot of information. So can i get this product by contacting you through email. "
    },
    {
      username: "Anonymous",
      date: "July 24",
      comment: "So can i get this product by contacting you through email. Also this blog is useful and has lot of information. So can i get this product by contacting you through email. "
    },
  ]
  return (
    <>
      <section className='details'>
        <div className="wrapper">

          <img className="details-img" src={detailsImg} alt='' />
          <div className='details-info'>
            <h1 className="heading-primary">
              MiO Sales - Enabling Next-Gen AI Sales Engine for Insurance & Distributors
            </h1>
            <p>
              Buying insurance seems like a painful journey and it becomes more painful when insurance businesses like carriers, brokers, distributors all find it difficult to find new customers, engage with them and finally enable with with right product need.
              <br /><br />
              It has been known fact that about 2-4% are general conversion seen in Insurance sector. Sales teams find it difficult to reach out to customers and work with them closely to support their needs. Using multiple systems to manage entire sales journey is complex.
              <br /><br />
              In this digital world, most of important part is technology but technology only can not solve the problems, it needs technology as enabler to solve the needs. Artivatic has been working for almost 3 years in insurance sector providing need based smart solutions such as smart AI underwriting, Alternative Underwriting, Automated claims, Risk & Decision intelligence, document processing and many more.
              Learning with these products and solutions while working with many large insurance businesses, Artivatic is now launching MiO Sales Platform [Web & Mobile] to enable smarter sales process and intelligence that allows to connect, engage with customers directly.
              <br /><br />
              MiO Sales is built with empathy, need and with use of data driven insights. MiO Provides next-gen insurance sales platform with:
            </p>

            <ul>
              <p>
                Prospecting and Capturing Leads:
                <br />
              </p>
              {
                leads.map(lead => {
                  return <li className="list-item">
                    <figure className='bullet' />
                    {lead}
                  </li>
                })
              }
            </ul>
            <ul>
              <p>
                MiO is next-gen insurance sales and distribution platform to allow business for:
                <br />
              </p>
              {
                distributionPlatforms.map(dp => {
                  return <li className="list-item">
                    <figure className='bullet' />
                    {dp}
                  </li>
                })
              }
            </ul>

            <hr />
            <p>Website: <a className="green underline" href="/">Visit website</a></p>
            <p>Interested to know more, write to layak@artivatic.ai or call at +91 9557162244</p>
            <hr />
            <p>Also checkout our <a className="green" href="/">MIO AGENT APP: Download from Playstore.</a></p>
            <Input placeholder={"Write a comment"} type="text" />
            <div className="comments">
              {
                comments.map(comment => {
                  return (
                    <Comment username={comment.username}
                      date={comment.date}
                      comment={comment.comment} />
                  )
                })
              }
            </div>
            <h3 className="heading-tertiary text-center">Load more comments</h3>
          </div>
          <hr />
          <h1 className="heading-primary">Related Topics</h1>
          <Carousel
            id="resource-section__carousel"
            containerClassName="resource-section__carousel__container"
            prevControlClassName="resource-section__carousel__prev-control"
            nextControlClassName="resource-section__carousel__next-control"
            fade="primary-vl"
            navColor="#22b966"
            dotNav={false}
          >
            <Card
              tag="Case study"
              title="Role of Telehealth During COVID-19"
              ancLink="/"
              btnText="View all case studies"
            />
            <Card
              tag="Case study"
              title="Role of Telehealth During COVID-19"
              ancLink="/"
              btnText="View all case studies"
            />
            <Card
              tag="Case study"
              title="Role of Telehealth During COVID-19"
              ancLink="/"
              btnText="View all case studies"
            />
            <Card
              tag="Case study"
              title="Role of Telehealth During COVID-19"
              ancLink="/"
              btnText="View all case studies"
            />
            <Card
              tag="Case study"
              title="Role of Telehealth During COVID-19"
              ancLink="/"
              btnText="View all case studies"
            />
          </Carousel>
        </div>
      </section>
      <NewsLetter
        title="Lets build next-gen insurance & health products"
        btn1text="Schedule a Demo"
        btn1Route="/contact-us"
        btn2text="Request Pricing"
        subscribeBtn="true"
      />
    </>
  );
}

export default Details;

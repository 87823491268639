import React, { useState } from "react";
import { Link,useHistory } from 'react-router-dom';
import NavDesk from "../NavDesk/NavDesk.js";
import "./Navbar.css";


export default function Navbar() {
  const [navExpand, setNavExpand] = useState(false);
  const history = useHistory();
  
  const navigateLogin = ()=> {
    history.push('/user-login');
  }
  const navigateContact = ()=> {
    history.push('/contact-us');
  }
  

  const handleClick = () => {
    setNavExpand(!navExpand)
  }

  return (
    <nav className={`nav ${navExpand ? 'expand' : ''}`}>
      <div className={`wrapper ${navExpand ? 'wrap-expand' : ''}`}>
        {/* Nav Logo */}
        <a href="/" className={`nav__logo nav__logo--light ${navExpand ? 'nav_hidden' : ''}`}>
          {null}
        </a>
        {/* Nav Hamburger */}
        <button className={`nav__hamburger ${navExpand ? 'toggle' : ''}`} onClick={() => handleClick()}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className={`desk-nav-container ${navExpand ? 'deskNavContainer' : ''}`}>
          {/* Nav Menu Desktop*/}
          <NavDesk setNavExpand={setNavExpand} />
          <div className="nav_mob_btns">
            <button className="nav__cta btn btn--bg btn--light nav_mob_btn" onClick={navigateContact}>
              <Link to="/contact-us" style={{color:'#22b966'}}>Get Demo</Link>
            </button>
            <button className="nav__login btn btn--bg btn--light nav_mob_btn" onClick={navigateLogin}>
              User Login
            </button>
          </div>
          <div className="nav__auth">
            <button className="nav__cta btn btn--bg btn--light fix1" onClick={navigateContact}>
            <Link to="/contact-us" style={{color:'#22b966'}}>Get Demo</Link>
            </button>
            <button className="nav__login btn btn--bg btn--light fix2" onClick={navigateLogin}>
              User Login
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

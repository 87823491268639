import React from "react";
import Card from "../Card/Card.js";
import Carousel from "../Carousel/carousel.js";

import Carousel1 from "../Carousel1/Carousel1.js";

import "./Section9.css";
export default function Section9(props) {
  const { heading, courosalValues, properties, id } = props
  // console.log(courosalValues);
  return (
    <section className={"section-9 " + (properties && properties.sectionClass)}>
      <div className="wrapper section-9-wrapper">
        <h1 className={"section-9__heading " + (properties && properties.headingClass)}>{heading}</h1>

        <hr className="section-9__underline"></hr>
        {/* <Carousel1 id="section-9__carousel"
          values={courosalValues}
          dotNav={true}
        ></Carousel1> */}
        <Carousel
          id={id}
          fade="gradient-nav-btn-green"
          navColor="#FFFFFF"
          className='card-carousel-container'
          containerClassName='card-carousel'
          prevControlClassName='card-carousel-btns card-nav-prev-btn'
          nextControlClassName='card-carousel-btns card-nav-next-btn'
          dotNav={true}
        >
          {courosalValues.map((value, index) => {
            return <Card
              key={index}
              type={value.type}
              title={value.title}
              text={value.text}
              btnText={value.btnText}
              cardImg={value.cardImg}
            />
          })}
        </Carousel>
      </div>

    </section>
  );
}

import React, { useState } from "react";

import Card from "../Card/Card.js";
import "./Carousel1.css";

export default function
  Carousel1(props) {
  // props
  const { id, containerClassName, values, properties, dotNav } = props;
  // states
  const [controlState, setControlState] = useState(0);
  // const { } = props;
  const maxCount = values.length;
  function handleScroll(e) {
    const type = e.currentTarget.getAttribute("data-type");
    const container = document.querySelector(`#${id}`);
    const element = container.firstChild.offsetWidth;

    const currentScroll = container.scrollLeft;
    let scroll;
    if (type === "prev") {
      scroll = currentScroll - element;
    }

    if (type === "next") {
      scroll = currentScroll + element;
    }

    if (type === "index") {
      scroll = currentScroll * element;
    }

    if (scroll >= 0 && scroll <= maxCount - 1) {
      setControlState(scroll);
    }

    container.scroll(scroll, 0);

    if (scroll <= 0) {
      setControlState(0);
    } else if (container.scrollWidth - scroll <= container.offsetWidth) {
      setControlState(2);
    } else {
      setControlState(1);
    }

    // console.log(`
    // 'container offset', ${container.offsetWidth},
    // scroll: ${scroll}
    // 'scroll width', ${container.scrollWidth - scroll},
    // element width: ${element}
    // `);
  }

  // console.log(containerClassName);
  return (
    // < div className="carousel-1" >
    < div className={`carousel-1 ${properties ? (properties.carouselClass ? properties.carouselClass : "") : ""
      } `} >
      <div
        id={id}
        className={`carousel-1__container ${containerClassName ? containerClassName : ""
          }`}
      >
        {values.map((value, index) => {
          return <Card
            key={index}
            type={value.type}
            title={value.title}
            text={value.text}
            btnText={value.btnText}
            cardImg={value.cardImg}
            telephn={value.telephn}
            mail={value.mail ? value.mail : ''}
            tollfree={value.tollfree ? value.tollfree : ''}
            fax={value.fax ? value.fax : ''}
          />
        })}

      </div>

      <div className="carousel-1__controls">
        {controlState !== 0 && (
          <div
            className={`carousel-1__control-prev ${properties ? (properties.navbutton ? properties.navbutton : "") : ""
              }`}
            data-type="prev"
            data-container={id}
            onClick={handleScroll}
          ></div>
        )}
        {controlState !== 2 && (
          <div
            className={`carousel-1__control-next ${properties ? (properties.navbutton ? properties.navbutton : "") : ""
              }`}
            data-type="next"
            data-container={id}
            onClick={handleScroll}
          ></div>
        )}
      </div>

      {dotNav && (
        <div className='carousel-1_dots'
          onClick={(e) => {
            if (!e.target.closest("button")) return;
            handleScroll(e);
          }}
          data-type="index"
        >
          {[...Array(maxCount)].map((n, i) => {
            return (
              <button
                key={i}
                className={`carousel__nav-btn ${i === 0 ? "select" : ""}`}
                data-index={i}
              ></button>
            );
          })}
        </div>
      )}
    </div >
  );
}

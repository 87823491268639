import React from "react";
import { Link } from 'react-router-dom';

import img from "../../assets/Logos_Pictures/img_1.webp";
import smallImg from '../../assets/png/intro-vid.png'
import "./section1.css";

export default function Section1() {
  return (
    <section className="section-1">
      <div className="wrapper">
        {/* heading */}
        <div className="grid-1">
          {/* <img className="bg" src={bg} alt="into-section-bg" /> */}
          <h1 className="section-1__heading">
            Positively <br />
            <span style={{ color: "black" }}>Enabling </span>
            Affordable Insurance
            <span style={{ color: "black" }}> Access</span>
          </h1>
        </div>
        {/* Image */}
        <div className="grid-2">
          <img src={img} alt="insurance-img" className="section-1__img" />
          {/* <div className="small-img"><img src={smallImg} alt="" /></div> */}
        </div>
        {/* Content */}
        <div className="section-1__content grid-3">
          <h2>Join us in revolutionizing the Insurance ecosystem</h2>
          <p>
            Artivatic’s AI- Native SaaS Solutions for insurance & health
            services as modular building blocks powered by best-in-class API
            gateway.
          </p>
          <div className="section-1-btns">
            <Link to="/contact-us" className="btn btn--dark">
              Get a demo
            </Link>
            <Link to="/contact-us" className="btn btn--toggle">
              Request Pricing
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

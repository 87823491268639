import React from "react";
import './Info.css';

const Info = (props) => {
  const { info, grid, classes } = props
  return (
    <section className={"security info " + (classes ? classes.sectionClass : '')}>
      <div className={"info-primary " + (classes ? classes.divClass : '')}>
        <h2 className={"heading-secondary " + (classes ? classes.headingSecondaryClass : '')}> {info.headingSecondary} </h2>
        <p className={"text-primary " + (classes ? classes.textPrimaryClass : '')}>{info.textPrimary}</p>
        {info.textSecond && (
          <p className={"text-primary " + (classes ? classes.textSecondClass : '')}>{info.textSecond}</p>
        )}
        {info.textThird && (
          <p className={"text-primary " + (classes ? classes.textThirdClass : '')}>{info.textThird}</p>
        )}
        {info.textFourth && (
          <p className={"text-primary " + (classes ? classes.textFourthClass : '')}>{info.textFourth}</p>
        )}
        {info.textFifth && (
          <p className={"text-primary " + (classes ? classes.textFifthClass : '')}>{info.textFifth}</p>
        )}
        {info.textSixth && (
          <p className={"text-primary " + (classes ? classes.textSixthClass : '')}>{info.textSixth}</p>
        )}
        {info.textSeventh && (
          <p className={"text-primary " + (classes ? classes.textSeventhClass : '')}>{info.textSeventh}</p>
        )}
        {info.textEigth && (
          <p className={"text-primary " + (classes ? classes.textEigthClass : '')}>{info.textEigth}</p>
        )}
      </div>
      {grid && (
        <div className="info-grid">
          {
            grid.map((info, index) => (
              <div className="info-secondary" key={index}>
                <div className="info-img">
                </div>
                <h3 className="heading-tertiary">{info.headingTertiary}</h3>
                <p className="text-secondary">{info.textSecondary}</p>
              </div>
            ))}
        </div>
      )}
    </section>
  )
}

export default Info;
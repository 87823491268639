import React, { useState } from "react";
import { Link } from 'react-router-dom';
import "./NewsLetter.css";
export default function NewsLetterSection(props) {
  const { title, btn1text, btn2text, btn1Route, btn2Route, subscribeBtn, productDetail,mtop } = props;
  const [email, setEmail] = useState('')
  const [disable, setDisable] = useState(true)

  const validateForm = () => {
    (email !== '') === true ? setDisable(true) : setDisable(false)
  }


  // console.log(email);
  return (
    <section className={`news-letter ${productDetail ? 'product-tour' : ''} ${mtop ? 'marginTop' : ''}`}>
      <div className="wrapper">
        <h1 className="heading">{title}
        </h1>
        <hr className="hr-underline" />
        <div className="flex flex-jc-c">
          {btn1text && (
            <Link to={btn1Route} className="btn btn--dark">
              {btn1text}
            </Link>)}
          {btn2text && (
            <Link to={btn2Route} className="btn btn--toggle">
              {btn2text}
            </Link>)}
        </div>
        {subscribeBtn && (
          <><hr className="hr" /><div className="container">
            <h2>
              Subcribe to our newsletter
            </h2>
            <form onChange={validateForm}>
              <input type="email" name="email" placeholder="E-mail" value={email} onChange={(e) => { setEmail(e.target.value) }} />
              <button className="icon" disabled={disable}></button>
            </form>
          </div></>
        )}
      </div>
    </section>
  );
}

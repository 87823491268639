import React from 'react';
import './Comment.css';

const Comment = (props) => {
    return (
        <div className="comment">
            <div className="comment--user__img"></div>
            <div className="comment--user__info">
                <h3 className="comment--user__name">{props.username}</h3>
                <p className="comment--date">{props.date}</p>
                <p className="comment--text">
                    {props.comment}
                </p>
            </div>
        </div>
    );
}

export default Comment;

import React from "react";
import IntroductionSection from "../../components/IntroductionSection/IntroductionSection";
import img from "../../assets/png/Rectangle 184.png";
import NewsLetter from "../../components/NewsLetter/NewsLetter"
import Section10 from "../../components/Section10/Section10";
import Section17 from "../../components/section17/section17";
import Testimonial from "../../components/Testimonial/Testimonial";
import Section12 from "../../components/Section12/Section12";
import Section13 from "../../components/section13/section13";
import storyimg from "../../assets/png/Rectangle 199.png";
import "./growth.css";
// import { useParams } from 'react-router-dom'

import sbilogonew from '../../assets/Logos_Pictures/Clients/image 16.png'
import aegonlife from '../../assets/Logos_Pictures/Clients/image 20.png'
import client1 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - AEGON Life.webp'
import client2 from '../../assets/Logos_Pictures/Clients/image 18.png'
import client3 from '../../assets/Logos_Pictures/Clients/image 21.png'
import client4 from '../../assets/Logos_Pictures/Clients/image 17.png'
import client5 from '../../assets/Logos_Pictures/Clients/image 19.png'
import client6 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - IFFCO TOKIO.webp'
import client7 from '../../assets/Logos_Pictures/Clients/Customer Logo - IDFC FIRST BANK.webp'
import client8 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - RenewBuy.webp'
import client9 from '../../assets/Logos_Pictures/Clients/image 23.png'
import client10 from '../../assets/Logos_Pictures/Clients/image 24.png'
import Section11 from "../../components/Section11/Section11";
import ContactForm from "../../components/ContactForm/ContactForm";
import contactImg from '../../assets/png/growth-form.png'

export default function Growth() {
  // const { id } = useParams();


  const clientList = [
    {
      file: sbilogonew,
      flag: 'sbi'
    },
    {
      file: aegonlife,
      flag: 'rect'
    },
    {
      file: client2,
      flag: 'square'
    },
    {
      file: client3,
      flag: 'rect'
    },
    {
      file: client4,
      flag: 'rect'
    },
    {
      file: client5,
      flag: 'rect'
    },
    {
      file: client8,
      flag: 'rect'
    },
    {
      file: client9,
      flag: 'square'
    },
    {
      file: client10,
      flag: 'rect'
    },

    // sbilogonew, aegonlife, client2, client3, client4, client5, client8, client9, client10
  ]


  return (
    <div id="growth">
      <IntroductionSection
        title={{ type: "spanend", Text: "If You Think GROWTH Starts With Your Customers, ", span: "Join The Club" }}
        description={{
          Text1: "AUSIS enables insurance businesses to provide in-depth underwriting, scoring & decisions in real-time. AUSIS provides reduction in cost, time, risk & fraud with enhancing efficiency, decision power, alternative scoring and more.",
          Text2: 'AUSIS helps increasing STP from NSTP and also enables non-invasive methods of health data aggregation from AQI, Location, Mortality, Social, Photo, Video, Health Devices, Weather, Sanitation and more. AUSIS reduces up to 40% reduction in per policy issuance.',
        }}
        image={img}
        classes={{
          titleClass: 'w-22ch'
        }}
      >
      </IntroductionSection>
      {/* Our Story Section Start  */}
      <section className="ourstory">
        <div className="wrapper">
          <h1>What is GROWTH?</h1>
          <div className="line"></div>
          <div className='our-Story-Image'>
            <img src={storyimg} alt=''></img>
          </div>
          <p>#GROWTH is an invite-only & exclusively community aimed at fostering growth, sharing new insights for product owners, entrepreneurs, growth marketers, and user engagement professionals from consumer-first brands</p>
          <p>Join us to uncover the growth strategies powering some of the leading brands worldwide. The focus of the community will be on sharing latest insights related to driving user engagement, on-boarding, retention, loyalty and LTV.</p>
        </div>
      </section>
      {/* Our Story Section End */}
      {/* <Section10 /> */}
      <Section17
        title={{
          Text: "", span: "GROWTH", rest: ' is a community where you can'
        }}
        description="Artivatic makes all your tools work better by letting you integrate industry-leading software and custom apps."
        list={
          [{
            Title: "Learn",
            Description: "Our insurance, brokers, distribution partners find navigating the supply & demand side of the industry chaotic.Our insurance, brokers, distribution partners."
          },
          {
            Title: "Discover",
            Description: "Our insurance, brokers, distribution partners find navigating the supply & demand side of the industry chaotic.Our insurance, brokers, distribution partners."
          },
          {
            Title: "Network",
            Description: "Our insurance, brokers, distribution partners find navigating the supply & demand side of the industry chaotic.Our insurance, brokers, distribution partners."
          },
          {
            Title: "Benefits",
            Description: "Our insurance, brokers, distribution partners find navigating the supply & demand side of the industry chaotic.Our insurance, brokers, distribution partners."
          },
          ]} >

      </Section17>
      <Section11
        title={{
          type: "spanmiddle", Text: "GROWTH brings together  ", span: "consumer brands ", Text2: 'from around the globe'
        }}
        description={{
          Text: 'Righting the wrongs of insurance technology for global insurance organizations.Leading organizations in the world utilize Artivatic’s suite of solutions Artivatic believes in collaborative growth with all of its stakeholders.'
        }}

        link={{
          Text: 'Read More',
          LinkRoute: '/partners',
        }}
        clientList={clientList}
      />
      {/* <div className="section13">
        <Section13
          image={img}
          title={
            {
              type: "spanmiddle",
              Text: "Title for the ",
              span: " Key ",
              Text2: "Features of the Health API"
            }
          }
          subtitle="Artivatic’s AI- Native SaaS Solutions for insurance & health services as modular building blocks powered by best-in-class API gateway. New age product offerings, dynamic pricing, automation, smart underwriting, smart claims processing, enhanced customer experience, persistence & collections.Artivatic’s AI- Native SaaS Solutions for insurance "
          list={[
            { Title: "Title for this feature", Description: "Artivatic’s AI- Native SaaS Solutions for insurance & health services as modular building blocks powered by best-in-class API gateway. New age product offerings, dynamic pricing, automation, smart underwriting, smart " },
            { Title: "Title for this feature", Description: "Artivatic’s AI- Native SaaS Solutions for insurance & health services as modular building blocks powered by best-in-class API gateway. New age product offerings, dynamic pricing, automation, smart underwriting, smart " },
            { Title: "Title for this feature", Description: "Artivatic’s AI- Native SaaS Solutions for insurance & health services as modular building blocks powered by best-in-class API gateway. New age product offerings, dynamic pricing, automation, smart underwriting, smart " }
          ]}
          button1={
            {
              Title: 'Request a Demo',
              Url: '/contact-us'
            }
          }
          button2={
            {
              Title: 'View Case study',
              Url: '/contact-us'
            }
          }
          classes={
            {
              sectionClass: 'green-back',
              button2Class: 'btn-transparent'
            }
          }
        ></Section13>
      </div> */}
      <Testimonial
        title={{
          type: 'spanend',
          Text1: 'What members are saying about the ',
          Span1: 'GROWTH experience',
        }}
      />
      <Section12 />
      <ContactForm
        title={{
          type: 'spanend',
          Text: 'Become a part of the ',
          Span: 'GROWTH Evolution'
        }}
        contactImg={{
          src: contactImg
        }}
      />
      <NewsLetter
        title="We provide personalized insurance products based on customer goals"
        btn1text="Know more about Atrivatic"
        btn1Route="/contact-us"
      >
      </NewsLetter>

    </div >
  )
}
import React, {useState} from 'react';
import './ContactForm.css';

const ContactForm = ({title, classes, contactImg}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const formObject = {
        first_name: '', last_name: '', email: '', mobile: '', description: ''
    }
    const [formData, setFormData] = useState(formObject);

    const [errors, setErrors] = useState(formObject);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
        setErrors({...errors, [name]: ''}); // Clear error on change
    };

    const validate = () => {
        let tempErrors = {};
        if (!formData.first_name) tempErrors.first_name = 'First Name is required';
        if (!formData.last_name) tempErrors.last_name = 'Last Name is required';
       // if (!formData.topic) tempErrors.topic = 'Topic is required';
        if (!formData.description) tempErrors.description = 'Description is required';
        if (!formData.email) {
            tempErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = 'Email is invalid';
        }
        if (!formData.mobile) {
            tempErrors.mobile = 'Mobile number is required';
        } else if (!/^\d{10}$/.test(formData.mobile)) {
            tempErrors.mobile = 'Mobile number must be 10 digits';
        }
        if (!formData.description) {
            tempErrors.description = 'Description is required';
        } else if (formData.description.length < 10) {
            tempErrors.description = 'Description must be at least 10 characters';
        }
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0; // Return true if no errors
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setIsSubmitting(true);
            try {
                // Example API endpoint
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/demo_contact_details/`, {
                    method: 'POST', headers: {
                        'Content-Type': 'application/json',
                    }, body: JSON.stringify(formData),
                });

                const data = await response.json();
                setResponseMessage('Form submitted successfully!');
                console.log('Response from server:', data);
            } catch (error) {
                setResponseMessage(`Error: ${error.message}`);
            } finally {
                setIsSubmitting(false);
                // Reset form
                setFormData(formObject);
            }
        }
    };
    return (<section className="demo">
        <form onSubmit={handleSubmit} className={'form'}>
            <div className="banner">
                <img src={contactImg.src} className={"contact-detail-img " + (classes && classes.imageClass)} alt=''/>
                <div className="contactdetails">
                    <div className="row">
                        {title && title.type === 'spanend' && (<div className="col-md-12">
                            <label className={"heading " + (classes && classes.titleClass)}>
                                {title.Text}
                                <span style={{color: "#22bb66"}}>{title.Span}</span></label>
                        </div>)}
                        <div className="col-md-12">
                            <div className="alert alert-info">
                                {responseMessage && <p>{responseMessage}</p>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <input type="text" placeholder="First Name" className="inputField" name="first_name"
                                   value={formData.first_name}
                                   onChange={handleChange}/>
                            {errors.first_name && (<span className="err">{errors.first_name}</span>)}
                        </div>
                        <div className="col-sm-6">
                            <input type="text" placeholder="Last Name" className="inputField"
                                   name="last_name"
                                   value={formData.last_name}
                                   onChange={handleChange}/>
                            {errors.last_name && (<span className="err">{errors.last_name}</span>)}
                        </div>
                        <div className="col-sm-6">
                            <input type="email" placeholder="Email" className="inputField" name="email"
                                   value={formData.email}
                                   onChange={handleChange}/>
                            {errors.email && (<span className="err">{errors.email}</span>)}
                        </div>
                        <div className="col-sm-6">
                            <input type="number" placeholder="Phone Number" className="inputField"
                                   name="mobile"
                                   value={formData.mobile}
                                   onChange={handleChange}/>
                            {errors.mobile && (<span className="err">{errors.mobile}</span>)}
                        </div>
                        {/*<div className="col-md-12">*/}
                        {/*    <input type="text" placeholder="Topic" className="inputField" name="topic"*/}
                        {/*           value={formData.topic}*/}
                        {/*           onChange={handleChange}/>*/}
                        {/*    {errors.topic && (<span className="err">{errors.topic}</span>)}*/}
                        {/*</div>*/}
                        <div className="col-md-12">
                            <textarea rows="10" className="inputField" placeholder="Description"
                                      id="description"
                                      name="description"
                                      value={formData.description}
                                      onChange={handleChange}
                            />
                            {errors.description && (<span className="err">{errors.description}</span>)}
                        </div>
                        <div className="col-md-12">
                            <button type="submit" className="greenbutton" disabled={isSubmitting}>
                                {isSubmitting ? 'Loading...' : 'Submit'}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </section>)
}

export default ContactForm

//Dependencies
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from "react-router-dom";

import { solution_list } from './Solution-lists';

//CSS
import styles from './solutions.module.css';
import greenTick from '../../assets/vector/green-tick.svg';
import Aos from 'aos';
import "aos/dist/aos.css";

//Components
import Section12 from '../../components/Section12/Section12'
import NewsLetterSection from '../../components/NewsLetter/NewsLetter'



const Solutions = () => {
  const history = useHistory();
  const { type } = useParams();
  let id;
  switch (type) {
    case 'health-insurance':
      id = 0;
      break;
    case 'sme-insurance':
      id = 1;
      break;
    case 'sales-marketing':
      id = 2;
      break;
    case 'distributors-brokers':
      id = 3;
      break;
    case 'third-party-administrator':
      id = 4;
      break;
    case 'hospitals-clinics':
      id = 5;
      break;
    case 'micro-insurance':
      id = 6;
      break;
    case 'group-health':
      id = 7;
      break;
    case 'oem-dealership-management-crm-system':
      id = 8;
      break;
    case 'garage-management':
      id = 9;
      break;
    case 'insurance-in-a-box':
      id = 10;
      break;
    case 'enterprise-fintech':
      id = 11;
      break;
    case 'reinsurance':
      id = 12;
      break;
  }
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  const navigate = () => {
    history.push('/contact-us');
  }

  return (
    <>
      <Helmet>
        <title>Solutions | {solution_list[id].head}</title>
        <meta name="description" content={`${solution_list[id].tagline}`} />
        <meta name="keywords" content={`${solution_list[id].head} | Artivatic`} />
        <link rel="canonical" href={`/solutions/${type}`} />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={`https://artivatic.ai/solutions/${type}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Artivatic | AI Insurance Solutions | ${solution_list[id].head}`} />
        <meta property="og:description" content={`${solution_list[id].tagline}`} />
        <meta property="og:image" content={`${solution_list[id].logoimg}`} />
        <meta property="og:image:width" content='1200' />
        <meta property="og:image:height" content='630' />

        {/* Twitter Meta Tags  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="artivatic.ai" />
        <meta property="twitter:url" content={`https://artivatic.ai/solutions/${type}`} />
        <meta name="twitter:title" content={`Artivatic | AI Insurance Solutions | ${solution_list[id].head}`} />
        <meta name="twitter:description" content={`${solution_list[id].tagline}`} />
        <meta name="twitter:image" content={`${solution_list[id].logoimg}`} />
        <meta property="og:image:width" content='1200' />
        <meta property="og:image:height" content='630' />

        {/* Service schema  */}
        <script type="application/ld+json">
          {`{
              "@context": "https://schema.org/",
              "@type": "Service",
              "serviceType": "AI Insurance Solutions",
              "provider": {
                "@type": "Organisation",
                "name": "Artivatic.ai"
              },
            },
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "name": "Insurance API Solutions",
              "itemListElement": [
                {
                  "@type": "OfferCatalog",
                  "name": "${solution_list[id].head}",
                }
              ]
     
            }`
          }
        </script>
      </Helmet>
      <div id={styles.solutions}>
        <section className={styles.introbox}>
          <div className={styles.wrapper}>
            <div className={styles.left}>
              <h1 className={styles.p1}>{solution_list[id].head}</h1>
              <img src={solution_list[id].imgintro} className={styles.introimgmob} />
              <p className={styles.p2}>{solution_list[id].tagline}</p>

              <button onClick={navigate}>Request A Demo</button>
            </div>
            <div className={styles.right}>
              <img src={solution_list[id].imgintro} className={styles.introimg} />
            </div>
          </div>
        </section>

        <section className={styles.productsol}>
          <h1 className={styles.head}>{solution_list[id].productsolhead}</h1>
          {id !== 3 && (<div className={styles.line3}></div>)}
          {solution_list[id].productsolsubhead && <p data-aos="zoom-in" className={styles.subhead}>{solution_list[id].productsolsubhead}</p>}
          {solution_list[id].productsolsubhead2 && <p data-aos="zoom-in" className={styles.subhead}>{solution_list[id].productsolsubhead2}</p>}

          {solution_list[id].box1heading1 && <h1 data-aos="zoom-in" className={styles.sechead}>{solution_list[id].box1heading1}</h1>}
          {solution_list[id].box1head1 && (<div className={styles.box1}>

            <div className={styles.leftbox} data-aos="fade-right">
              {solution_list[id].box1head1tag && <p className={styles.tag} style={{ 'width': 'fit-content', 'fontSize': '1.2rem', 'marginBottom': '0.9rem' }}>{solution_list[id].box1head1tag}</p>}
              <p className={styles.boxp1}>{solution_list[id].box1head1}</p>
              <div className={styles.rightbox} data-aos="fade-left">
                <img src={solution_list[id].p1img} className={styles.pimgmob} />
              </div>
              {solution_list[id].p1pointers && (<div className={`${id === 2 ? `${styles.my_flex}` : ''}`}>
                {solution_list[id].p1pointers.map((item, index) => {
                  return (
                    <div className={`${styles.in}` + ' ' + `${id === 2 ? `${styles.my_width}` : ''}`} key={index}>
                      <img src={greenTick} alt="tick" className={styles.green_tick_used_for_listing_img} />
                      <p>
                        {item.c1.map((seg, j) => {
                          return (<span key={j} className={'mar3' + ' ' + (seg.bold ? 'fwb' : '')}>{seg.name}</span>)
                        })}
                      </p>
                    </div>
                  )
                })
                }
              </div>
              )}

            </div>
            <div className={styles.rightbox} data-aos="fade-left">
              <img src={solution_list[id].p1img} className={styles.pimg} />
            </div>
          </div>
          )}

          {solution_list[id].box1heading2 && <h1 data-aos="zoom-in" className={styles.sechead}>{solution_list[id].box1heading2}</h1>}
          {solution_list[id].box1head2 && (<div className={styles.box1}>

            <div className={styles.leftbox} data-aos="fade-right">
              <img src={solution_list[id].p2img} className={styles.pimg} />
            </div>
            <div className={styles.rightbox} data-aos="fade-left" style={{ 'marginBottom': '2rem' }}>
              {solution_list[id].box1head2tag && <p className={styles.tag} style={{ 'width': 'fit-content', 'fontSize': '1.2rem', 'marginBottom': '0.9rem' }}>{solution_list[id].box1head2tag}</p>}
              <p className={styles.boxp1}>{solution_list[id].box1head2}</p>
              <div className={styles.rightbox} data-aos="fade-left">
                <img src={solution_list[id].p2img} className={styles.pimgmob} />
              </div>

              {solution_list[id].p2pointers.map((item, index) => {
                return (
                  <div className={styles.in} key={index}>
                    <img src={greenTick} alt="tick" className={styles.green_tick_used_for_listing_img} />
                    <p>{item}</p>
                  </div>
                )
              })
              }


            </div>

          </div>
          )}

          {solution_list[id].box1heading3 && <h1 data-aos="zoom-in" className={styles.sechead}>{solution_list[id].box1heading3}</h1>}
          {solution_list[id].box1head3 && (<div className={styles.box1}>

            <div className={styles.leftbox} data-aos="fade-right">
              <p className={styles.boxp1}>{solution_list[id].box1head3}</p>
              <div className={styles.rightbox} data-aos="fade-left">
                <img src={solution_list[id].p3img} className={styles.pimgmob} />
              </div>
              {solution_list[id].p3pointers.map((item, index) => {
                return (
                  <div className={styles.in} key={index}>
                    <img src={greenTick} alt="tick" className={styles.green_tick_used_for_listing_img} />
                    <p>{item}</p>
                  </div>
                )
              })
              }

            </div>
            <div className={styles.rightbox} data-aos="fade-left">
              <img src={solution_list[id].p3img} className={styles.pimg} />
            </div>
          </div>
          )}

          {solution_list[id].special_section_head && (<div className={styles.special_section} style={{ 'paddingTop': id === 3 ? '0rem' : '' }}>
            <h1 data-aos="zoom-in" className={styles.head}>{solution_list[id].special_section_head}</h1>
            <p data-aos="zoom-in" className={styles.subhead}>{solution_list[id].special_section_subhead}</p>
            <div className={styles.box1}>
              <div className={styles.leftbox} data-aos="fade-right" style={{ 'justifyContent': 'center' }}>
                <div className={styles.rightbox} data-aos="fade-left">
                  <img src={solution_list[id].special_section_img} className={styles.pimgmob} />
                </div>
                {solution_list[id].special_section_data.map((item, index) => {
                  return (
                    <div className={styles.in} key={index}>
                      <img src={greenTick} alt="tick" className={styles.green_tick_used_for_listing_img} />
                      <p>{item}</p>
                    </div>
                  )
                })
                }

              </div>
              <div className={styles.rightbox} data-aos="fade-left">
                <img src={solution_list[id].special_section_img} className={styles.pimg2} />
              </div>
            </div>
          </div>


          )}
        </section >

        <section className={styles.impactper}>
          <h1 className={styles.ip}>{solution_list[id].impactperhead}</h1>
          <div className={styles.line4}></div>
          <div className={styles.cardbox}>
            {
              solution_list[id].impactdata.map((item, index) => {
                return (
                  <div className={styles.card} key={index}>
                    <p>{item.val}</p>
                    <span>
                      {
                        item.text.map((i, i2) => {
                          return (
                            <span key={i2} className={`${i.bold === true ? `${styles.boldme}` : ''}` + ' ' + `${i2 === 0 ? `${styles.mar6}` : ''}`}>{i.t1}</span>
                          )
                        })
                      }
                    </span>

                  </div>
                )
              })
            }

          </div>
        </section>

        {solution_list[id].journeyhead && (<section className={styles.ourjourney}>
          <h1 className={styles.head2}>{solution_list[id].journeyhead}</h1>
          {id !== 4 && (<div className={styles.line3}></div>)}
          {solution_list[id].journeysubhead && (<p data-aos="zoom-in" className={styles.subhead}>{solution_list[id].journeysubhead}</p>)}
          <h1>Always in step with our customers</h1>
          <div className={styles.row}>
            <div className={styles.leftmd}>
              {
                solution_list[id].journeydata.map((item, index) => {
                  return (
                    <div className={styles.journeyrow} key={index}>
                      <div className={styles.year} style={{ 'marginBottom': item.name === '' ? '0px' : '' }}>
                        <h6>{item.name}</h6>
                      </div>
                      <div className={styles.desc}>
                        <p>{item.text}</p>
                      </div>
                      <div className={styles.journeyimage}>
                        {/* <img src={storyimg} alt=''></img> */}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </section>
        )}

        {solution_list[id].impact2data.length > 0 && <section className={styles.impactper2}>
          <div className={styles.cardbox2}>
            {
              solution_list[id].impact2data.length > 0 && solution_list[id].impact2data.map((item, index) => {
                return (
                  <div className={styles.card2} key={index}>
                    <div className={styles.mediaitem}>
                      <img src={item.img} className={styles.plogoimg} />
                    </div>
                    <p className={styles.card2p1}>{item.headtext}</p>
                    <p className={styles.card2p2}>{item.perdata}</p>
                    <span className={styles.fdptext}>{item.text2}</span>
                    <div className={styles.fdp}>
                      {/* <img src={RectangleMob} className={styles.pboximg} /> */}
                      <div className={styles.pbox}>
                        <span>{item.name}</span>
                        <span>{item.role}</span>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </section>
        }

        <section className={styles.resources}>
          <h1 className={styles.ip2}>Resources that Help you Grow</h1>
          <div className={styles.line5}></div>
          <div className={styles.cardboxr}>
            {
              solution_list[id].resource_list.map((item, index) => (
                <div className={styles.card3} key={index}>
                  <img src={item.cardImg} className={styles.blogimg} />
                  <div className={styles.card3info}>
                    <p className={styles.tag}>{item.cardTag}</p>
                    <h3 className={styles.card3textdes}>{item.cardTtitle}</h3>
                    <a href={item.link} target={item.new_page_open === true ? '_blank' : ''} className={styles.btn}>
                      {item.cardBtnText}
                      <svg width="23" height="14" viewBox="0 0 23 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.30347 7.98935L18.5111 7.98935L14.7306 11.4998C14.2092 11.984 14.2092 12.7103 14.7306 13.1945C15.2521 13.6787 16.0342 13.6787 16.5557 13.1945L22.5523 7.6262C23.0737 7.14201 23.0737 6.41571 22.5523 5.93152L16.5557 0.36325C16.0342 -0.120947 15.2521 -0.120947 14.7306 0.36325C14.2092 0.847447 14.2092 1.57374 14.7306 2.05794L18.5111 5.56837L1.30348 5.56837C0.651671 5.56837 -0.000130951 6.05256 -0.000131014 6.77886C-0.000131078 7.50515 0.651671 7.98935 1.30347 7.98935Z"
                          fill="#22BB66" />
                      </svg>
                    </a>
                  </div>
                </div>
              ))
            }

          </div>

        </section>
        <Section12 />
        <NewsLetterSection
          title="Lets build next-gen insurance & health products"
          btn1text="Schedule a Demo"
          btn1Route="/contact-us"
          btn2Route="/contact-us"
          btn2text="Request Pricing" subscribeBtn="true"
        />
      </div >
    </>
  )
}

export default Solutions

import React from "react";
import { Link } from 'react-router-dom';
import "./IntroductionSection.css";
import blackRightArrowIcon from '../../assets/vector/black-right-icon.svg'
export default function IntroductionSection(props) {
  const { from, title, subtitle, description, button1, button2, image, classes, casestudyLbl, titleSecondary, descriptionSecondary, YTvideo, LocalVideo } = props;
  // console.log(video);
  return (
    <div id="introductionsection">
      <section className={"first-section wrapper " + (classes ? classes.sectionClass : '')}>
        <div className="primary-section">
          <div className={"left-md " + (classes ? classes.divClass : '')}>
            {title && title.type === "nospan" && (
              <h1 className={'' + (classes ? classes.titleClass : "")}
              >{title.Text}</h1>
            )}
            {title && title.type === "spanstart" && (
              <h1 className={'' + (classes ? classes.titleClass : "")}>{title.Text}<span>{title.span}</span></h1>
            )}
            {title && title.type === "spanmiddle" && (
              <h1 className={'' + (classes ? classes.titleClass : "")}>{title.Text}<span>{title.span}</span>{title.Text2}</h1>
            )}
            {title && title.type === "spanend" && (
              <h1 className={'' + (classes ? classes.titleClass : "")}>{title.Text}<span>{title.span}</span></h1>
            )}
            {title && title.type === "breakmiddle" && (
              <h1 className={'' + (classes ? classes.titleClass : "")}>{title.Text}<br /><span>{title.span}</span><br />{title.Text2}</h1>
            )}
            {subtitle && (
              <h2 className={'sub-title ' + (classes ? classes.subtitleClass : "") + ' ' + (from === 'cloudapi_page' ? 'fontc' : "")}>{subtitle}</h2>
            )}
            {image && (
              <img src={image} className={'mobile ' + (classes ? classes.imageClass : "")} alt=''></img>
            )}
            {YTvideo && (
              <iframe src={YTvideo} className={'mobile ' + (classes ? classes.videoClass : "")} autoPlay={false} frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            )}
            {LocalVideo && (
              <video src={LocalVideo} className={'mobile ' + (classes ? classes.videoClass : "")} controls />
            )}
            {description && description.Text1 && (
              <p className={'' + (classes ? classes.descriptionClass : "") + ' ' + (from === 'cloudapi_page' ? 'fontd' : "")}>{description.Text1}</p>
            )}
            {description && description.Text2 && (
              <p className={'' + (classes ? classes.descriptionClass : "")}>{description.Text2}</p>
            )}
            {description && description.Text3 && (
              <p className={'' + (classes ? classes.descriptionClass : "")}>{description.Text3}</p>
            )}
            <div className={"links " + (classes ? classes.buttonsClass : '')}>
              {button1 && (
                <Link to={button1.Url} className={"fill " + (classes ? classes.buttonClass : '')}>{button1.Text}</Link>
              )}
              {button2 && (
                <Link to={button2.Url}>{button2.Text}</Link>
              )}
              {/* <a href="#">Know more about Artivatic</a> */}
            </div>
            {casestudyLbl && (
              <p className={'casestudy ' + (classes ? classes.casestudyLblClass : "")}>{casestudyLbl}<span className="arrow-icon"><img src={blackRightArrowIcon} alt="" /></span></p>
            )}
          </div>
          <div className={"right-md desktop " + (classes ? classes.rightClass : '')}>
            {image && (
              <img src={image} className={'' + (classes ? classes.imageClass : "")} alt=''></img>
            )}
            {YTvideo && (
              <iframe src={YTvideo} className={'' + (classes ? classes.videoClass : "")} frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            )}
            {LocalVideo && (
              <video src={LocalVideo} className={'' + (classes ? classes.videoClass : "")} controls />
            )}
          </div>
        </div>
        {
          titleSecondary && (
            <div className="center-md">
              {titleSecondary && titleSecondary.type === 'spanend' && (
                <h1 className={'' + (classes ? classes.titleClass : "")}>{titleSecondary.Text}<span>{titleSecondary.span}</span></h1>
              )}
              {descriptionSecondary && descriptionSecondary.Text1 && (
                <p className={'' + (classes ? classes.descriptionClass : "")}>{descriptionSecondary.Text1}</p>
              )}
              {descriptionSecondary && descriptionSecondary.Text2 && (
                <p className={'' + (classes ? classes.descriptionClass : "")}>{descriptionSecondary.Text2}</p>
              )}
              {descriptionSecondary && descriptionSecondary.Text3 && (
                <p className={'' + (classes ? classes.descriptionClass : "")}>{descriptionSecondary.Text3}</p>
              )}
              {descriptionSecondary && descriptionSecondary.Text4 && (
                <p className={'' + (classes ? classes.descriptionClass : "")}>{descriptionSecondary.Text4}</p>
              )}
              {descriptionSecondary && descriptionSecondary.Text5 && (
                <p className={'' + (classes ? classes.descriptionClass : "")}>
                  {descriptionSecondary.Text5.map((item, index) => (
                    <span key={index} className={'mar3' + ' ' + (item.bold ? 'highme' : '')}>
                      {item.link ? (
                        <a href="https://www.renewbuy.com/" target="_blank" style={{ 'textDecoration': 'underline' }}>{item.content}</a>
                      ) : (<span>{item.content}</span>)
                      }

                    </span>
                  ))}
                </p>

              )}
            </div>
          )
        }
      </section >
    </div >
  );
}
import React from "react";
import { Helmet } from 'react-helmet-async';
// Images
import search_img from "../../assets/vector/search.svg";
import send_img from "../../assets/vector/send.svg";
import JobRow from "../../components/JobRow/JobRow";
import NewsLetterSection from "../../components/NewsLetter/NewsLetter"
import "./career.css";

export default function Career() {
  return (
    <>
      <Helmet>
        <title>Artivatic | Careers</title>
        <meta name="description" content="Apply to Job Openings to join Our Excellent Team." />
        <meta name="keywords" content="Careers,Jobs,AI ML Jobs,Front-end Developers Jobs,Backend Python Developer Jobs,python Jobs,Devops Jobs in India,Artivatic"/>
        <link rel="canonical" href={`/career`} />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={`https://artivatic.ai/career`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Artivatic | Careers`}/>
        <meta property="og:description" content={`Apply to Job Openings to join Our Excellent Team.`} />
        <meta property="og:image" content={`https://artivatic.ai/favicon.jpeg`} />
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>

        {/* Twitter Meta Tags  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="artivatic.ai" />
        <meta property="twitter:url" content={`https://artivatic.ai/career`} />
        <meta name="twitter:title" content={`Artivatic | Careers`} />
        <meta name="twitter:description" content={`Apply to Job Openings to join Our Excellent Team.`} />
        <meta name="twitter:image" content={`https://artivatic.ai/favicon.jpeg`} />
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>

      </Helmet>
      <div id="career">
        {/* First Section Start  */}
        <section className="wrapper career-section">
          <h1>Job Openings</h1>
          <div className="row">
            <div className="search">
              <img src={search_img} alt=''></img>
              <input type="text" name="search" placeholder="Search Jobs..."></input>
              <img src={send_img} alt=''></img>
            </div>
            <select name="department" id="department">
              <option>Department</option>
              <option value="1">Front-end Technology</option>
              <option value="2">Sales & Markketing</option>
              <option value="3">Backend Technology</option>
              <option value="4">Computer Vision</option>
              <option value="5">Data Science & ML</option>
            </select>
            <select name="department" id="department">
              <option>Work Type</option>
              <option value="1">Developer</option>
              <option value="2">Tester</option>
              <option value="3">Data Scientist</option>
              <option value="4">Client Relation</option>
              <option value="5">Sales & Marketing</option>
            </select>
          </div>

          <JobRow
            jobid="1"
            date="Posted today"
            head="Backend Developer "
            description="Must have experience in Python and Django"
          >
          </JobRow>
          <JobRow
            jobid="2"
            date="Posted today"
            head="Client Relations & Inbound sales"
            description="Industry experience: Insurance/ Healthcare"
          >
          </JobRow>


          <a href="#" className="load-more">Load More</a>
        </section>
        {/* First Section End */}

        {/* Background Banner Section */}

        <NewsLetterSection
          title="Wanna know about the work culture at Artivatic officie ?"
          btn1text="Check out our Work Culture"
          btn1Route='/teams'
        >

        </NewsLetterSection>

        {/* Background Banner Section */}
      </div>
    </>
  );
}
import React from 'react';
import './Home.css';
import sslCertificate from '../Assets/png/SSL_certificate 1@2x.png';
import isoImg from '../Assets/png/iso_27001-150x150 1@2x.png';
import hippaImg from '../Assets/png/hipaa-artivatic 1@2x.png';
import dataandsecImage from '../../../assets/Logos_Pictures/Data and Security-2.png'

const Home = (props) => {
  return (
    <section className="home">
      <div className="home-img">
        <img src={dataandsecImage} alt="" />
      </div>
      <h1 className="heading-primary">Artivatic protects data & information</h1>
      <hr />
      <p className="text-primary">Artivatic is ISO 27001, HIPAA, SSL, GDPR, Personal Data Protection, Encryption &
        updated guidelines to ensure data safety, privacy, terms are aligned to the business & customer needs.
      </p>
      <div className="home-logos">
        <img className="home-logo" src={sslCertificate} alt="" />
        <img className="home-logo" src={isoImg} alt="" />
        <img className="home-logo" src={hippaImg} alt="" />
      </div>
    </section>
  )
}

export default Home;
import React from "react";
import "./JobRow.css";
export default function JobRow(props) {
    const {
        date,
        head,
        description,
        jobid
    } = props

    return (
        <div id="JobRow">
            <a href={`job-details/${jobid}`}>
                <section className="job-row">
                    <span>{date}</span>
                    <h3>{head}</h3>
                    <p>{description}</p>
                </section>
            </a>
        </div>
    );
}







import React from "react";
import "./Banner.css";

export default function Banner(props) {
    const { bannerClass, title, buttonText, navUrl, titleClass, buttonClass, hrClass,from } = props;
    return (
        <div id="Banner">
            <section className={`intro-banner ${bannerClass ? bannerClass : ''}`} >
                <div className="wrapper">
                    <h1 className={`title ${titleClass ? titleClass : ''}`}>{title}</h1>
                    <hr className={`hr ${hrClass ? hrClass : ''}`}></hr>
                    <a href={navUrl} target="_blank">
                        <button className={`button ${buttonClass ? buttonClass : ''}`}>{buttonText}</button>
                    </a>
                </div>
            </section>
        </div >
    );
}

import React, { useState } from "react";
import { Link, useHistory } from 'react-router-dom';

import ProductSlide from "../ProductSlide/ProductSlide.js";
import CoverImg from "../../assets/png/ausis-cover-img.png";
import Card from "../Card/Card.js";
import resourceImg1 from '../../assets/png/resource-card-image-1.webp'
import resourceImg2 from '../../assets/png/resource-card-image-2.webp'
import resourceImg3 from '../../assets/png/resource-card-image-3.png'
import resourceImg4 from '../../assets/png/resource-card-image-4.png'
import ausisImg from '../../assets/products/Ausis/23.webp'
import aspireImg from '../../assets/products/Aspire/ASPIRE IMAGE.webp'
import infrdImg from '../../assets/products/INFRD/INFRD-2.webp'
import alfredImg from '../../assets/products/ALFRD/ALRED-3.webp'
import mioImg from '../../assets/products/MiO/MIO-2.webp'
import prodxDesignImg from '../../assets/products/PRODX- Design/Product Design-2.webp'
import prodxDistImg from '../../assets/products/PRODX- Distribution/22.webp'
import ausisDocs from '../../assets/docs/AUSIS_Underwriting_Case_Study.pdf'

import u1img from '../../assets/png/resources34/u1.webp';
import u2img from '../../assets/png/resources34/u2.webp';
import u3img from '../../assets/png/resources34/u3.webp';
import u4img from '../../assets/png/resources34/u4.webp';
import u5img from '../../assets/png/resources34/u5.webp';

import "./Section8.css";
import Carousel from "../Carousel/carousel.js";

export default function Section8() {
  const [currentSlide, setCurrentSlide] = useState("ausis");
  const history = useHistory();


  const navigateHandler = () => {
    history.push('/contact-us');
  }
  function handleClick(e) {
    document
      .querySelector(".section-8 .btn-nav.select")
      .classList.remove("select");
    e.target.classList.add("select");
    const slideName = e.currentTarget.getAttribute("data-name");
    setCurrentSlide(slideName);
  }
  return (
    <section className="section-8 ">
      <div className="wrapper">
        <h1 className="heading">Artivatic Products</h1>
        <button className="demo-btn btn btn--sm btn--dark" onClick={navigateHandler}>
          <Link to="/contact-us" style={{ color: 'white' }}>Get a demo </Link>
        </button>

        <Carousel
          id="section_8__carousel"
          containerClassName="section_8__carousel__container"
          prevControlClassName="section_8__carousel__prev-control"
          nextControlClassName="section_8__carousel__next-control"
          fade="transparent"
          navColor="#22b966"
          className='section_8__carousel'
          dotNav={false}>
          <button
            className="btn-nav btn select"
            data-name="ausis"
            onClick={handleClick}
          >
            AUSIS
          </button>
          <button
            className="btn-nav btn"
            data-name="aspire"
            onClick={handleClick}
          >
            ASPIRE
          </button>
          <button
            className="btn-nav btn"
            data-name="infrd"
            onClick={handleClick}
          >
            INFRD
          </button>
          <button
            className="btn-nav btn"
            data-name="alfred"
            onClick={handleClick}
          >
            ALFRED
          </button>
          <button
            className="btn-nav btn"
            data-name="mio"
            onClick={handleClick}
          >
            MIO
          </button>
          <button
            className="btn-nav btn"
            data-name="prodx design"
            onClick={handleClick}
          >
            PRODX Design
          </button>
          <button
            className="btn-nav btn"
            data-name="prodx dist"
            onClick={handleClick}
          >
            PRODX Distribution
          </button>
        </Carousel>

      </div>
      {currentSlide === "ausis" && (
        <ProductSlide
          id="ausis__product-slide"
          imgSrc={ausisImg}
          tag="AUSIS for Life & Health Insurance"
          title=" AUSIS – A customised AI built & 3rd party data driven smart, real-time & personalized Underwriting Platform for Insurance.
              Underwriting"
          text="Artivatic’s AI- Native SaaS Solutions for insurance & health services as modular building blocks powered by best-in-class API gateway. New age product offerings, dynamic pricing, automation, smart underwriting, smart claims processing, enhanced customer experience, persistence & collections."
          relatedBtns={[
            "Medical Underwriting",
            "SME Underwriting",
            "Financial Underwriting",
            "Commercial Underwriting",
            'Life Underwriting',
          ]}
          link={'/products/ausis'}
        >
          <Card
            tag="Blog"
            title="Exponential Insurance. Underwriting Redefined"
            ancLink="https://blog.artivatic.ai/p/exponential-insurance-underwriting"
            btnText="Read more"
            cardImg={u5img}
            from='product_page_blog_section'
          />
          <Card
            tag="Blog"
            title="Underwriting 3.0: AI Based Dynamic & Smart decision making in real-time"
            ancLink="https://blog.artivatic.ai/p/underwriting-30-ai-based-dynamic "
            btnText="Read more"
            cardImg={resourceImg1}
            from='product_page_blog_section'
          />
          <Card
            tag="Case study"
            title="AI BASED MEDICAL & FINANCAIL UNDERWRITING SYSTEM FOR LIFE INSURANCE COMPANY IN INDIA"
            ancLink={ausisDocs}
            btnText="Read more"
            cardImg={resourceImg2}
            from='product_page_blog_section'
          />

        </ProductSlide>
      )}

      {currentSlide === "aspire" && (
        <ProductSlide
          id="aspire__product-slide"
          imgSrc={aspireImg}
          tag="Aspire for Life & Health Insurance"
          title=" ASPIRE – An end to end personalized & affordable solution for employee health & business insurance."
          text="A shared, modern, automated & AI-based Fullstack Employee & Group Health Benefits Placement System to manage the entire placement process in one shared system."
          relatedBtns={[
            "Group Health",
            "Commercial Insurance",
            "SME Insurance",
            "ASPIRE 360"
          ]}
          link={'/products/aspire'}
        >
          <Card
            tag="Case study"
            title="Simplifying Group Health & Commercial Insurance for Businesses, SMEs & Start-ups"
            ancLink="https://blog.artivatic.ai/p/simplifying-group-health-and-commercial"
            btnText="Read more"
            cardImg={resourceImg1}
            from='product_page_blog_section'
          />

        </ProductSlide>
      )}
      {currentSlide === "infrd" && (
        <ProductSlide
          id="infrd__product-slide"
          imgSrc={infrdImg}
          tag="API Economy is future"
          title="INFRD – Integration of Cloud APIs Platform with 400+APIs to provide a holistic solution for all insurance & healthcare services."
          text="The BETA version of INFRD Insurance APIs Cloud Platform has 400+ APIs that helps in onboarding, claims, risk management, fraud detection, automated underwriting, KYC, sales and services, marketing, health analysis, health scoring, handwritten documents, micro insurance, claim denials, video-based underwriting, image recognition, patient advisory and many more."
          relatedBtns={[
            "APIs",
            "Solutions",
            "Payment",
            "PIVC, Video KYC, Xtractor",
          ]}
          link={'/products/5'}
        >
          <Card
            tag="Case study"
            title="Artivatic Launches INFRD - API Insurance Cloud Platform to Build Future of Insurance"
            ancLink="https://blog.artivatic.ai/p/artivatic-launches-infrd-api-insurance"
            btnText="View all case studies"
            cardImg={resourceImg1}
            from='product_page_blog_section'
          />

        </ProductSlide>
      )}
      {currentSlide === "alfred" && (
        <ProductSlide
          id="alfred__product-slide"
          imgSrc={alfredImg}
          tag="API Economy is future"
          title="ALFRED – One integrated platform for all your claims needs. Provide end to end claims automation and assessment platform."
          text="ALFRED- AI Health Claims Platform to build 360 health ecosystem using tech & data to transform how real-time claims process can be settled."
          relatedBtns={[
            "Life Claims",
            "Health Claims",
            "Auto Claims",
            "Property Claims",
            'Commercial Claims',
          ]}
          link={'/products/alfred'}
        >
          <Card
            tag="Blog"
            title="Generative AI for Health Claims"
            ancLink="https://blog.artivatic.ai/p/generative-ai-for-health-claims"
            btnText="Read more"
            cardImg={u1img}
            from='product_page_blog_section'
          />
          <Card
            tag="Blog"
            title="The Health Insurance Sector and ChatGPT"
            ancLink="https://blog.artivatic.ai/p/the-health-insurance-sector-and-chatgpt"
            btnText="Read more"
            cardImg={u2img}
            from='product_page_blog_section'
          />
          <Card
            tag="Blog"
            title="Use of Chat GPT in Insurance Sector"
            ancLink="https://blog.artivatic.ai/p/use-of-chat-gpt-in-insurance-sector"
            btnText="Read more"
            cardImg={u3img}
            from='product_page_blog_section'
          />
          <Card
            tag="Blog"
            title="Enable Patient to get Discharge from Hospital in 30 mins"
            ancLink="https://blog.artivatic.ai/p/enable-patient-to-get-discharge-from"
            btnText="Read more"
            cardImg={u4img}
            from='product_page_blog_section'
          />
          <Card
            tag="Case study"
            title="Automating End to End Health Claims using AI & Data: ALFRED HEALTH CLAIMS"
            ancLink="https://blog.artivatic.ai/p/automating-end-to-end-health-claims"
            btnText="Read more"
            cardImg={resourceImg1}
            from='product_page_blog_section'
          />

        </ProductSlide>
      )}

      {currentSlide === "mio" && (
        <ProductSlide
          id="mio__product-slide"
          imgSrc={mioImg}
          tag="MIO for Life & Health Insurance"
          title="MIO – AI embedded sales, communication & marketing platform for a complete insurance & financial services providers"
          text="Empowering insurance players with a specialized AI based Sales & Marketing Platform to turn every opportunity into ROI. Powering Future Growth for Businesses using AI Driven Sales, Engagement, CRM and marketing."
          relatedBtns={[
            "MiO Sales",
            "MiO Agent/POSP",
            "MiO Service",
          ]}
          link={'/products/mio'}
        >
          <Card
            tag="Case study"
            title="Artivatic redefines Sales & Marketing: Launches AI Based MiOSales for Insurance"
            ancLink="https://blog.artivatic.ai/p/artivatic-redefines-sales-and-marketing"
            btnText="Read more"
            cardImg={resourceImg1}
            from='product_page_blog_section'
          />

        </ProductSlide>
      )}
      {currentSlide === "prodx design" && (
        <ProductSlide
          id="prodx_design__product-slide"
          imgSrc={prodxDesignImg}
          tag="ProdX for Life & Health Insurance"
          title="ProdX Design – A holistic Next-Gen rapid product designing with risk insights."
          text="ProdX allows use of historical data, underwriting risks, claims, location/geospatial data and more provide insights for creating risk-based products under 60 mins."
          relatedBtns={[
            "Life",
            "Health",
            "Commercial",
            "Micro",
            'Sachet'
          ]}
          link={'/products/prodx'}
        >
          <Card
            tag="Case study"
            title="Insurtech is the New Software!"
            ancLink="https://blog.artivatic.ai/p/insurtech-is-the-new-software"
            btnText="Read more"
            cardImg={resourceImg1}
            from='product_page_blog_section'
          />

        </ProductSlide>
      )}

      {currentSlide === "prodx dist" && (
        <ProductSlide
          id="prodx_dist__product-slide"
          imgSrc={prodxDistImg}
          tag="ProdX for Life & Health Insurance"
          title="ProdX Distribution – A customised B2B2C distribution and embedded insurance platform for businesses."
          text="Self Service API Driven SaaS Platform to allow partners /brokers/distributors to integrate insurance products to sell to their customers. Connects to Policy management & UW System."
          relatedBtns={[
            "B2B2C Distribution",
          ]}
          link={'/products/prodx'}
        >
          <Card
            tag="Case study"
            title="Insurtech is the New Software!"
            ancLink="https://blog.artivatic.ai/p/insurtech-is-the-new-software"
            btnText="Read more"
            cardImg={resourceImg1}
            from='product_page_blog_section'
          />

        </ProductSlide>
      )}
    </section>
  );
}

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from 'aos';
import "aos/dist/aos.css";

import rectImg from "../../assets/png/Rectangle 184.png"
import blackRightArrowIcon from '../../assets/vector/black-right-icon.svg';
import greenTick from '../../assets/vector/green-tick.svg';

import "./section13.css";
export default function Section13(props) {
  const { title, subtitle, list, button1, button2, imageDesk, imageMob, classes, extraInfo, from } = props;
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <section className={"section-13 | " + (classes ? classes.sectionClass : "")}>
      <div className="wrapper">
        <div className="headDiv">
          {title && title.type === "nospan" && (
            <h1 className={'' + (classes ? classes.titleClass : "")}
            >{title.Text}</h1>
          )}
          {title && title.type === "spanstart" && (
            <h1 className={'' + (classes ? classes.titleClass : "")}>{title.Text}<span>{title.span}</span></h1>
          )}
          {title && title.type === "spanmiddle" && (
            <h1 data-aos="zoom-in" className={'' + (classes ? classes.titleClass : "")}>{title.Text}<span>{title.span}</span>{title.Text2}<span className="product_page_product_name">{title.Text3 ? title.Text3 : ''}</span></h1>
          )}
          {title && title.type === "spanend" && (
            <h1 className={'' + (classes ? classes.titleClass : "")}>{title.Text}<span>{title.span}</span></h1>
          )}
          {subtitle && (
            <p data-aos="zoom-in" className={'' + (classes ? classes.subtitleClass : "")}>{subtitle}</p>
          )}
        </div>
        <div className="row">

          <div className="leftDiv">
            { !from && list && list.map((value, index) => {
              return <div key={index} className={'card ' + (value.classes ? value.classes.CardClass : '')}>
                {value.Title && (<h1 className={'' + (value.classes ? value.classes.TitleClass : '')}>{value.Title}</h1>)}
                {value.Description && (<p className={'' + (value.classes ? value.classes.DescriptionClass : '')}>{value.Description}</p>)}
              </div>
            })}
            {from && from === 'product_details_page' && list && (
              <div className="product_list_key_feature_data">
                {list.map((item, index) => (
                
                    <div key={index} className="in" data-aos="fade-right">
                      <img src={greenTick} alt="tick" className="green_tick_used_for_listing_img" />
                      <p>
                        { item.content.map((e,index2)=> {
                         return (<span key={index2} className={'mar3' + ' ' +(e.bold ? 'fwb' : '')}>{e.name}</span>)
                        })}
                      </p>
                    </div>
                
                ))}
              </div>
            )}
          </div>

          <div className="rightDiv" data-aos="zoom-in">
            <img src={imageDesk} alt='' className={'sec-13-image-desk' + ' ' + (title.Text3 && (title.Text3==='Provider Network' || title.Text3==='Fraud Intelligence System') ? 'eh' : '')}></img>
            <img src={imageMob} alt="" className='sec-13-image-mob' />
          </div>
        </div>
        <div className="row2">
          {button1 && (
            <a href={button1.Url}>
              <button className={'button-fill | ' + (classes ? classes.button1Class : "")}>{button1.Title}</button>
            </a>
          )}
          {button2 && (
            <a href={button2.Url}>
              <button className={'button-border | ' + (classes ? classes.button2Class : "")}>{button2.Title}</button>
            </a>
          )}
        </div>
        <div className="row3">
          {extraInfo && (
            <Link to={extraInfo.Url} className='extra-info'>
              <p>{extraInfo.Text}</p>
              <div className="arrow-icon"><img src={blackRightArrowIcon} alt="" /></div>
            </Link>
          )}
        </div>
      </div>
    </section>
  );
}

import React, { useEffect } from 'react'
import { gsap } from "gsap/dist/gsap";

import brandImg1 from "../../assets/png/brand1.png"
import brandImg2 from "../../assets/png/brand2.png"
import brandImg3 from "../../assets/png/brand3.png"
import brandImg4 from "../../assets/png/brand4.png"
import brandImg5 from "../../assets/png/brand5.png"
import brandImg6 from "../../assets/png/brand6.png"
import Marquee from "react-fast-marquee";

import './Logomarquee.css'
const Logomarquee = (props) => {
  const { Images, properties, dir, speed, classes } = props

  const List = []

  // Images.map((item, index) => (
  //   List.push(<img src={item} key={index}/>)
  // ))

  let boxWidth
  let boxHeight
  let totalWidth
  let totalHeight
  let n
  let dirFromLeft
  let dirFromRight
  n = Images.length
  boxWidth = 200
  totalWidth = boxWidth * n
  dirFromLeft = "+=" + totalWidth
  dirFromRight = "-=" + totalWidth

  // var mod = gsap.utils.wrap(0, totalWidth);
  // gsap.set(".logo-box", { zIndex: (i, target, targets) => targets.length * 0 });

  // const animation = (which, time, direction) => {
  //   gsap.set(which, {
  //     x: function (i) {
  //       return i * boxWidth;
  //     }
  //   });
  //   var action = gsap.timeline()
  //     .to(which, {
  //       x: direction,
  //       modifiers: {
  //         x: x => mod(parseFloat(x)) + 'px'
  //       },
  //       duration: time,
  //       ease: 'none',
  //       repeat: -1,
  //     })
  //   return action
  // }

  // useEffect(() => {
  //   var imageList = document.querySelectorAll('.logo-box')
  //   // const box = document.querySelector('.logo-box')

  //   var master = gsap.timeline()
  //     .add(animation(imageList, 15, dirFromRight))

  // console.log(imageList);
  // }, [])
  // console.log(speed);

  return (
    <div className={"logo-marquee-container " + (classes && classes.marqueeClass)}>
      {/* {
          Images.map((item, index) => (
            <div className="logo-box" key={index}>
              <img src={item} alt="" />
            </div>
          ))
        } */}
      <Marquee
        gradient={false}
        speed={speed}
        direction={`${dir}`}
        className='logo-marquee'
      >
        {
          Images.map((item, index) => (
            <div className="logo-box" key={index}>
              <img src={item} alt="" />
            </div>
          ))
        }
      </Marquee>
    </div>
  )
}

export default Logomarquee

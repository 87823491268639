import React from 'react';
import './Input.css';

const Input = (props) => {
    return (
        <form className="input-form">
            <input className="input" type={props.placeholder} placeholder={props.placeholder} />
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
            d="M0.00783647 16.4855C0.00886129 17.2029 0.74259 17.6859 1.40191 17.4032L18.8565 9.91908C19.6644 9.57267 19.6644 8.42733 18.8565 8.08092L1.40191 0.596818C0.74259 0.314117 0.00886129 0.797092 0.00783647 1.51447L0.00125254 6.12322C0.000535762 6.62497 0.371743 7.04957 0.869088 7.11588L15 9L0.869087 10.8841C0.371742 10.9504 0.00053576 11.375 0.00125254 11.8768L0.00783647 16.4855Z"
            fill="#22BB66" />
            </svg>
        </form>
    );
}

export default Input;

import React from "react";
import { Link } from 'react-router-dom';
import "./services.css";
import Carousel from "../Carousel/carousel"
export default function Services(props) {
  const { title, list, button1, currentSlide, setCurrentSlide ,id} = props;

  function handleClick(number) {
    setCurrentSlide(number);
  }
  // console.log(currentSlide);

  return (
    <section className="services">
      <label className="title">{title}</label>
      <div className="services__nav">
        {
          list.map((item, index) => (
            <button
              key={index}
              className={`lifeLabel ${currentSlide === index ? 'selected' : ''}`}
              onClick={() => handleClick(index)}
            >
              { id==='from_products' ? item.name : item}
            </button>
          ))
        }
      </div>
      {button1 && (
        <Link to={button1.Url}>
          <button className="demo">{button1.Text}</button>
        </Link>
      )}
    </section>
  )
}
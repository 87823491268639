import React from 'react';
import './Home6.css';



import u1img from '../../../assets/png/resources34/u1.webp';

const Home2 = (props) => {
  return (
    <section className="home">
      <img className="home-img" src={u1img} alt="" />
      <div className="home-info">
        <h1 className="heading-tertiary heading-tertiary--green">News</h1>
        <h1 className="heading-primary">Generative AI for Health Claims</h1>
        
        <p className="text-primary text-date" style={{fontWeight:'bold',color:'black'}}>Feb 08, 2023</p>
        <a className="btn news-view-btn" href='https://blog.artivatic.ai/p/generative-ai-for-health-claims' target='_blank'>
          View more
          <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.30347 7.98935L18.5111 7.98935L14.7306 11.4998C14.2092 11.984 14.2092 12.7103 14.7306 13.1945C15.2521 13.6787 16.0342 13.6787 16.5557 13.1945L22.5523 7.6262C23.0737 7.14201 23.0737 6.41571 22.5523 5.93152L16.5557 0.36325C16.0342 -0.120947 15.2521 -0.120947 14.7306 0.36325C14.2092 0.847447 14.2092 1.57374 14.7306 2.05794L18.5111 5.56837L1.30348 5.56837C0.651671 5.56837 -0.000130951 6.05256 -0.000131014 6.77886C-0.000131078 7.50515 0.651671 7.98935 1.30347 7.98935Z"
              fill="#22BB66" />
          </svg>
        </a>
      </div>

    </section>
  )
}

export default Home2;
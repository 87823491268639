import React from "react";

import greenCircle from "../../assets/vector/green-circle.svg";
import featureImg1 from '../../assets/Home_feature/AI ML & Data Driven Products.webp'
import featureImg2 from '../../assets/Home_feature/Automated Decision Platform.webp'
import featureImg3 from '../../assets/Home_feature/Continuously & Self Evolving.webp'
import featureImg4 from '../../assets/Home_feature/Code_free Integration.webp'
import featureImg5 from '../../assets/Home_feature/360 Data Intelligence.webp'
import featureImg6 from '../../assets/Home_feature/Scalable Technology.webp'

import "./Section10.css";
export default function Section10() {
  return (
    <section className="section-10 feature-section">
      <div className="wrapper">
        <h1 className="heading">
          Build <span style={{ color: "var(--primary)" }}>Next Future </span>of
          Insurance & Health
        </h1>
        <hr className="hr-underline"></hr>
        <p className="para">
          Future is about need based, instant, personalised & risk focused.
          Artivatic makes that happen with powerful AI driven platform.
        </p>
        <div className="grid">
          <div className="cell">
            <img src={featureImg1} alt="cell-img" />
            <h3>AI, ML & Data Driven Products</h3>
            <p>
              Bleeding-edge technology innovations that leverage Natural Language Processing, Deep Learning and Predictive Algorithms to build a scalable and reliable financial, healthcare & Insurtech systems. Enable backend systems for ‘human-like’ decision power.
            </p>
          </div>

          <div className="cell">
            <img src={featureImg2} alt="cell-img" />
            <h3>Automated Decision Platform</h3>
            <p>
              Helps businesses use automated decision engines to reduce labour costs, enforce policies and improve the quality of the customer experience. Genomic science & neuro-computing analogy-based engine powered with AI, enables intelligent automated decision.
            </p>
          </div>

          <div className="cell">
            <img src={featureImg3} alt="cell-img" />
            <h3>Progressive & Self Evolving</h3>
            <p>
              Taking inspiration from real life & science, our AI system evolves millions of scenarios and responses constantly selecting the best outcome in a given scenario. Improve efficiency through greater evolving information enables to think beyond technology systems.
            </p>
          </div>

          <div className="cell">
            <img src={featureImg4} alt="cell-img" />
            <h3>Code-free Integration</h3>
            <p>
              Making the entire process easier and faster to implement and execute without having to code your AI platform to save time and efforts. Artivatic’s DIY AI-platform enables businesses to directly integrate technology to their solutions.
            </p>
          </div>

          <div className="cell">
            <img src={featureImg5} alt="cell-img" />
            <h3>360 Data Intelligence</h3>
            <p>
              Capture and analyze complex individual-product-activities-operations-business-level data for agile optimization despite the channel or device. Uncovering hidden uplift opportunities with automated AI-driven targets.
            </p>
          </div>

          <div className="cell">
            <img src={featureImg6} alt="cell-img" />
            <h3>Scalable Technology</h3>
            <p>
              Whether it is one person team to one of the biggest organizations, our products and services are capable of providing optimal solutions to all your needs. Artivatic’s solutions are available in form of API/SDK’s, SaaS & on-premise.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";

import award1 from "../../assets/Awards/1.webp";
import award2 from "../../assets/Awards/2.webp";
import award3 from "../../assets/Awards/3.webp";
import award4 from "../../assets/Awards/4.webp";
import award5 from "../../assets/Awards/5.webp";
import award6 from "../../assets/Awards/6.webp";
import award7 from "../../assets/Awards/7.webp";

import "./Section3.css";
export default function Section3() {

  const award_content = [
    { img: award1, link: 'https://analyticsindiamag.com/10-indian-startups-that-are-leading-the-ai-race-2018/' },
    { img: award2, link: 'https://ehealth.eletsonline.com/2020/07/22-healthcare-startups-selected-as-ab-pmjay-grand-challenge-finalists/' },
    { img: award3, link: 'https://www.analyticsinsight.net/top-25-hottest-artificial-intelligence-startups-to-work-in-india/' },
    { img: award4, link: 'https://analyticsindiamag.com/top-data-science-providers-in-india-2021-penetration-and-maturity-pema-quadrant/' },
    { img: award5, link: 'https://analyticsindiamag.com/startups-that-won-indias-ai-solution-challenge-at-raise-2020/' },
    { img: award6, link: 'https://www.startupindia.gov.in/nsa2021results/fintech.html' },
    { img : award7, link: ''}
  ];
  return (
    <section className="section-3">
      <div className="wrapper">
        <h1 className="section-3__heading">Awards & Recognition</h1>
        <hr className="underline" />
        <div className="img-container">
          {/* <img className="section-3__img" src={award1} alt="award-1" />
          <img className="section-3__img" src={award2} alt="award-2" />
          <img className="section-3__img" src={award3} alt="award-3" />
          <img className="section-3__img" src={award4} alt="award-4" />
          <img className="section-3__img" src={award5} alt="award-5" /> */}
          {
            award_content.map((item, index) => {
              return (
                <a href={item.link} target="_blank" key={index}>
                  <img className="section-3__img" src={item.img} alt="Awards" />
                </a>
              )
            })
          }
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { useHistory} from 'react-router-dom';
import styles from './ShowResource.module.css';

import greenDot from '../../../assets/vector/green-circle.svg';


export default function ShowResource() {

    const history = useHistory();
    
    let filename = JSON.parse(localStorage.getItem('ingographicfilename'));
    let file_link = JSON.parse(localStorage.getItem('ingographicfilelink'));
    if(!file_link.includes('.pdf')) {
       history.push('/page-not-found');
    }

    return (
        <>
            <div className={styles.box}>
                <div className={styles.name}>
                    <img src={greenDot} alt="Pointer"/>
                    <p>{filename}</p>
                </div>
                {/* <object data={`${file_link}`} type="application/pdf" width="800" height="600"></object> */}
                <iframe src={`${file_link}#view=fitH`} title={filename} height="600px" width="100%"
                 />

            </div>

        </>
    )
}


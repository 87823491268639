import React from "react";

import abdm from '../../assets/png/certificates/artivatic-ABDM.jpeg'
import nioHealth from '../../assets/png/certificates/NiO-Health.png'
import Carousel1 from "../Carousel1/Carousel1.js";
import "./AbdmCertificate.css";

export default function AbdmCertificate() {
    const courosalValues = [];
    courosalValues.push({
        type: "hover",
        title: "ABDM Artivatic Data Labs Pvt Ltd",
        text: "Facilitates the creation of the Ayushman Bharat Health Account (ABHA) for individuals. Manages user consent for sharing health records.",
        cardImg: abdm
    })
    courosalValues.push({
        type: "hover",
        title: "Nio Health",
        text: "Provides a digital platform for managing personal health records.",
        cardImg: nioHealth

    })
    return (<section className="section-6">
        <div className="wrapper section-9-wrapper">
            <h1 className="section-6__heading">
                NHA - ABDM Certified
            </h1>

            <hr className="section-6__underline"/>
            <Carousel1
                id="section-6__carousel"
                values={courosalValues}
                dotNav={false}
            />
        </div>

    </section>);
}

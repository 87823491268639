import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Balls from './Balls/Balls';
import Home from './Home/Home';
import Home2 from './Home2/Home2';
import Home3 from './Home3/Home3';
import Home4 from './Home4/Home4';
import Home5 from './Home5/Home5';
import Home6 from './Home6/Home6';
import Home7 from './Home7/Home7';
import Home8 from './Home8/Home8';
import Home9 from './Home9/Home9';
import Nav from './Nav/Nav';
import NewsLetter from '../../components/NewsLetter/NewsLetter';
import News from './News/News';
import SwitchPage from './SwitchPage/SwitchPage';
import './NewsAndMedia.css';
import card1 from './Assets/png/card-1.png';
import card2 from './Assets/png/card-2.png';
import card3 from './Assets/png/card-3.png';
import card4 from './Assets/png/card-4.png';
import blog1 from './Assets/png/1.png';
import r1 from './Assets/png/2.png';
import r2 from './Assets/png/3.png';
import r3 from './Assets/png/4.png';
import r4 from './Assets/png/5.png';
import r5 from './Assets/png/6.png';
import r6 from './Assets/png/7.png';
import r7 from './Assets/png/8.png';
import r8 from './Assets/png/9.png';
import r9 from './Assets/png/10.png';
import r10 from './Assets/png/11.png';
import r11 from './Assets/png/12.png';

import n1 from './Assets/png/n1.png';
import n2 from './Assets/png/n2.png';
import n3 from './Assets/png/n3.jpeg';
import n4 from './Assets/png/n4.png';
import n5 from './Assets/png/n5.png';
import n6 from './Assets/png/n6.png';
import n7 from './Assets/png/n7.png';
import n8 from './Assets/png/n8.png';
import n9 from './Assets/png/n9.png';
import n10 from './Assets/png/n10.png';
import n11 from './Assets/png/n11.png';
import n12 from './Assets/png/n12.png';
import n13 from './Assets/png/n13.png';
import n14 from './Assets/png/n14.png';
import n15 from './Assets/png/n15.png';



const NewsAndMedia = (props) => {
  const [mediaSelected, setMediaSelected] = useState('All')

  const all_data = [
    {
      cardImg: n1,
      cardTag: 'News',
      cardTtitle: 'Success Story of Artivatic: AI-driven Automation Service for Insurance & Health',
      cardBtnText: 'Read More',
      link: 'https://startuptalky.com/artivatic-success-story/',
      new_page_open: true
    },
    {
      cardImg: n2,
      cardTag: 'News',
      cardTtitle: 'HR leaders’ nightmare: Lack of proper Background Verifications and its aftermath',
      cardBtnText: 'Read More',
      link: 'https://hr.economictimes.indiatimes.com/news/workplace-4-0/recruitment/hr-leaders-nightmare-lack-of-proper-background-verifications-and-its-aftermath/89311192',
      new_page_open: true
    },
    {
      cardImg: n3,
      cardTag: 'News',
      cardTtitle: 'Union Budget 2022 - what are startups expecting from FM Nirmala Sitharaman ahead of this budget',
      cardBtnText: 'Read More',
      link: 'https://www.zeebiz.com/hindi/video-union-budget-2022-what-are-startups-expecting-from-fm-nirmala-sitharaman-ahead-of-this-budget-72533',
      new_page_open: true
    },
    {
      cardImg: n4,
      cardTag: 'News',
      cardTtitle: 'Is Tech driving transformation of Buying & Selling Insurance by simplification?',
      cardBtnText: 'Read More',
      link: 'https://successinsightsindia.com/is-tech-driving-transformation-of-buying-selling-insurance-by-simplification/#_ftn1',
      new_page_open: true
    },
    {
      cardImg: n5,
      cardTag: 'News',
      cardTtitle: 'IOT is the Future of Insurance.The InsurTech segment has been attracting lots of funding in recent years, both in India and overseas.',
      cardBtnText: 'Read More',
      link: 'https://www.healthcareradius.in/features/technology/iot-is-the-future-of-insurance-%ef%bf%bc',
      new_page_open: true
    },
    {
      cardImg: n6,
      cardTag: 'News',
      cardTtitle: 'Artivatic.AI launches integrated platform for insurance sales, customer engagement',
      cardBtnText: 'Read More',
      link: 'https://www.livemint.com/companies/news/artivaticai-launches-integrated-platform-11641989946644.html',
      new_page_open: true
    },
    {
      cardImg: n7,
      cardTag: 'News',
      cardTtitle: '“National Startup day is a validation of start-ups’ role in the growth of India’s GDP”',
      cardBtnText: 'Read More',
      link: 'https://www.adgully.com/national-startup-day-is-a-validation-of-start-ups-role-in-the-growth-of-india-s-gdp-113230.html',
      new_page_open: true
    },
    {
      cardImg: n8,
      cardTag: 'News',
      cardTtitle: '#startupoftheday 96- Artivatic.AI , an AI-powered digital, risk & decision Insurtech platform',
      cardBtnText: 'Read More',
      link: 'https://www.biospectrumindia.com/features/85/20365/startupoftheday-96-artivaticai.html',
      new_page_open: true
    },
    {
      cardImg: n9,
      cardTag: 'News',
      cardTtitle: 'InsurTech – the Future of Insurance',
      cardBtnText: 'Read More',
      link: 'https://thesmeindia.com/smes-will-see-growth-on-the-back-of-better-insurance-plans-that-are-customised-to-their-particular-needs-cost-savings-and-automated-quality-backend-services/',
      new_page_open: true
    },
    {
      cardImg: n10,
      cardTag: 'News',
      cardTtitle: 'Top Insurtech trends to look forward to in 2022',
      cardBtnText: 'Read More',
      link: 'https://timesofindia.indiatimes.com/blogs/voices/top-insurtech-trends-to-look-forward-to-in-2022/',
      new_page_open: true
    },
    {
      cardImg: n11,
      cardTag: 'News',
      cardTtitle: 'How & why year-end is the right time to choose health insurance?',
      cardBtnText: 'Read More',
      link: 'https://health.economictimes.indiatimes.com/news/industry/how-why-year-end-is-the-right-time-to-choose-health-insurance/88617985',
      new_page_open: true
    },
    {
      cardImg: n12,
      cardTag: 'Press Release',
      cardTtitle: 'Artivatic.AI launches shared employee benefit placement system',
      cardBtnText: 'Read More',
      link: 'https://www.livemint.com/insurance/news/artivaticai-launches-shared-employee-benefit-placement-system-11640264483332.html',
      new_page_open: true
    },
    {
      cardImg: n13,
      cardTag: 'News',
      cardTtitle: 'Job opportunities in the insurance sector',
      cardBtnText: 'Read More',
      link: 'https://www.deccanherald.com/supplements/dh-education/job-opportunities-in-the-insurance-sector-1060957.html',
      new_page_open: true
    },

    {
      cardImg: n15,
      cardTag: 'News',
      cardTtitle: 'How technology can help start-ups, businesses & SMEs choose right group health & commercial insurance',
      cardBtnText: 'Read More',
      link: 'https://timesofindia.indiatimes.com/blogs/voices/how-technology-can-help-start-ups-businesses-smes-choose-right-group-health-commercial-insurance/',
      new_page_open: true
    },
    {
      cardImg: blog1,
      cardTag: 'News',
      cardTtitle: 'Artivatic launches smart AI underwriting platform named AUSIS-Financial Express',
      cardBtnText: 'Read More',
      link: 'https://blog.artivatic.ai/p/artivatic-launches-smart-ai-underwriting',
      new_page_open: true
    },
    {
      cardImg: r1,
      cardTag: 'News',
      cardTtitle: 'Top Artificial Intelligence As a Service Provider in India in 2021',
      cardBtnText: 'Read More',
      link: 'https://www.analyticsinsight.net/top-artificial-intelligence-as-a-service-provider-in-india-in-2021/',
      new_page_open: true
    },
    {
      cardImg: r2,
      cardTag: 'News',
      cardTtitle: 'KFin Technologies acquires 17% stake in Insurtech Startup Artivatic.AI',
      cardBtnText: 'Read More',
      link: 'https://inc42.com/buzz/kfin-technologies-acquires-17-stake-in-insurtech-startup-artivatic-ai/',
      new_page_open: true
    },
    {
      cardImg: r3,
      cardTag: 'News',
      cardTtitle: 'Artivatic.ai Raises Bridge Funding From Scale Ventures &amp; IAN',
      cardBtnText: 'Read More',
      link: 'http://bwdisrupt.businessworld.in/article/-Artivatic-ai-Raises-Bridge-Funding-From-Scale-Ventures-IAN/04-12-2020-349724/',
      new_page_open: true
    },
    {
      cardImg: r4,
      cardTag: 'News',
      cardTtitle: 'Artivatic.ai–Enabling Digital Insurance &amp; Health as Service-',
      cardBtnText: 'Read More',
      link: 'https://keevurds.com/brands/artivatic-enabling-digital-insurance-health-as-service/',
      new_page_open: true
    },
    {
      cardImg: r5,
      cardTag: 'News',
      cardTtitle: 'Locobuzz,Enthu.AI,Ingenium raise funding;Sitics Logistic Solutions buys Quifers',
      cardBtnText: 'Read More',
      link: 'https://www.vccircle.com/locobuzz-enthu-ai-ingenium-raise-funding-sitics-logistic-solutions-buys-quifers',
      new_page_open: true
    },
    {
      cardImg: r6,
      cardTag: 'News',
      cardTtitle: 'This IIT grad’s startup helps large insurance companies with credit underwriting and risk profiling',
      cardBtnText: 'Read More',
      link: 'https://yourstory.com/2020/06/iit-startup-insurance-companies-credit-underwriting-risk-profiling/amp',
      new_page_open: true
    },
    {
      cardImg: r7,
      cardTag: 'News',
      cardTtitle: 'AUSIS–AI based dynamic and personalized risk underwriting automation platform by Artivatic',
      cardBtnText: 'Read More',
      link: 'https://theprint.in/ani-press-releases/ausis-ai-based-dynamic-and-personalized-risk-underwriting-automation-platform-by-artivatic-2/668741/',
      new_page_open: true
    },
    {
      cardImg: r8,
      cardTag: 'News',
      cardTtitle: 'Artivatic redefines Sales & Marketing:launches AI Based MIOSales for insurance',
      cardBtnText: 'Read More',
      link: 'https://theprint.in/ani-press-releases/artivatic-redefines-sales-marketing-launches-ai-based-miosales-for-insurance/705434/',
      new_page_open: true
    },
    {
      cardImg: r9,
      cardTag: 'News',
      cardTtitle: 'Artivatic Launches ALFRED AI HEALTH CLAIMS Solution for Automating End to end Health Claims',
      cardBtnText: 'Read More',
      link: 'https://www.prnewswire.com/in/news-releases/artivatic-launches-alfred-ai-health-claims-solution-for-automating-end-to-end-health-claims-869305036.html',
      new_page_open: true
    },
    {
      cardImg: r10,
      cardTag: 'News',
      cardTtitle: 'IAN, Scale Ventures provide bridge funding to fintech startup Artivatic.ai',
      cardBtnText: 'Read More',
      link: 'https://www.vccircle.com/ian-scale-ventures-provide-bridge-funding-to-fintech-startup-artivatic-ai',
      new_page_open: true
    },
    {
      cardImg: r11,
      cardTag: 'News',
      cardTtitle: 'RAISE 2020: Meet the winners of India’s biggest AI Solution Challenge for Indian startups',
      cardBtnText: 'Read More',
      link: 'https://yourstory.com/2020/10/raise2020-meet-winners-ai-solution-challenge-indian-startups/amp',
      new_page_open: true
    }
  ];

  const newsData = []

  all_data.forEach((d) => {
    if (d.cardTag === 'News') {
      newsData.push(d)
    }
  })

  const pressData = []

  all_data.forEach((d) => {
    if (d.cardTag === 'Press Release') {
      pressData.push(d)
    }
  })

  return (
    <>
      <Helmet>
        <title>Artivatic | News & Media</title>
        <meta name="description" content="Explore all News,Company Press Releases and Media" />
        <meta name="keywords" content="Artivatic,News and Media,Press Release,Union Budget 2022." />
        <link rel="canonical" href="/news-media" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={`https://artivatic.ai/news-media`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Artivatic | MEDIA COVERAGE`} />
        <meta property="og:description" content={`Union Budget 2022`} />
        <meta property="og:image" content={`https://artivatic.ai/static/media/n3.eba7981d.jpeg`} />
        <meta property="og:image:width" content='1200' />
        <meta property="og:image:height" content='630' />

        {/* Twitter Meta Tags  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="artivatic.ai" />
        <meta property="twitter:url" content={`https://artivatic.ai/news-media`} />
        <meta name="twitter:title" content={`Artivatic | MEDIA COVERAGE`} />
        <meta name="twitter:description" content={`Union Budget 2022`} />
        <meta name="twitter:image" content={`https://artivatic.ai/static/media/n3.eba7981d.jpeg`} />
        <meta property="og:image:width" content='1200' />
        <meta property="og:image:height" content='630' />

      </Helmet>
      <div className="newsandmedia">
        <div className='main'>
          <Home9 />
          <br />
          <br />
          <Home8 />
          <br />
          <br />
          <Home7 />
          <br />
          <br />
          <Home6 />
          <br />
          <br />
          <Home5 />
          <br />
          <br />
          <Home4 />
          <br />
          <br />
          <br />
          <Home3 />
          <br />
          <br />
          <Home2 />
          <br />
          <Home />
          {/* <Balls /> */}
          <Nav
            mediaSelected={mediaSelected}
            setMediaSelected={setMediaSelected}
          />
          <News
            all_data={all_data}
            newsData={newsData}
            pressData={pressData}
            mediaSelected={mediaSelected}
          />
          {/* <h3 className="heading-tertiary only-mobile">Load more comments</h3> */}
          {/* <SwitchPage /> */}
        </div>
        <NewsLetter
          title="Lets build next-gen insurance & health products"
          btn1text="Schedule a Demo"
          btn1Route="/contact-us"
          btn2Route="/contact-us"
          btn2text="Request Pricing"
          subscribeBtn="true"
        />
      </div>
    </>
  )
}

export default NewsAndMedia;
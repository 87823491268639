import React from "react";
import { Helmet } from 'react-helmet-async';
import IntroductionSection from "../../components/IntroductionSection/IntroductionSection";
import img from "../../assets/png/mainimg.png";
import NewsLetter from "../../components/NewsLetter/NewsLetter"
import Section10 from "../../components/Section10/Section10";
import Section17 from "../../components/section17/section17";
import Testimonial from "../../components/Testimonial/Testimonial";
import APIs from "../../components/APIs/APIs";
import Section12 from "../../components/Section12/Section12";
import Section13 from "../../components/section13/section13";
import "./cloud-api.css";
import { useParams } from 'react-router-dom'
export default function CloudAPI() {
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>Artivatic | Cloud APIs</title>
        <meta name="description" content="Insurance & Healthcare APIs for all your needs. 400+ APIs Available for building next-gen insurtech." />
        <meta name="keywords" content="400+ Next-gen Cloud APIs,Artivatic"/>
        <link rel="canonical" href={`/cloud-api`} />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={`https://artivatic.ai/cloud-api`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Artivatic | AI Insurance Cloud APIs`}/>
        <meta property="og:description" content={`Insurance & Healthcare APIs for all your needs. 400+ APIs Available for building next-gen insurtech.`} />
        <meta property="og:image" content={`https://artivatic.ai/static/media/mainimg.6054c92c.png`} />
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>

        {/* Twitter Meta Tags  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="artivatic.ai" />
        <meta property="twitter:url" content={`https://artivatic.ai/cloud-api`} />
        <meta name="twitter:title" content={`Artivatic | AI Insurance Cloud APIs`} />
        <meta name="twitter:description" content={`Insurance & Healthcare APIs for all your needs. 400+ APIs Available for building next-gen insurtech.`} />
        <meta name="twitter:image" content={`https://artivatic.ai/static/media/mainimg.6054c92c.png`} />
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>

      </Helmet>
      <div id="cloudapi">
        <IntroductionSection
          title={{ type: "spanmiddle", Text: "", span: "Cloud APIs", Text2: "" }}
          subtitle="Insurance & Healthcare APIs for all your needs. 400+ APIs Available for building next-gen insurtech."
          description={{ Text1: "Embed our Insurance/Healthcare services into your products using our modular building block APIs for on-boarding, claims, risk, fraud, underwriting, KYC, sales, services, hospital and more." }}
          button1={{ Text: "Get a Demo", Url: "/contact-us" }} image={img}
          button2={{ Text: "View Case study", Url: "/contact-us" }}
          casestudyLbl=""
          from="cloudapi_page"
        >
        </IntroductionSection>

        <Section17
          title={{
            Text: "Impact &", span: "Benefits", rest: 'of the Cloud APIs'
          }}
          description="APIs and Cloud for Insurance & It's Impact on Business Operations."
          list={
            [{
              Title: "KYC APIs",
              Description: "Enable your consumers or partner businesses to onboard & register themselves seamlessly via any method."
            },
            {
              Title: "Social & Digital APIs",
              Description: "Powerful APIs that enable you to send social media posts effortlessly. For developers and businesses of all sizes."
            },
            {
              Title: "Financial APIs",
              Description: "APIs, digital links between data providers and end-users."
            },
            {
              Title: "Self- Signing",
              Description: "API SaaS Platform for Every Insurance, Finance Developer & Businesses."
            },
            {
              Title: "Self API Set-up",
              Description: "Helps in setting up your own APIs system."
            }
            ]} >

        </Section17>


        <APIs />
        <Testimonial />

        <NewsLetter
          title="Lets build next-gen insurance & health products"
          btn1text="Schedule a Demo"
          btn1Route="/contact-us"
          btn2Route="/contact-us"
          btn2text="Request Pricing"
          subscribeBtn="true"
        >
        </NewsLetter>

      </div >
    </>
  )
}
import React from "react";
import { Helmet } from 'react-helmet-async';
import Rectangle from "../../assets/png/Rectangle 184.png";
import serviceimg1 from "../../assets/Logos_Pictures/Team Pictures/2.jpg";
import serviceimg2 from "../../assets/Logos_Pictures/Team Pictures/3.jpg";
import serviceimg3 from "../../assets/Logos_Pictures/Team Pictures/4.jpg";
import serviceimg4 from "../../assets/Logos_Pictures/Team Pictures/5.jpg";
import serviceimg5 from "../../assets/Logos_Pictures/Team Pictures/6.png";

import serviceimg6 from "../../assets/Logos_Pictures/Team Pictures/7.webp";
import serviceimg7 from "../../assets/Logos_Pictures/Team Pictures/8.webp";
import serviceimg8 from "../../assets/Logos_Pictures/Team Pictures/9.webp";

import teamImg1 from "../../assets/png/team1.png"
import teamImg2 from "../../assets/png/team2.png"
import teamImg3 from "../../assets/png/team3.png"
import teamImg4 from "../../assets/png/team4.png"
import Section14 from "../../components/section14/section14";
import Section15 from "../../components/section15/section15";
import Section17 from "../../components/section17/section17";
import Section16 from "../../components/section16/section16";
import teamBibhas from '../../assets/png/Core Team/Bibhas Kumar Singh.png'
import teamRupesh from '../../assets/png/Core Team/rupesh.jpg'
import teamAshu from '../../assets/png/Core Team/ashu.webp'
import teamSrinivas from '../../assets/png/Core Team/srinivas.jpg'
import teamPrasun from '../../assets/png/Core Team/prasun.jpg'

import teamBishwajeet from '../../assets/png/Core Team/Bishwajeet Mahato.png'
import teamKiyuri from '../../assets/png/Core Team/Dr. Keyuri Jayesh Kotak.png'
import teamMinal from '../../assets/png/Core Team/Dr. Minal Muni.png'
import teamNeha from '../../assets/png/Core Team/Neha Ramaiya.png'
import teamSurendra from '../../assets/png/Core Team/Surendra Singh.png'

import teamAnkit from '../../assets/png/Core Team/Ankit Bhandari.png'
import teamSourabh from '../../assets/png/Core Team/Saurabh Agrawal.png'
import teamZohra from '../../assets/png/Core Team/Zohra_1.png';

import teamAnil from '../../assets/png/Core Team/Anil Kumar - Senior Software Engineer.webp';
import teamManisha from '../../assets/png/Core Team/Manisha Rajput - Chief Human Resource Officer.webp';
import teamVirendra from '../../assets/png/Core Team/Virendra Gupta - DevOps Engineer.webp';
import teamMahesh from '../../assets/png/Core Team/S Mahesh - VP Products.webp';
import teamSaziya from '../../assets/png/Core Team/Saziya Khan - Software Quality Assurance Engineer.webp';
import teamRakhi from '../../assets/png/Core Team/Rakhi Awasthi - Product Manager.webp';
import teamPaul from '../../assets/png/Core Team/Paul Varghese - Product Manager.webp';

import teamVideo from '../../assets/Logos_Pictures/Video/Team Video.mp4';



import "./team.css";
import IntroductionSection from "../../components/IntroductionSection/IntroductionSection";
export default function Team() {
  var description = "Artivatic makes all your tools work better by letting you integrate \n industry-leading software and custom apps."
  var courosalProperties = {
    navbutton: "backgroundnone greenicon",
    carouselClass: "margin-left-none"
  }
  var staffList = [
    
    {
      type: "tag",
      name: "Manisha Rajput",
      Designation: "Chief Human Resource Officer",
      Image: teamManisha
    },
    {
      type: "tag",
      name: "S Mahesh",
      Designation: "VP Products",
      Image: teamMahesh
    },
    {
      type: "tag",
      name: "Dr. Minal Muni",
      Designation: "Sr. Manager-Product Subject Matter Expert/Health",
      Image: teamMinal
    },
    {
      type: "tag",
      name: "Rakhi Awasthi",
      Designation: "Product Manager",
      Image: teamRakhi
    },
    {
      type: "tag",
      name: "Rupesh Routh",
      Designation: "Technical Service Head",
      Image: teamRupesh
    },
    
    {
      type: "tag",
      name: "Ashutosh Pandey",
      Designation: "CISO",
      Image: teamAshu
    },
    {
      type: "tag",
      name: "Srinivas KNS",
      Designation: "Head, Health",
      Image: teamSrinivas
    },
    {
      type: "tag",
      name: "Prasun Samir",
      Designation: "AI/ML Head",
      Image: teamPrasun
    },
    {
      type: "tag",
      name: "Neha Ramaiya",
      Designation: "Head- Customer Success and Strategic Alliances",
      Image: teamNeha
    },
    {
      type: "tag",
      name: "Bibhas Kumar Singh",
      Designation: "Team Lead- Backend",
      Image: teamBibhas
    },
    
    {
      type: "tag",
      name: "Bishwajeet Mahato",
      Designation: "Team Lead- Data Science",
      Image: teamBishwajeet
    },
    {
      type: "tag",
      name: "Ankit Bhandari",
      Designation: "Team Lead- DevOps",
      Image: teamAnkit
    },
    
    {
      type: "tag",
      name: "Anil Kumar",
      Designation: "Senior Software Engineer",
      Image: teamAnil
    },
    
    {
      type: "tag",
      name: "Saziya Khan",
      Designation: "Software Quality Assurance Engineer",
      Image: teamSaziya
    }



  ];




  return (
    <>
      <Helmet>
        <title>Artivatic | Meet Our Core Team</title>
        <meta name="description" content="A World of Delightful Possibilities.Life at Artivatic.Meet Our Core Team" />
        <meta name="keywords" content="Life At Artivatic,Core Team Members."/>
        <link rel="canonical" href="/teams" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={`https://artivatic.ai//teams`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Artivatic | Meet Our Core Team`}/>
        <meta property="og:description" content={`A World of Delightful Possibilities.Life at Artivatic.Meet Our Core Team`} />
        <meta property="og:image" content={`https://artivatic.ai/static/media/6.4efa1939.png`} />
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>

        {/* Twitter Meta Tags  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="artivatic.ai" />
        <meta property="twitter:url" content={`https://artivatic.ai//teams`} />
        <meta name="twitter:title" content={`Artivatic | Meet Our Core Team`} />
        <meta name="twitter:description" content={`A World of Delightful Possibilities.Life at Artivatic.Meet Our Core Team`} />
        <meta name="twitter:image" content={`https://artivatic.ai/static/media/6.4efa1939.png`} />
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>

      </Helmet>
      <div id="team">
        {/* First Section Start */}
        <IntroductionSection
          title={{ type: "spanmiddle", Text: "A World of ", span: "Delightful ", Text2: "Possibilities" }}
          description={{
            Text1: "At Artivatic, every work day is a great day. We empower employees by listening to their ideas and providing support whenever required. We are building a culture at Artivatic where innovative and creative people can carry out their finest work. If you think that you are ready to grow your career with Artivatic, you’ve reached arrive to the right place.",
          }}
          button1={{ Text: "Explore Openings", Url: "/career" }}
          button2={{ Text: 'Know more about Artivatic', Url: '/about-us' }}
          LocalVideo={teamVideo}
          classes={{
            titleClass: 'order-1',
            imageClass: "reactangle99 order-2",
            divClass: 'prod-width',
            descriptionClass: 'order-3',
            buttonsClass: 'order-4',
            videoClass: 'videoClass order-2'
          }}
        >
        </IntroductionSection>
        {/* Work Section Start */}
        
        {/* Third Section Start */}
        <section className="third-section">
          <div className="wrapper">
            <div className="row">
              <div className="col md-5">
                <h1>A Peek into Artivatican Life</h1>
                <img src={serviceimg1} alt=''></img>
              </div>
              <div className="col md-7">
                <img src={serviceimg6} alt=''></img>
              </div>
              <div className="col md-7">
                <img src={serviceimg7} alt=''></img>
              </div>
              <div className="col md-5">
                <img src={serviceimg8} alt=''></img>
              </div>
              <div className="col md-7">
                <img src={serviceimg2} alt=''></img>
              </div>
              <div className="col md-5">
                <img src={serviceimg3} alt=''></img>
              </div>
              <div className="col md-12">
                <img src={serviceimg4} alt=''></img>
              </div>
              <div className="col md-12">
                <img src={serviceimg5} alt=''></img>
              </div>
            </div>
          </div>
        </section>
        
        
        <Section16
          title="Our Investors"
          // description={description}
          investorCarousel={false}
          // button={
          //   {
          //     Url: '/investors',
          //     Text: 'View all Investors'
          //   }
          // }
          classes={{
            marqueeClass: 'marquee-margin-top-40'
          }}
        ></Section16>
        {/* Core Team Start */}
        <Section15
          staffList={staffList}
          title="Our Core Team"
        ></Section15>
        {/* Benefits Section Start  */}
        <section className="benefits">
          <div className="wrapper">
            <h1>Perks and Benefits</h1>
            <div className="line"></div>
            <p>We are all up for disrupting the status quo!</p>
            <p>Do you want to be a part of this revolution in the making?</p>
            <div className="row">
              <div className="col md-4">
                <span></span>
                <h3>Flexible Working Hours</h3>
              </div>
              <div className="col md-4">
                <span></span>
                <h3>Challenging Environment</h3>
              </div>
              <div className="col md-4">
                <span></span>
                <h3>High Learning Curve</h3>
              </div>
              <div className="col md-4">
                <span></span>
                <h3>You are CEO of your own</h3>
              </div>
              <div className="col md-4">
                <span></span>
                <h3>Family Health Cover & Unlimited online doctor consultation</h3>
              </div>
              <div className="col md-4">
                <span></span>
                <h3>Employee term Life Protection</h3>
              </div>
              <div className="col md-4">
                <span></span>
                <h3>Hybrid work culture- Work in office near to your home; Challenging problems to solve</h3>
              </div>
            </div>
          </div>
        </section>
        {/* Last Section Start */}
        <Section14
          title1="Do you want to be a part of our Innovation team?"
          button1={{ Text: "See current openings", Url: "/career" }}
          title2="We provide personalized insurance products based on customer goals"
          button2={{ Text: "Know more about Atrivatic", Url: "/about-us" }}>
        </Section14>
      </div >
    </>
  );
}
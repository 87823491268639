import React from "react";
import './SwitchPage.css';

const SwitchPage = (props) => {
  return (
    <section className="switch-page">
      <button className="btn btn-outlined">
        <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3393 4.19643L2.73214 4.19643L5.0625 1.86607C5.38393 1.54464 5.38393 1.0625 5.0625 0.741072C4.74107 0.419643 4.25893 0.419643 3.9375 0.741072L0.241071 4.4375C-0.0803575 4.75893 -0.0803576 5.24107 0.241071 5.5625L3.9375 9.25893C4.25893 9.58036 4.74107 9.58036 5.0625 9.25893C5.38393 8.9375 5.38393 8.45536 5.0625 8.13393L2.73214 5.80357L13.3393 5.80357C13.7411 5.80357 14.1429 5.48214 14.1429 5C14.1429 4.51786 13.7411 4.19643 13.3393 4.19643Z"
            fill="#22BB66" />
        </svg>
        Previous
      </button>
      <div className="pages">
        <div className="page page-active btn-previous">1</div>
        <div className="page page-active">2</div>
        <div className="page">3</div>
        <div className="page">4</div>
        . . . .
        <div className="page">17</div>
        <div className="page">18</div>
        <div className="page">19</div>
        <div className="page">20</div>
      </div>
      <button className="btn btn-outlined btn-next">
        Next
        <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.30347 7.98935L18.5111 7.98935L14.7306 11.4998C14.2092 11.984 14.2092 12.7103 14.7306 13.1945C15.2521 13.6787 16.0342 13.6787 16.5557 13.1945L22.5523 7.6262C23.0737 7.14201 23.0737 6.41571 22.5523 5.93152L16.5557 0.36325C16.0342 -0.120947 15.2521 -0.120947 14.7306 0.36325C14.2092 0.847447 14.2092 1.57374 14.7306 2.05794L18.5111 5.56837L1.30348 5.56837C0.651671 5.56837 -0.000130951 6.05256 -0.000131014 6.77886C-0.000131078 7.50515 0.651671 7.98935 1.30347 7.98935Z"
            fill="#22BB66" />
        </svg>
      </button>
    </section>
  )
}

export default SwitchPage;
import React from "react";
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

// Images

import "./jobdetails.css";

export default function JobDetails() {
    const params = useParams();

    let content;

    if (params.id === '1') {
        content = (
            <div>
                <h6>Roles & Responsibilities:</h6>
                <ul>
                    <li>Building server-side logic that powers our APIs, in effect deploying machine learning models in
                        production system that can scale to billions of API calls</li>
                    <li>Scaling and performance tuning of database to handle billions of API calls and thousands of
                        concurrent requests</li>
                    <li>Collaborate with data science team to build effective solutions for data collection, pre-
                        processing and integrating machine learning into the workflow</li>
                    <li>Collaborate, provide technical guidance, and engage in design and code review for other team
                        members</li>
                    <li>Experience of architecting and developing large distributed, scalable, robust, high-performing
                        systems</li>
                    <li>Solid experience in Python, Django, DRF</li>
                    <li>Solid UNIX or Linux experience</li>
                    <li>Great understanding of database theory and solid experience in at least one relational or non-
                        relational DBMS</li>
                    <li>Highly fluent with Git, or similar distributed source management system with a good grasp of
                        branch/release management, rollback strategies and other best practices</li>
                    <li>Ability to handle multiple competing priorities in a fast-paced environment</li>
                    <li>Experience working with service oriented architectures and web based solutions</li>
                    <li>3+ year’s industry experience in developing, understanding of software design principles and
                        launching production grade software.</li>
                    <li>Bachelors or Master’s Degree in Computer Science or related field</li>
                </ul>
            </div>
        )
    }
    if (params.id === '2') {
        content = (
            <div>
                <h6>Responsibilities:</h6>
                <ul>
                    <li>Coordination of clients' concerns and adjustment of the services or products to the clients'
                        needs</li>
                    <li>Resolving of any issues on the clients' site and cooperation with respective other departments</li>
                    <li>Identification of new business opportunities by monitoring of inbound marketing channels and
                        activities</li>
                    <li>Maintenance of existing and building of new relationships by provision of any necessary support
                        and recommendations on new opportunities</li>
                    <li>Ensuring a high-performing customer service system</li>
                    <li>Monitoring of performance and analysis of reports in order to derive improvement plans</li>
                </ul>
                <h6>Requirements:</h6>
                <ul>
                    <li>Degree in business, marketing or any other related field</li>
                    <li>At best, practical experience in a similar position</li>
                    <li>Strongly developed networking and communication as well as analytical skills</li>
                    <li>High customer-orientation and business acumen</li>
                    <li>Ability to organize projects simultaneously and keep an eye on the details</li>
                    <li>Interpersonal skills as well as the will and ability to cooperate with people from other
                        departments and managerial levels</li>
                </ul>
            </div>
        )
    }


    return (
        <div id="JobDetails">
            <section className="job-details">
                <h1>Job Description</h1>
                <div className="line"></div>
                <div className="first-line">
                    <h2>{params.id === '1' ? 'Back-End Developer' : 'Client Relations & Inbound sales'}</h2>
                    <span><Link to={`/job-submit/${params.id}`} style={{color:'#22BB66'}}>Apply Now</Link></span>
                </div>
                <span>Full-time  |  Remote</span>

                <h6>About Artivatic</h6>
                <p>Artivatic is a global risk & decision-making platform that automates human decisions in insurance &
                    healthcare to provide efficiency, transparency, risk assessment, personalization and digitization in
                    entire lifecycle of operations. The platform uses deep machine learning and proprietary algorithms

                    to offer insurance & healthcare solutions like smart risk underwriting, alternative data insights, real-
                    time personalized product offering & automated onboarding, claims automation, sales &

                    distribution, self-branch servicing, customer 360, insights and analytics,
                </p>
                <p>Artivatic empowers insurance & healthcare businesses and developers to re-imagine insurance &
                    health products for the next billion users. We at Artivatic are constantly building low-cost, modular
                    API infrastructure so that insurance businesses /hospitals can go live in a matter of days, not
                    months.
                </p>
                <p>Artivatic is transforming legacy, traditional, complex and 'one size fits all' in to personalized, digital,
                    instant, risk based, new age InsurTech that touches human lives, understand their need, use data to
                    provide them better, need based products instantly using technology. Artivatic aims to provide
                    personalized insurance products based on customer goals/need under 60 seconds using data,
                    technology and process.
                </p>
                <p>Artivatic has 50+ members team with presence in INDIA & USA. Artivatic is funded by KFintech (backed
                    by General Atlantic), IAN Fund & IAN Network.
                </p>

                {content}


                <div className="applyjob">
                    <span><Link to={`/job-submit/${params.id}`}>Apply for this job</Link></span>
                </div>
            </section>
        </div>
    );
}
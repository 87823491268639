import React from "react";
import './News.css';
import card1 from '../Assets/png/card-1.png';
import card2 from '../Assets/png/card-2.png';
import card3 from '../Assets/png/card-3.png';
import card4 from '../Assets/png/card-4.png';
import Card from "../Card/Card";

const News = (props) => {
  const { newsData, all_data, pressData,mediaSelected } = props;
 
  
  return (
    <section className="news">
      {
        mediaSelected === 'All' && (
          <>
            {
              all_data.map((item, i) => (
                <Card key={i} imgSrc={item.cardImg} tag={item.cardTag} title={item.cardTtitle} btnText={item.cardBtnText}
                      link={item.link} new_page={item.new_page_open} />
              ))
            }
          </>
        )
      }
      {
        mediaSelected === 'News' && (
          <>
            {
              newsData.map((item, i) => (
                <Card key={i} imgSrc={item.cardImg} tag={item.cardTag} title={item.cardTtitle} btnText={item.cardBtnText}
                      link={item.link} new_page={item.new_page_open} />
              ))
            }
          </>
        )
      }
      {
        mediaSelected === 'Press Release' && (
          <>
            {
              pressData.map((item, i) => (
                <Card key={i} imgSrc={item.cardImg} tag={item.cardTag} title={item.cardTtitle} btnText={item.cardBtnText} 
                      link={item.link} new_page={item.new_page_open}/>
              ))
            }
          </>
        )
      }
      
    </section>
  )
}

export default News;
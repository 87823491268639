import React from "react";
import Carousel from "../../components/Carousel/carousel";
import "./section15.css"
export default function Section15(props) {
  const { staffList, title } = props
  return (
    <section className="core-team">
      <div className="wrapper">
        <div className="row desktop">
          <h1>{title}</h1>
          <div className="images_wrapper">
            {staffList.map((value, index) => {
              return <div className="col-md-4" key={index}>
                <div className="card">
                  <div className="team_image">
                    <img src={value.Image} className="profilePic" alt=''></img>
                  </div>
                  <div className="pad24">
                    <label className="title">{value.name}</label>
                    <label className="designation">{value.Designation}</label>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
        <div className="row mobile">
          <h1>{title}</h1>
          <Carousel
            id="core-team__carousel"
            containerClassName="core-team__carousel__container"
            prevControlClassName="core-team__carousel__prev-control"
            nextControlClassName="core-team__carousel__next-control"
            fade="primary-vl"
            navColor="#22b966"
            dotNav={false}
          >
            {staffList.map((value, index) => {
              return <div className="col-md-4" key={index}>
                <div className="card team_cards">
                  <div className="team_image">
                    <img src={value.Image} className="profilePic" alt=''></img>
                  </div>
                  <div className="pad24">
                    <label className="title">{value.name}</label>
                    <label className="designation">{value.Designation}</label>
                  </div>
                </div>
              </div>
            })}
          </Carousel>
        </div>
      </div>
    </section>
  )
}
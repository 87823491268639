import React from 'react';

import img from '../../assets/404 Error Code.png';
import styles from './PageNotFound.module.css';

const PageNotFound = () => {
    return (
        <div className={styles.box}>
            <img src={img}/>
            <h1>Oops!This page can't be found.</h1>
            <button><a href="/">Back To Home Page</a></button>
        </div>
    )
}

export default PageNotFound;
import React from "react";

import Card from "../../components/Card/Card";
import cardImg from "../../assets/png/cardIMG.png";
import b1img from '../../assets/png/resources34/71 - Front Page.png';
import b2img from '../../assets/png/resources34/74 - Front Page.webp';
import b3img from '../../assets/png/resources34/75 - Front Page.webp';
import b4img from '../../assets/png/resources34/78 - Front Page.webp';
import newsimg from '../../assets/png/resources34/113 - Front Page.webp';

import u1img from '../../assets/png/resources34/u1.webp';
import u2img from '../../assets/png/resources34/u2.webp';
import u3img from '../../assets/png/resources34/u3.webp';
import u4img from '../../assets/png/resources34/u4.webp';
import u5img from '../../assets/png/resources34/u5.webp';

import Carousel from "../../components/Carousel/carousel.js";
import "./Section12.css";
import resourceImg1 from '../../assets/png/resource-card-image-1.png'
import resourceImg2 from '../../assets/png/resource-card-image-2.png'
import resourceImg3 from '../../assets/png/resource-card-image-3.png'
import resourceImg4 from '../../assets/png/resource-card-image-4.png'
export default function Section12(props) {
  const { title, tagline, des, date, img, link, resources, from } = props;
  return (
    <div>
      {from === 'Product_page' && <section className="section-12 resource-section">
        <div className="wrapper">
          <h1 className="heading">Resources that help you grow</h1>
          <hr className="hr-underline" />
          <a href={link} target="_blank">
            <div className="main-card">

              <div className="img-container">
                <img src={img} alt="card-img" className="main-card-img" />
              </div>
              <div className="text">
                <p className="tag">{title}</p>
                <h3>{tagline}</h3>
                <p className="details">
                  {des}
                </p>
                <p className="date">{date}</p>
              </div>

            </div>
          </a>


          <Carousel
            id="resource-section__carousel"
            containerClassName="resource-section__carousel__container"
            prevControlClassName="resource-section__carousel__prev-control"
            nextControlClassName="resource-section__carousel__next-control"
            fade="primary-vl"
            navColor="#22b966"
            dotNav={false}

          >
            {resources ? resources.map((item, index) => {
              return (
                <Card
                  key={index}
                  from="product_page_blog_section"
                  tag={item.tag}
                  title={item.title}
                  ancLink={item.link}
                  btnText={item.btnText}
                  cardImg={item.img}
                />
              )
            }) : ''}

          </Carousel>

          <a className="resource-section__view-more-btn btn btn--bg btn--dark" href='/resources/blogs'>
            View all Resources
          </a>
        </div>
      </section >}

      {from === 'Home_page' && <section className="section-12 resource-section">
        <div className="wrapper">
          <h1 className="heading">Resources that help you grow</h1>
          <hr className="hr-underline" />
          <a href="https://blog.artivatic.ai/p/stronger-together-artivatic-the-way?s=r" target="_blank">
            <div className="main-card">
              <div className="img-container">
                <img src={newsimg} alt="card-img" className="main-card-img" />
              </div>
              <div className="text">
                <p className="tag">News</p>
                <h3>Stronger Together! Artivatic - The way forward!</h3>
                <p className="details">
                  Throughout our journey, we have always believed that 'The First Step towards getting somewhere is to decide you're not going to stay where you're' and that's what holds true to Artivatic too. We are pleased to announce today that Artivatic is getting stronger with the new Partner <b>RenewBuy.com</b>
                </p>
                <p className="date">Feb 28,2022</p>
              </div>
            </div>
          </a>

          <Carousel
            id="resource-section__carousel"
            containerClassName="resource-section__carousel__container"
            prevControlClassName="resource-section__carousel__prev-control"
            nextControlClassName="resource-section__carousel__next-control"
            fade="primary-vl"
            navColor="#22b966"
            dotNav={false}
          >
            <Card
              tag="Blog"
              title="Generative AI for Health Claims"
              ancLink="https://blog.artivatic.ai/p/generative-ai-for-health-claims"
              btnText="Read more"
              cardImg={u1img}
              from='product_page_blog_section'
            />
             <Card
              tag="Blog"
              title="The Health Insurance Sector and ChatGPT"
              ancLink="https://blog.artivatic.ai/p/the-health-insurance-sector-and-chatgpt"
              btnText="Read more"
              cardImg={u2img}
              from='product_page_blog_section'
            />
            <Card
              tag="Blog"
              title="Use of Chat GPT in Insurance Sector"
              ancLink="https://blog.artivatic.ai/p/use-of-chat-gpt-in-insurance-sector"
              btnText="Read more"
              cardImg={u3img}
              from='product_page_blog_section'
            />
            <Card
              tag="Blog"
              title="Enable Patient to get Discharge from Hospital in 30 mins"
              ancLink="https://blog.artivatic.ai/p/enable-patient-to-get-discharge-from"
              btnText="Read more"
              cardImg={u4img}
              from='product_page_blog_section'
            />
            <Card
              tag="Blog"
              title="Exponential Insurance. Underwriting Redefined"
              ancLink="https://blog.artivatic.ai/p/exponential-insurance-underwriting"
              btnText="Read more"
              cardImg={u5img}
              from='product_page_blog_section'
            />
            <Card
              tag="Blog"
              title="Simplifying Group Health & Commercial Insurance for Businesses, SMEs & Start-ups"
              ancLink="https://blog.artivatic.ai/p/simplifying-group-health-and-commercial?s=r"
              btnText="Read more"
              cardImg={b1img}
              from='product_page_blog_section'
            />
            <Card
              tag="Blog"
              title="Artivatic Launches INFRD - API Insurance Cloud Platform to Build Future of Insurance"
              ancLink="https://blog.artivatic.ai/p/artivatic-launches-infrd-api-insurance?s=r"
              btnText="Know more"
              cardImg={b3img}
              from='product_page_blog_section'
            />
            <Card
              tag="Blog"
              title="Automating End to End Health Claims using AI & Data: ALFRED HEALTH CLAIMS"
              ancLink="https://blog.artivatic.ai/p/automating-end-to-end-health-claims?s=r"
              btnText="Know more"
              cardImg={b2img}
              from='product_page_blog_section'
            />
            <Card
              tag="Blog"
              title="Artivatic redefines Sales & Marketing: Launches AI Based MiOSales for Insurance"
              ancLink="https://blog.artivatic.ai/p/artivatic-redefines-sales-and-marketing?s=r"
              btnText="Know more"
              cardImg={b4img}
              from='product_page_blog_section'
            />
          </Carousel>

          <a className="resource-section__view-more-btn btn btn--bg btn--dark" href='/resources/blogs'>
            View all Resources
          </a>
        </div>
      </section>}
    </div>

  );
}

import React from "react";
import { Link } from "react-router-dom";

import nativeImgMob from "../../assets/png/native-section.png";
import nativeImgDesk from "../../assets/png/native-section-desk.png";

import "./Section11.css";
export default function Section11({ title, description, link, clientList }) {
  // console.log(title, description, Link, clientList);
  return (
    <section className="section-11">
      <div className="wrapper">
        <div className="section-11__content">
          <h1>
            {title.Text}{" "}
            <span style={{ color: "var(--primary" }}>{title.span}</span>
            {title.Text2}
          </h1>
          <hr className="hr-underline"></hr>
          <p>
            {description.Text}
            {!description.Text2 && (<span className="sec11spanreadmore">
              <Link to={link.LinkRoute}>{link.Text}</Link>
            </span>)
            }
          </p>
          {description.Text2 && (<p>
            {description.Text2}
            <span className="sec11spanreadmore">
              <Link to={link.LinkRoute}>{link.Text}</Link>
            </span>
          </p>)}

        </div>
        {/* <div className="img-container">
          <div className="client-image-row-1">
            {
              clientList.map((c, index) => (
                <div className="client_image" key={index}>
                  <img src={c} alt="client-img" />
                </div>
              ))
            }
          </div>
        </div> */}
        <div className="img-container-new-added">
          {
            clientList.map((c, index) => (
              <div className="client-image-mywrap" key={index}>
                <img src={c.file} alt="client-img" className={`${c.flag === 'rect' ? "clientRectImg" : ""} ${c.flag === 'sbi' ? 'sbiclient' : ""} ${c.flag === 'square' ? "clientSquareImg" : "" }`} />
              </div>
            ))
          }
        </div>
      </div>
    </section>
  );
}

import React from "react";

import blackDot from "../../assets/vector/blackDot.svg";
import "./Section7.css";
export default function Section7(props) {
  const { title, subtitle, cards, sec7 } = props;
  // console.log(sec7);
  return (
    <section className="section-7 new-product-section">
      <div className="wrapper">
        {/* <div className="dot-grid-1"></div> */}
        <div className="dot-grid-2">
          <span role="img" aria-label="dot-grid-img"> </span>
        </div>

        <div className="heading">
          <h1>
            {title.line1}
            <br />{title.line2green ? (
              <><span style={{ color: "var(--primary)" }}>{title.greenText}</span><span>{title.line2}</span></>
            ) : (title.line2)}
            <br />
            {
              title.line3green ? (
                <span style={{ color: "var(--primary)" }}>{title.greenText}</span>
              ) : <span>{title.line3}</span>
            }
          </h1>
        </div>
        <div className="custom_box">
          {
            cards.map((card, index) => (
              <div className="grid-card mob" key={index}>
                <img src={card.imgLink} alt="grid-card-img" />
                <h2>{card.Title}</h2>
                <p>
                  {card.Description}
                </p>
                <a href={card.btnLink}>Know more</a>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  );
}

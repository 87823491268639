import React from 'react';
import { Helmet } from 'react-helmet-async';
import blackRightArrowIcon from '../../assets/vector/black-right-icon.svg'
import infrdLogo from '../../assets/products/INFRD/INFRD.svg'
import alfredLogo from '../../assets/products/ALFRD/ALFRED.svg'
import aspireLogo from '../../assets/products/Aspire/ASPIRE.svg'
import ausisLogo from '../../assets/products/Ausis/AUSIS.svg'
import mioLogo from '../../assets/products/MiO/MiO.svg'
import prodxDesignLogo from '../../assets/products/PRODX- Design/PRODX DESIGN.svg'
import prodxDistLogo from '../../assets/products/PRODX- Distribution/PRODX DISTRIBUTION.svg';
import genericLogo from '../../assets/Generic Logo.png';

import styles from './Login.module.css';


const Login = () => {
    const productList = [
        { Title: "ASPIRE", Description: "An end to end personalized & affordable solution for employee health & business insurance.", btnLink: 'https://aspirebq.com/home', imgLink: aspireLogo },
        { Title: "ALFRED", Description: 'One integrated platform for all your claims needs. Provide end to end claims automation and assessment platform.', btnLink: 'https://alfred.artivatic.ai/', imgLink: alfredLogo },
        { Title: "AUSIS", Description: "A Customized AI built & 3rd party data driven smart, real-time & personalized Underwriting Platform for Insurance.", btnLink: 'https://ausis.ai/journey', imgLink: ausisLogo },
        { Title: "MiO Sales", Description: "AI embedded sales, communication & marketing platform for a complete insurance & financial services providers.", btnLink: 'https://miosales.com/home', imgLink: mioLogo },
        { Title: "PRODX Design", Description: "A holistic Next-Gen rapid product designing with risk insights.", btnLink: 'https://design.prodxai.com/#/main/login', imgLink: prodxDesignLogo },
        { Title: "PRODX Distribution", Description: "A   customised   B2B2C   distribution   and   embedded   insurance   platform   for businesses.", btnLink: 'http://35.200.168.137:8095/', imgLink: prodxDistLogo },
        { Title: "INFRD", Description: 'Integration of Cloud APIs Platform with 400+APIs to provide a holistic solution for all insurance & healthcare services.', btnLink: 'https://infrd.artivatic.ai/main', imgLink: infrdLogo },
    ];

    const otherList = [
        { Title: 'Early Claims', Description: 'Manage all aspects of an Early claims, from early assessment to investigation and through settlement.', btnLink: '/products/early-claims', imgLink: genericLogo },
        { Title: 'Pre-Issuance Verification Calls(PIVC)', Description: 'Pre-Issuance Verification Call or PIVC- A solution that lets you confirm your application details via video.', btnLink: '/products/preinsurance-verification', imgLink: genericLogo },
        { Title: 'SecondQuote Engine', Description: 'Alternative insurance buying journey using Facial Analysis.', btnLink: '/products/secondQuote-engine', imgLink: genericLogo },
        { Title: 'Inspection Systems', Description: 'AI Damage Assessment Engine for end to end property/vehicle inspection', btnLink: '/products/inspection-systems', imgLink: genericLogo },
        { Title: 'OCR & ICR(Handwritten)', Description: 'In-house built algorithms and models to provide document reading, matching and analysis.', btnLink: '/products/ocr-icr', imgLink: genericLogo },
        { Title: 'Provider Network', Description: 'AI-Driven 360-degree platform that connects Patients, Providers, and Payers (Insurance)', btnLink: '/products/provider-network', imgLink: genericLogo },
        { Title: 'CARSURE', Description: 'ALFRED MOTOR /CARSURE Platform uses its smart AI driven technology and data to automate Vehicle Inspections and vehicle Claims Assessment.', btnLink: 'https://alfred.artivatic.ai/', imgLink: genericLogo },
    ];
    return (
        <>
            <Helmet>
                <title>User Login</title>
                <meta name="description" content="Explore All Our Products & Modules." />
                <meta name="keywords" content="Artivatic User Login,Products,Modules and APIs"/>
                <link rel="canonical" href="/user-login" />

                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`https://artivatic.ai/user-login`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`User Login`} />
                <meta property="og:description" content={`Explore All Our Products & Modules.`} />
                <meta property="og:image" content={`https://artivatic.ai/favicon.jpeg`} />
                <meta property="og:image:width" content='1200' />
                <meta property="og:image:height" content='630' />

                {/* Twitter Meta Tags  */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="artivatic.ai" />
                <meta property="twitter:url" content={`https://artivatic.ai/user-login`} />
                <meta name="twitter:title" content={`User Login`} />
                <meta name="twitter:description" content={`Explore All Our Products & Modules.`} />
                <meta name="twitter:image" content={`https://artivatic.ai/favicon.jpeg`} />
                <meta property="og:image:width" content='1200' />
                <meta property="og:image:height" content='630' />

            </Helmet>
            <div className={styles.page}>
                <div className={styles.heading}>
                    <h1>Explore All Our Products</h1>
                </div>
                <div className={styles.content}>
                    {
                        productList.map((card, index) => (
                            <div className={styles.card} key={index}>
                                <div className={`${styles.logobox}`}>
                                    <img src={card.imgLink} className={styles.logo} alt="product-logo" />
                                </div>
                                <div className={styles.box}>
                                    <h2 className={styles.cardtitle}>{card.Title}</h2>
                                    <p className={styles.carddes}>
                                        {card.Description}
                                    </p>
                                    <a href={card.btnLink} target="_blank">
                                        <span>Visit Website</span>
                                        <img src={blackRightArrowIcon} className={styles.cardlink} alt="website-link-arrow" />
                                    </a>
                                </div>


                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={styles.page2}>
                <div className={styles.heading}>
                    <h1>Other Modules & APIs</h1>
                </div>
                <div className={styles.content}>
                    {
                        otherList.map((card, index) => (
                            <div className={styles.card} key={index}>
                                <div className={`${styles.logobox}`}>
                                    <img src={card.imgLink} className={styles.logo} alt="product-logo" />
                                </div>
                                <div className={styles.box}>
                                    <h2 className={styles.cardtitle}>{card.Title}</h2>
                                    <p className={styles.carddes}>
                                        {card.Description}
                                    </p>
                                    <a href={card.btnLink} target={card.btnLink.includes('https') ? '_blank' : ''}>
                                        <span>Visit Page</span>
                                        <img src={blackRightArrowIcon} className={styles.cardlink} alt="website-link-arrow" />
                                    </a>
                                </div>


                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}
export default Login;
import React from "react";
import logo from "../../assets/logo-light.svg";
import logo2 from "../../assets/RenewBuy White.webp";
import ssl from "../../assets/png/SSL_certificate 1.png";
import iso from "../../assets/png/iso_27001-150x150 1.webp";
import hippa from "../../assets/png/hipaa-artivatic 1.png";
import pdficon from "../../assets/ic_pdf.svg";
import pdf1 from "../../assets/MGT 7_Artivatic.pdf";
import "./Footer.css";
export default function Footer() {
  
  const download = () => {
    let filename = 'MGT 7_Artivatic';
    let a = document.createElement('a');
    a.download = filename;
    a.href = pdf1;
    a.click();
  }

  return (
    <footer id="footer">
      <div className="wrapper">
        <div className="about">
          <div className="text">
            <img src={logo} alt="logo" className="logo" />
            <p className="para">
              Artivatic’s AI- Native SaaS Solutions for insurance & health
              services as modular building blocks powered by best-in-class API
              gateway helps in driving innovation & collaboration with intuitive
              workflows, seamless integration, data driven decisions, individual
              level risk assessment, new age product offerings, dynamic pricing,
              automation, smart underwriting, smart claims processing, enhanced
              customer experience, persistence & collections, distributions with
              AI, ML & Data.
            </p>
            <p className="para">Artivatic is now part of larger Family with RenewBuy.</p>
            <img src={logo2} alt="logo" className="logor" />
            <p className="para">
              RenewBuy is a disruptive InsurTech player providing choice and convenience to customers buying Health,
              Life and Motor Insurance products, delivered through a trusted network of POSP (Point of Sales Person)
              advisors. RenewBuy has grown exponentially, because of its unique model of combining a complete digital
              experience for customers; to avail choice of insurers, comparison on price and features, and advice from
              trained, certified POSP advisors; from buying to renewals and servicing for claims. The entire experience
              is paperless and contactless and policy is issued instantly.
            </p>

          </div>
          <div className="connect flex flex-jc-sb flex-ai-c">
            <h3>Connect</h3>
            <p>-</p>
            <a target='_blank' href='https://www.facebook.com/artivatic' className="icon FB-icon"></a>
            <a target='_blank' href='https://www.linkedin.com/company/artivatic' className="icon LD-icon"></a>
            <a target='_blank' href='https://www.youtube.com/channel/UCErCyc2yw9zKPuLm3WrANZQ' className="icon YT-icon"></a>
            <a target='_blank' href='https://www.instagram.com/artivatic.ai/' className="icon IG-icon"></a>
            <a target='_blank' href='https://twitter.com/artivatic' className="icon TW-icon"></a>
            <a target='_blank' href='https://www.slideshare.net/artivatic' className="icon VC-icon"></a>
          </div>
          <div className="imgs flex flex-jc-sb">
            <img src={ssl} alt="ssl" className="ssl" />
            <img src={iso} alt="iso" className="iso" />
            <img src={hippa} alt="hippa" className="hippa" />
          </div>
        </div>
        <div className="grid">
          <div className="col-1 flex flex-col">
            <div className="products links">
              <h2>Products</h2>
              <a href="/products/ausis">AUSIS</a>
              <a href="/products/aspire">ASPIRE</a>
              <a href="/products/infrd">INFRD</a>
              <a href="/products/alfred">ALFRED</a>
              <a href="/products/mio">MIO</a>
              <a href="/products/prodx">PRODX</a>
              <a href="/products/early-claims">Early Claims</a>
              <a href="/products/preissuance-verification">Pre Issuance Verification Calls(PIVC)</a>
              <a href="/products/secondQuote-engine">SecondQuote Engine</a>
              <a href="/products/inspection-systems">Inspection Systems</a>
              <a href="/products/ocr-icr">OCR & ICR</a>
              <a href="/products/provider-network">Provider Network</a>
            </div>
            <div className="solutions links">
              <h2>Solutions</h2>
              <a href="/">Life Insurance</a>
              <a href="/solutions/health-insurance">Health Insurance</a>
              <a href="/solutions/sme-insurance">SME Insurance</a>
              <a href="/solutions/distributors-brokers">Distributors & Brokers</a>
              <a href="/solutions/sales-marketing">Sales & Marketing</a>
              <a href="/">Reinsurance</a>
              <a href="/solutions/third-party-administrator">TPAs</a>
              <a href="/solutions/hospitals-clinics">Hospitals & Clinics</a>
              <a href="/">Micro Insurance</a>
              <a href="/">Group Health</a>
              <a href="/">Enterprise & Fintech</a>
              <a href="/">OEM Customers & CRM</a>
              <a href="/">Garages</a>
              <a href="/">Insurance in a Box</a>
            </div>
          </div>

          <div className="col-2 flex flex-col">
            <div className="company links">
              <h2>Company</h2>
              <a href="/about-us">About Artivatic</a>
              <a href="https://www.renewbuy.com/" target="_blank">About RenewBuy</a>
              <a href="/customers">Customers</a>
              <a href="/partners">Partners</a>
              <a href="/contact-us">Contact Us</a>
              <a href="/career">Career</a>
              <a onClick={download}>
                <span>Company Disclosure</span>
                <img src={pdficon} />
              </a>
            </div>
            <div className="covid-19 links">
              <h2>Covid-19 Tools</h2>
              <a href="/">AI Based Video KYC</a>
              <a href="/">Video Conferencing</a>
              <a href="/">Chat & Branch</a>
              <a href="/">Video App for Agent & POS</a>
              <a href="/">CRM & Lead Management</a>
              <a href="/">Remote Customer Support</a>
            </div>
            <div className="data-privacy links">
              <h2>Data & Privacy</h2>
              <a href="/data-security/1">Privacy Policies</a>
              <a href="/data-security/2">Terms and Conditions</a>
              <a href="/data-security/3">Security</a>
              <a href="/data-security/4">Disclaimer</a>
              <a href="/data-security/5">Cookies Policy</a>
              <a href="/data-security/6">Cancellation</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

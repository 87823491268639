import React from "react";
import "./section14.css";
export default function Section14(props) {
  const { title1, button1, title2, button2, investor } = props;
  return (
    <section className={`last-section ${investor ? 'inv-sec' : ''}`}>
      <div className="wrapper">
        <div className="col-md-6">
          <div className="box img1">
            {title1 && (<h2>{title1}</h2>)}
            <div className="line"></div>
            {button1 && (
              <a href={button1.Url}>{button1.Text}</a>
            )}

          </div>
        </div>
        <div className="col-md-6">
          <div className={`box img2  ${investor ? 'inv-img' : ''}`}>
            {title2 && (<h2 className=''>{title2}</h2>)}
            <div className="line"></div>
            {button1 && (
              <a href={button2.Url}>{button2.Text}</a>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
import React, { useState } from "react";
import './Nav.css';
import { Link } from "react-router-dom";

const Nav = (props) => {
  const { secNav, setSecNav } = props

  const handleChange = (nav) => {
    setSecNav(nav)
  }

  return (
    <div className="nav--bottom">
      <Link to={'/data-security/1'} className={`nav--item ${secNav === 1 ? 'active' : ''}`} onClick={() => handleChange(1)}>Privacy Policies</Link>
      <Link to={'/data-security/2'} className={`nav--item ${secNav === 2 ? 'active' : ''}`} onClick={() => handleChange(2)}>Terms and Conditions</Link>
      <Link to={'/data-security/3'} className={`nav--item ${secNav === 3 ? 'active' : ''}`} onClick={() => handleChange(3)}>Security</Link>
      <Link to={'/data-security/4'} className={`nav--item ${secNav === 4 ? 'active' : ''}`} onClick={() => handleChange(4)}>Disclaimer</Link>
      <Link to={'/data-security/5'} className={`nav--item ${secNav === 5 ? 'active' : ''}`} onClick={() => handleChange(5)}>Cookies Policy</Link>
      <Link to={'/data-security/6'} className={`nav--item ${secNav === 6 ? 'active' : ''}`} onClick={() => handleChange(6)}>Cancellation</Link>
    </div>
  )
}

export default Nav;
import React from "react";
import {Helmet} from 'react-helmet-async';
import BoxCard from "../../components/BoxCard/boxcard";
import greenShade from "../../assets/vector/green-shade.svg";
import customerSvc from "../../assets/png/customer-service.png";
import officeMap from "../../assets/png/office-map.png";
import Carousel1 from "../../components/Carousel1/Carousel1.js";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import "./contact.css";
import contactImg from "../../assets/png/contact-us-footer.png"
import ContactForm from "../../components/ContactForm/ContactForm";
import conIndia from "../../assets/png/Rectangle 108.png";

export default function Contact() {
    var courosalProperties = {
        navbutton: "backgroundnone greenicon",
        carouselClass: "margin-left-none"
    }
    var contactDetails = ["India: 08049719935"];
    var courosalValues = [];
    courosalValues.push({
        type: "contact",
        title: "Artivatic (Bangalore)",
        text: "15, Old No.2950, D-Block, Gayathri Nagar, Rajajinagar, Bangalore, 560010, Karnataka",
        telephn: '08049719935',
        mail: 'contact@artivatic.ai',
        cardImg: conIndia
    })
    courosalValues.push({
        type: "contact",
        title: "Artivatic (Gurgaon)",
        text: "Plot No. 94, First Floor, RenewBuy Building, Sector-32, Gurgaon- 122001",
        telephn: '18004197852',
        mail: 'contact@artivatic.ai',
        cardImg: conIndia
    })
    courosalValues.push({
        type: "contact",
        title: "Artivatic (Mumbai)",
        text: "R City Building, 8th Floor, RCity Offices (Awfis), Lal Bahadur Shastri Marg, adjoining RCity Mall, Ghatkopar West, Mumbai, Maharashtra 400086",
        telephn: '18004197852',
        mail: 'contact@artivatic.ai',
        cardImg: conIndia
    })
    courosalValues.push({
        type: "contact",
        title: "Artivatic (Indore)",
        text: "Lower Ground, Red Square, 6/4 & 19 PU3 Commercial Scheme No 54, AB Road, Vijay Nagar,  Indore, MP. 452010",
        telephn: '18004197852',
        mail: 'contact@artivatic.ai',
        cardImg: conIndia
    })


    return (
        <>
            <Helmet>
                <title>Artivatic | Contact</title>
                <meta name="description" content="Get in Touch. Connect With One of our Global offices."/>
                <meta name="keywords"
                      content="Artivatic Contact,Sales Enquiry,Customer Service,Headquarters,Global Offices,Get Demo.Press & Media Enquiries,Support,Tech Support."/>
                <link rel="canonical" href={`/contact-us`}/>

                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`https://artivatic.ai/contact-us`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={`Artivatic | Contact`}/>
                <meta property="og:description" content={`Get in Touch. Connect With One of our Global offices.`}/>
                <meta property="og:image" content={`https://artivatic.ai/static/media/customer-service.81500308.png`}/>
                <meta property="og:image:width" content='1200'/>
                <meta property="og:image:height" content='630'/>

                {/* Twitter Meta Tags  */}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="twitter:domain" content="artivatic.ai"/>
                <meta property="twitter:url" content={`https://artivatic.ai/contact-us`}/>
                <meta name="twitter:title" content={`Artivatic | Contact`}/>
                <meta name="twitter:description" content={`Get in Touch. Connect With One of our Global offices.`}/>
                <meta name="twitter:image" content={`https://artivatic.ai/static/media/customer-service.81500308.png`}/>
                <meta property="og:image:width" content='1200'/>
                <meta property="og:image:height" content='630'/>

                {/* Organisation Schema   */}
                <script type="application/ld+json">
                    {`{
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Artivatic.ai",
              "alternateName": "Artivatic",
              "url": "https://artivatic.ai/",
              "logo": "https://artivatic.ai/favicon.jpeg",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 8431969670",
                "contactType": "customer service",
                "areaServed": ["IN","US"],
                "availableLanguage": "en"
              },
              "sameAs": [
                "https://www.facebook.com/artivatic",
                "https://twitter.com/artivatic",
                "https://www.instagram.com/artivatic.ai/?hl=en",
                "https://www.youtube.com/channel/UCErCyc2yw9zKPuLm3WrANZQ",
                "https://www.linkedin.com/company/artivatic"
              ]
            }`
                    }
                </script>
            </Helmet>
            <div id="contact">
                <section className="getintouch">
                    <div className="banner">
                        <div className="leftdiv">
                            <h1 className="head1">Get in touch</h1>
                            <h1 className="description desktop">
                                Want to get in touch?We’d love to hear from you.Here’s how you can reach us...
                                <br/><br/>
                                If you want to know more about our products, features, pricing or anything at all, we
                                are here to answer your questions.
                            </h1>
                        </div>
                        <img src={customerSvc} className="service-img" alt=''></img>
                        <label className="description mobile">
                            Want to get in touch?We’d love to hear from you.Here’s how you can reach us...
                            <br/><br/>
                            If you want to know more about our products, features, pricing or anything at all, we are
                            here to answer your questions.
                        </label>
                        <img src={greenShade} className="green-right-shade" alt=''/>
                    </div>
                </section>
                <ContactForm
                    id={'ContactForm'}
                    title={{
                        type: 'spanend',
                        Text: 'Get started with your personalised ',
                        Span: 'Demo'
                    }}
                    contactImg={{
                        src: contactImg
                    }}
                    classes={{
                        titleClass: 'w-25ch'
                    }}
                />
                <section className="contactdetails">
                    <div className="wrapper">
                        <div className="dot-grid-1"></div>
                        <div className="dot-grid-2"></div>
                        <BoxCard
                            className="marginleft"
                            title="Sales Enquiries"
                            text="Interested in any of our products? Talk to our experts today for demo & integration."
                            ancLink="/"
                            contactNo={contactDetails}
                            btnText="contact@artivatic.ai"
                        ></BoxCard>
                        <BoxCard
                            className="marginright"
                            title="Support Enquiries"
                            text="Interested in any of our products? Talk to our experts today for demo & integration."
                            ancLink="/"
                            contactNo={contactDetails}
                            btnText="contact@artivatic.ai"
                        ></BoxCard>
                        <BoxCard
                            className="marginleft"
                            title="Business & Partners"
                            text="Interested in any of our products? Talk to our experts today for demo & integration."
                            ancLink="/"
                            contactNo={contactDetails}
                            btnText="contact@artivatic.ai"
                        ></BoxCard>
                        <BoxCard
                            className="marginright"
                            title="Press & Media Enquiries"
                            text="Interested in any of our products? Talk to our experts today for demo & integration."
                            ancLink="/"
                            contactNo={contactDetails}
                            btnText="contact@artivatic.ai"
                        ></BoxCard>
                    </div>

                </section>
                <section className="globalOffices">
                    <div className="wrapper wp">

                        <h1 className="title">
                            <span style={{color: "black"}}>Connect with one of our </span>Global offices
                        </h1>
                        <img src={officeMap} className="map" alt=''></img>
                        <Carousel1 id="contactus__carousel" values={courosalValues} properties={courosalProperties}
                        />
                    </div>
                </section>
                <NewsLetter
                    title="View case study of our products and customers"
                    btn1text="View Case Study"
                    btn2text="About Artivatic" subscribeBtn="true"
                    btn2Route="/about-us"
                    btn1Route="/"
                />
            </div>
        </>
    );
}

import React from "react";
import { Helmet } from 'react-helmet-async';

import "./purchaseorder.css";


export default function PurchaseOrder() {



    return (
        <>
            <Helmet>
                <title>Artivatic | Purchase Order</title>
            </Helmet>
            <div id="purchaseorder">
                <h3 className="pagehead">Purchase Order Terms and Conditions</h3>
                <div className="subhead">
                    <p>Each Purchase Order placed by buyer for goods and/or services is subject to these
                        standard purchase terms except if otherwise specified. Supplier shall be deemed to have agreed to be bound by such terms by accepting the Purchase Order, delivering the goods, and/or performing the services in the absence of any governing Agreement.
                    </p>
                </div>
                <div className="mainsec">
                    <div className="first">
                        <p>1. <span className="fwbold">Definitions.</span> In these Standard Purchase Terms, the following definitions apply:</p>
                        <ol type="a">
                            <li>“Agreement” means the agreement between Supplier and Buyer for the
                                purchase and sale of Goods and/or Services.</li>
                            <li>“Buyer” means Artivatic Data Labs Private Limited which has its registered office at No. 15, Old No. 2950, D Block, Gayathrinagar, Rajajinagar,Bengaluru – 560010, Karnataka.</li>
                            <li>“Deliverable” means any deliverable or other product or result from Services that is referred to in a Purchase Order, and any related materials, data,documentation, and includes any Intellectual Property Rights developed by Supplier pursuant to such Purchase Order.</li>
                            <li>“Delivery Date” means the date of delivery for Goods or performance of
                                Services as specified in a Purchase Order.</li>
                            <li>“Delivery Point” means the location identified by Buyer in the Purchase Order to which the Supplier is to deliver Goods and/or perform the services, or such other delivery area or point which is specified in writing by Buyer.</li>
                            <li>“Goods” means the goods that are required to be delivered by Supplier
                                pursuant to a Purchase Order, and include all materials, component parts,packaging and labelling of such goods.</li>
                            <li>“Intellectual Property Rights” means all intellectual and industrial property
                                rights and rights of a similar nature including all rights in and to, patents
                                including all issued patents and pending applications therefore and patents
                                which may be issued therefrom (including divisions, reissues, re-
                                examinations, continuations and continuations-in-part); trade-marks;
                                copyrights; industrial design rights; rights pertaining to trade secrets and
                                confidential information; publicity rights; personality rights; moral rights; and
                                other intellectual property rights whether registered or not and all applications,
                                registrations, renewals and extensions pertaining to the foregoing.</li>
                            <li>“Purchase Order” means the purchase order between Buyer and Supplier for
                                the purchase and sale of Goods and/or Services, to which these Standard
                                Purchase Terms are attached or are incorporated by reference.</li>
                            <li>“Services” means any services to be provided by Supplier to Buyer pursuant to a Purchase Order.</li>
                            <li>“Specifications” means the requirements, attributes and specifications for the
                                Goods or Services that are set out in the applicable Purchase Order.
                                Specifications also include: (a) documentation published by Supplier relating to the Goods or Services; (b) operational and technical features and
                                functionality of the Goods or Services; (c) standards or levels of service
                                performance for Services; and (d) Buyer business requirements that are
                                expressly set out in a Purchase Order.</li>
                            <li>“Supplier” means the party indicated on the face page of the Purchase Order
                                that is contracting with Buyer for the purchase and sale of Goods and/or
                                Services.</li>
                            <li>“Supplier Proposal” means any acknowledgement, estimate, quote, offer to
                                sell, invoice, or proposal of Supplier relating to the supply of Goods and/or
                                Services to Buyer, including any delivered in connection with a request for
                                quotations, request for proposal or similar process initiated by Buyer.</li>
                            <li>“Warranty Period” means in respect of any Goods or Services, the longer of:
                                (i) the express written warranty period provided by Supplier for the Goods or
                                Services; and (ii) the period commencing on the date of Acceptance of such
                                Goods or Services and ending on the date that is one (1) year from that date.</li>
                        </ol>
                    </div>
                    <div className="first">
                        <p>2. <span className="fwbold">Agreement.</span> The Agreement consists only of: (a) these Standard Purchase Terms; and
                            (b) any Specifications or other documents expressly referenced in the Purchase Order.
                            Any reference in the Purchase Order to any Supplier Proposal is solely for the purpose
                            of incorporating the descriptions and specifications of the Goods and/or Services
                            contained in the Proposal, and only to the extent that the terms of the Supplier
                            Proposal do not conflict with the descriptions and Specifications set out in the
                            Purchase Order. Buyer’s acceptance of, or payment for, Goods and/or Services will
                            not constitute Buyer’s acceptance of any additional or different terms in any Supplier
                            Proposal, unless otherwise accepted in writing by Buyer. If there is any conflict or
                            inconsistency between the documents constituting the Agreement, then unless
                            otherwise expressly provided, the documents will rank in the order of precedence in
                            accordance with the order in which they are listed in this Section 2.</p>
                    </div>
                    <div className="first">
                        <p>3. <span className="fwbold">Delivery of Goods and Services.</span></p>
                        <ol type="a">
                            <li>Supplier agrees to supply and deliver the Goods to Buyer and to perform the
                                Services, as applicable, on the terms set out in this Agreement.</li>
                            <li>Supplier shall, at its own expense, pack, load, and deliver Goods to the
                                Delivery Point and in accordance with the invoicing, delivery terms, shipping,
                                packing, and other instructions printed on the face of the Purchase Order or
                                otherwise provided to Supplier by Buyer in writing. No charges will be
                                allowed for freight, transportation, insurance, shipping, storage, handling,
                                demurrage, cartage, packaging or similar charges unless provided for in the
                                applicable Purchase Order or otherwise agreed to in writing by Buyer.</li>
                            <li>Time is of the essence with respect to delivery of the Goods and performance
                                of Services. Goods shall be delivered and Services performed by the
                                applicable Delivery Date. Supplier must immediately notify Buyer if Supplier
                                is likely to be unable to meet a Delivery Date. At any time prior to the
                                Delivery Date, Buyer may, upon notice to Supplier, cancel or change a
                                Purchase Order, or any portion thereof, for any reason, including, without</li>
                            <li>Title and risk of loss or damage shall pass to Buyer upon receipt of Goods at
                                the Delivery Point, unless otherwise agreed to by the Buyer in writing. Buyer
                                has no obligation to obtain insurance while Goods are in transit from Supplier
                                to the Delivery Point.
                            </li>

                        </ol>
                    </div>
                    <div className="first">
                        <p>4. <span className="fwbold">Inspection; Acceptance and Rejection.</span></p>
                        <ol type="a">
                            <li>All shipments of Goods and performance of Services shall be subject to
                                Buyer’s right of inspection. Buyer shall have <span className="fwbold">ninety (90) days</span>(the
                                “Inspection Period“) following the delivery of the Goods at the Delivery
                                Point or performance of the Services to undertake such inspection, and upon
                                such inspection Buyer shall either accept the Goods or Services
                                (<span className="fwbold">“Acceptance“</span>) or reject them. Buyer shall have the right to reject any Goods
                                that are delivered in excess of the quantity ordered or are damaged or
                                defective. In addition, Buyer shall have the right to reject any Goods or
                                Services that are not in conformance with the Specifications or any term of
                                this Agreement. Transfer of title to Buyer of Goods shall not constitute
                                Buyer’s Acceptance of those Goods. Buyer shall provide Supplier within the
                                Inspection Period notice of any Goods or Services that are rejected, together
                                with the reasons for such rejection. If Buyer does not provide Supplier with
                                any notice of rejection within the Inspection Period, then Buyer will be
                                deemed to have provided Acceptance of such Goods or Services. Buyer’s
                                inspection, testing, or Acceptance or use of the Goods or Services hereunder
                                shall not limit or otherwise affect Supplier’s warranty obligations hereunder
                                with respect to the Goods or Services, and such warranties shall survive
                                inspection, test, Acceptance and use of the Goods or Services.</li>
                            <li>Buyer shall be entitled to return rejected Goods to Supplier at Supplier’s
                                expense and risk of loss for, at Buyer’s option, either: (i) full credit or refund
                                of all amounts paid by Buyer to Supplier for the rejected Goods; or (ii)
                                replacement Goods to be received within the time period specified by Buyer.
                                Title to rejected Goods that are returned to Supplier shall transfer to Supplier
                                upon such delivery and such Goods shall not be replaced by Supplier except
                                upon written instructions from Buyer. Supplier shall not deliver Goods that
                                were previously rejected on grounds of non-compliance with this Agreement,
                                unless delivery of such Goods is approved in advance by Buyer, and is
                                accompanied by a written disclosure of Buyer’s prior rejection(s).
                            </li>


                        </ol>
                    </div>
                    <div className="first">
                        <p>5. <span className="fwbold">Price/Payment Terms.</span> Prices for the Goods and/or Services will be set out in the
                            applicable Order. Price increases or charges not expressly set out in the Purchase
                            Order shall not be effective unless agreed to in advance in writing by Buyer. Supplier
                            will issue all invoices on a timely basis. All invoices delivered by Supplier must meet
                            Buyer’s requirements, and at a minimum shall reference the applicable Purchase
                            Order. Buyer will pay the undisputed portion of properly rendered invoices forty-five
                            (45) days from the invoice date. Buyer shall have the right to withhold payment of
                            any invoiced amounts that are disputed in good faith until the parties reach an agreement with respect to such disputed amounts and such withholding of disputed
                            amounts shall not be deemed a breach of this Agreement nor shall any interest be
                            charged on such amounts. Notwithstanding the foregoing, Buyer agrees to pay the
                            balance of the undisputed amounts on any invoice that is the subject of any dispute
                            within the time periods specified herein.
                        </p>
                    </div>
                    <div className="first">
                        <p>6. <span className="fwbold">Taxes</span> Unless otherwise stated in a Purchase Order, all prices or other payments
                            stated in the Purchase Order are exclusive of any taxes. Supplier shall separately
                            itemize all applicable taxes each on each invoice and indicate on each invoice its
                            applicable tax registration number(s). Buyer will pay all applicable taxes to Supplier
                            when the applicable invoice is due. Supplier will remit all applicable taxes to the
                            applicable government authority as required by applicable laws. Notwithstanding any
                            other provision of this Agreement, Buyer may withhold from all amounts payable to
                            Supplier all applicable withholding taxes and to remit those taxes to the applicable
                            governmental authorities as required by applicable laws.
                        </p>
                    </div>
                    <div className="first">
                        <p>7. <span className="fwbold">Standard GST Clause.</span></p>
                        <ol type="a">
                            <li>The Supplier shall provide a proper invoice in the form and manner prescribed
                                under GST Invoice Rules containing all the particulars mentioned therein.
                                Supplier shall also report the transaction in GSTR 1 return within 30days of
                                raising the invoice as enable the Buyer to claim GST Input credit. In the event
                                that the Supplier fails to provide the invoice in the form and manner
                                prescribed under rules, Buyer shall not be liable to make any payment against
                                such invoice. Notwithstanding anything contained anywhere in the Order, in
                                the event that the input tax credit of the GST charged by Supplier is denied by
                                the tax authorities to Buyer, Buyer shall be entitled to recover such amount
                                from the Supplier by way of adjustment from the next invoice, In addition to
                                the amount of GST, Buyer shall also be entitled to recover interest at the
                                applicable rate and penalty, in case any penalty is imposed by the tax
                                authorities on Buyer.
                            </li>
                            <li>As required by any applicable legislation, where identifiable cost savings are
                                realised by virtue of the enactment of the GST law, those cost savings will be
                                reflected in the calculations of the consideration under this Order and shall be
                                passed on by the Supplier to Buyer.
                            </li>
                            <li>Event of default clause–In the event that the Supplier does not deposit the
                                GST charged on the invoice issued to Buyer or such GST charged on the
                                invoice and paid by Buyer is not reflected in online tax credit ledger on
                                common GSTN portal of the govt.as eligible input tax credit for any reason
                                whatsoever, this Order shall be liable to be terminated with immediate effect
                                and Supplier shall be liable to pay such damages as may be reasonably
                                estimated by Buyer. In the event that the compliance rating prescribed under
                                the GST Act,2017 read with GST Rules,2017 of Supplier falls below
                                prescribed level for any reason whatsoever, this Order shall be liable to be
                                terminated with immediate effect and Supplier shall be liable to pay such
                                damages as may be reasonably estimated by Buyer.</li>
                            <li>Representation and warranties clause–The Supplier represents and warrants
                                that it shall have and maintain in effect level of compliance rating as
                                prescribed by the government.
                            </li>
                            <li>
                                As per the applicable state rules Supplier is responsible to prepare an E way
                                bill for transportation of goods on GST online GST portal. In case Supplier is
                                unable to prepare the E way bill or in case any E way bill is erroneously made
                                and the goods are being detained by the Tax Authorities, in that case Supplier
                                has the responsibility to get the detained goods released and pay for the
                                damages. Buyer can recover for the damages caused in such event.
                            </li>

                        </ol>
                    </div>
                    <div className="first">
                        <p>8. <span className="fwbold">Hazardous Materials.</span> Supplier agrees to provide, upon and as requested by Buyer, to
                            satisfy any applicable laws governing the use of any hazardous substances either of
                            the following: (a) all reasonably necessary documentation to verify the material
                            composition, on a substance by substance basis, including quantity used of each
                            substance, of any Goods, and/or of any process used to make, assemble, use, maintain
                            or repair any Goods; or (b) all reasonably necessary documentation to verify that any
                            Goods and/or any process used to make, assemble, use, maintain or repair any Goods,
                            do not contain, and the Services do not require the use of, any particular hazardous
                            substances specified by Buyer.
                        </p>
                    </div>
                    <div className="first">
                        <p>9. <span className="fwbold">Legal Compliance; Workplace Safety.</span>In carrying out its obligations under the
                            Agreement, including the performance of Services, Supplier shall at all times comply
                            with all applicable laws, regulations, standards, and codes. Supplier shall obtain all
                            applicable permits, licences, exemptions, consents and approvals required for the
                            Supplier to manufacture and deliver the Goods and perform the Services.
                        </p>
                        <ol type="a">
                            <li><span className="fwbold">Product Warranties.</span> Supplier warrants to Buyer that during the Goods
                                Warranty Period all Goods provided hereunder shall be: (i) of merchantable
                                quality; (ii) fit for the purposes intended; (iii) unless otherwise agreed to by
                                Buyer, new; (iv) free from defects in design, material and workmanship; (v) in
                                strict compliance with the Specifications; (vi) free from any liens or
                                encumbrances on title whatsoever; (vii) in conformance with any samples
                                provided to Buyer; and (viii) compliant with all applicable federal, provincial,
                                and municipal laws, regulations, standards, and codes.
                            </li>
                            <li><span className="fwbold">Service Warranties.</span> Supplier shall perform all Services: (i) exercising that
                                degree of professionalism, skill, diligence, care, prudence, judgment, and
                                integrity which would reasonably be expected from a skilled and experienced
                                service provided providing services under the same or similar circumstances
                                as the Services under this Agreement; (ii) in accordance with all Specifications
                                and all Buyer policies, guidelines, by-laws and codes of conduct applicable to
                                Supplier; and (iii) using only personnel with the skills, training, expertise, and
                                qualifications necessary to carry out the Services. Buyer may object to any of
                                the Supplier’s personnel engaged in the performance of Services who, in the
                                reasonable opinion of Buyer, are lacking in appropriate skills or qualifications,
                                engage in misconduct, constitute a safety risk or hazard or are incompetent or
                                negligent, and the Supplier shall promptly remove such personnel from the performance of any Services upon receipt of such notice, and shall not re-
                                employ the removed person in connection with the Services without the prior
                                written consent of Buyer.
                            </li>
                            <li><span className="fwbold">Intellectual Property Warranty.</span>Supplier further warrants to Buyer that at
                                all times all Goods and or Services (including any Deliverables) will not be in
                                violation of or infringe any Intellectual Property Rights of any person.
                            </li>
                            <li><span className="fwbold">Manufacturer Warranties.</span>Supplier shall assign to Buyer all manufacturer’s
                                warranties for Goods not manufactured by or for Supplier, and shall take all
                                necessary steps as required by such third party manufacturers to effect
                                assignment of such warranties to Buyer.
                            </li>


                        </ol>
                    </div>
                    <div className="first">
                        <p>10. <span className="fwbold">Warranty Remedies.</span>
                        </p>
                        <ol type="a">
                            <li>In the event of breach of any of the warranties in Section a or 9.b, and without
                                prejudice to any other right or remedy available to Buyer (including Buyer’s
                                indemnification rights hereunder), Supplier will, at Buyer’s option and
                                Supplier’s expense, refund the purchase price for, or correct or replace the
                                affected Goods, or re-perform the affected Services, within 10 day(s) after
                                notice by Buyer to Supplier of warranty breach. All associated costs, including
                                costs of re-performance, costs to inspect the Goods and/or Services, transport
                                the Goods from Buyer to Supplier, and return shipment to Buyer, and costs
                                resulting from supply chain interruptions, will be borne by Supplier. If Goods
                                are corrected or replaced or Services are re-performed, the warranties in
                                Section 9.a will continue as to the corrected or replaced Goods for a further
                                Goods Warranty Period commencing on the date of Acceptance of the
                                corrected or replaced Goods by Buyer. If Supplier fails to repair or replace the
                                Product within the time periods required above, Buyer may repair or replace
                                the Goods at Supplier’s expense.
                            </li>
                            <li>In the event that any Goods provided by Supplier to Buyer are subject to a
                                claim or allegation of infringement of Intellectual Property Rights of a third
                                party, Supplier shall, at its own option and expense, without prejudice to any
                                other right or remedy of Buyer (including Buyer’s indemnification rights
                                hereunder), promptly provide Buyer with a commercially reasonable
                                alternative, including the procurement for Buyer of the right to continue using
                                the Goods in question, the replacement of such Goods with a non-infringing
                                alternative satisfactory to Buyer, or the modification of such Goods (without
                                affecting functionality) to render them non-infringing.
                            </li>



                        </ol>
                    </div>
                    <div className="first">
                        <p>11. <span className="fwbold">Intellectual Property Rights.</span> All Intellectual Property Rights in and to each
                            Deliverable shall vest in Buyer free and clear of all liens and encumbrances on receipt
                            of payment by Supplier for each Deliverable. To the extent that any Deliverables
                            contain any intellectual property of Supplier, Supplier hereby grants to Buyer a
                            worldwide, royalty-free, non-exclusive, perpetual license to use, copy, modify and
                            distribute such intellectual property as part of the Deliverables. Supplier agrees to
                            provide to Buyer all assistance reasonably requested by Buyer to perfect the rights
                            described herein, including obtaining all assignments and waivers of moral rights necessary or appropriate to vest the entire right, title and interest in such materials in
                            Buyer and its successors and assigns.</p>
                    </div>
                    <div className="first">
                        <p>12. <span className="fwbold">Confidentiality.</span> Supplier shall safeguard and keep confidential any and all
                            information relating to Buyer obtained by it or provided to it by Buyer in connection
                            with this Agreement, and shall use such information only for the purposes of carrying
                            out its obligations under this Agreement.</p>
                    </div>
                    <div className="first">
                        <p>13. <span className="fwbold">Insurance.</span>Supplier represents and warrants to Buyer that it has in place with
                            reputable insurers such insurance policies in coverage amounts that would be
                            maintained by a prudent supplier of goods and services similar to the Goods and
                            Services provided hereunder, including, as applicable, professional errors and
                            omissions liability insurance and comprehensive commercial general liability
                            insurance (including product liability coverage, all-risk contractors’ equipment
                            insurance, and automobile liability insurance). In addition, Supplier will take out and
                            maintain, at its own cost, such insurance policies and coverages as may be reasonably
                            required by Buyer from time to time. Supplier will promptly deliver to Buyer, as and
                            when requested, written proof of such insurance. If requested, Buyer will be named as
                            an additional insured under any such policies. If requested by Buyer, such insurance
                            will provide that it cannot be cancelled, or materially changed so as to affect the
                            coverage provided under this Agreement, without the insurer providing at least 30
                            days prior written notice to Buyer.</p>
                    </div>
                    <div className="first">
                        <p>14. <span className="fwbold">Indemnities.</span>Supplier shall indemnify, defend and hold harmless Buyer, its Affiliates,
                            and their respective officers, directors, employees, consultants, and agents (the
                            <span className="fwbold">“Buyer Indemnified Parties“</span>) from and against any claims, fines, losses, actions,
                            damages, expenses, legal fees and all other liabilities brought against or incurred by
                            the Buyer Indemnified Parties or any of them arising out of: (a) death, bodily injury,
                            or loss or damage to real or tangible personal property resulting from the use of or any
                            actual or alleged defect in the Goods or Services, or from the failure of the Goods or
                            Services to comply with the warranties hereunder; (b) any claim that the Goods or
                            Services infringe or violate the Intellectual Property Rights or other rights of any
                            person; (c) any intentional, wrongful or negligent act or omission of Supplier or any
                            of its Affiliates or subcontractors; (d) Supplier’s breach of any of its obligations under
                            this Agreement; or (e) any liens or encumbrances relating to any Goods or Services.</p>
                    </div>
                    <div className="first">
                        <p>15. <span className="fwbold">Limitation of Liability.</span>EXCEPT FOR SUPPLIER’S OBLIGATIONS UNDER
                            SECTION 14, AND EXCEPT FOR DAMAGES THAT ARE THE RESULT OF THE
                            GROSS NEGLIGENCE OR WILFUL MISCONDUCT OF A PARTY, IN NO
                            EVENT WILL EITHER PARTY BE LIABLE TO THE OTHER PARTY OR ANY
                            OTHER PERSON FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR
                            PUNITIVE DAMAGES, INCLUDING ANY LOST PROFITS, DATA, GOODWILL,
                            OR BUSINESS OPPORTUNITY FOR ANY MATTER RELATING TO THIS
                            AGREEMENT.
                        </p>
                    </div>
                    <div className="first">
                        <p>16. <span className="fwbold">Independent Contractors.</span>Supplier will perform its obligations under the Agreement
                            as an independent contractor and in no way will Supplier or its employees be
                            considered employees, agents, partners, fiduciaries, or joint venturers of Buyer.
                            Supplier and its employees will have no authority to represent Buyer or its Affiliates or bind Buyer or its Affiliates in any way, and neither Supplier nor its employees will
                            hold themselves out as having authority to act for Buyer or its Affiliates.
                        </p>
                    </div>
                    <div className="first">
                        <p>17. <span className="fwbold">Further Assurances.</span>The parties shall sign such further and other documents, cause
                            such meetings to be held, resolutions passed and do and perform and cause to be done
                            and performed such further and other acts and things as may be necessary or desirable
                            in order to give full effect to this Agreement and every part thereof.
                        </p>
                    </div>
                    <div className="first">
                        <p>18. <span className="fwbold">Severability.</span>If any provision of this Agreement is determined to be unenforceable or
                            invalid for any reason whatsoever, in whole or in part, such invalidity or
                            unenforceability shall attach only to such provision or part thereof and the remaining
                            part thereof and all other provisions shall continue in full force and effect.
                        </p>
                    </div>
                    <div className="first">
                        <p>19. <span className="fwbold">Waiver.</span>No waiver of any provision of this Agreement shall be enforceable against
                            that party unless it is in writing and signed by that party.
                        </p>
                    </div>
                    <div className="first">
                        <p>20. <span className="fwbold">Assignment.</span>Supplier may not assign or subcontract this Agreement, in whole or in
                            part, without Buyer’s prior written consent. Supplier’s permitted assignment or
                            subcontracting of this Agreement or any part thereof will not release Supplier of its
                            obligations under this Agreement, and it will remain jointly and severally liable with
                            the assignee or subcontractor for any obligations assigned or subcontracted. The acts
                            of omissions of any subcontractors of Supplier will be deemed to be the acts and
                            omissions of the Supplier. Buyer may assign this Agreement, in whole or in part, to
                            any Affiliate of Buyer, without the consent of Supplier. This Agreement shall enure to
                            the benefit of and be binding upon the parties and their respective legal personal
                            representatives, heirs, executors, administrators, assigns or successors.
                        </p>
                    </div>
                    <div className="first">
                        <p>21. <span className="fwbold">Cumulative Remedies.</span>Subject to Section 15, the rights and remedies of the Buyer in
                            this Agreement are cumulative and in addition to any other rights and remedies at law
                            or in equity.
                        </p>
                    </div>
                    <div className="first">
                        <p>22. <span className="fwbold">Survival.</span>Any provision of this Agreement which expressly or by implication from its
                            nature is intended to survive the termination or completion of the Agreement will
                            continue in full force and effect after any termination, expiry or completion of this
                            Agreement.
                        </p>
                    </div>
                    <div className="first">
                        <p>23. <span className="fwbold">Interpretation.</span>The headings used in this Agreement and its division into articles,
                            sections, schedules, exhibits, appendices, and other subdivisions do not affect its
                            interpretation. Unless the context requires otherwise, words importing the singular
                            number include the plural and vice versa; words importing gender include all genders.
                            References in this Agreement to articles, sections, schedules, exhibits, appendices,
                            and other subdivisions are to those parts of this Agreement. Where this Agreement
                            uses the word “including,” it means “including without limitation,” and where it uses
                            the word “includes,” it means “includes without limitation.
                        </p>
                    </div>
                    <div className="first">
                        <p>24. <span className="fwbold">Governing Law.</span>This Agreement shall be governed by the laws of India. The parties
                            irrevocably attorn to the jurisdiction of the courts of Gurugram, Haryana which will have
                            non-exclusive jurisdiction over any matter arising out of this Agreement.
                        </p>
                    </div>
                    <div className="first">
                        <p>25. <span className="fwbold">Language.</span>It is the express wish of the parties that this Agreement and any related
                            documentation be drawn up in English.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import "./product.css";
import { products } from "./Products-lists";
import RectangleDesk from "../../assets/png/Rectangle 184.png";
import RectangleMob from '../../assets/png/Rectangle 187.png'
import Carousel from "../../components/Carousel/carousel";
import NewsLetterSection from "../../components/NewsLetter/NewsLetter"
import Section12 from "../../components/Section12/Section12.js";
import Section13 from "../../components/section13/section13"
import Banner from "../../components/Banner/Banner";
import blackRightArrowIcon from '../../assets/vector/black-right-icon.svg'
import { useParams, Link } from 'react-router-dom';
import Logomarquee from "../../components/Logomarquee/Logomarquee";

export default function Product() {
  const { name } = useParams();
  const history = useHistory();
  let id;
  switch (name) {
    case 'ausis':
      id = 1;
      break;
    case 'alfred':
      id = 2;
      break;
    case 'aspire':
      id = 3;
      break;
    case 'mio':
      id = 4;
      break;
    case 'infrd':
      id = 5;
      break;
    case 'prodx':
      id = 6;
      break;
    case 'early-claims':
      id = 7;
      break;
    case 'preissuance-verification':
      id = 8;
      break;
    case 'secondQuote-engine':
      id = 9;
      break;
    case 'inspection-systems':
      id = 10;
      break;
    case 'ocr-icr':
      id = 11;
      break;
    case 'provider-network':
      id = 12;
      break;
    case 'fraud-intelligence-system':
      id = 13;
      break;
  }
  const [currentSlide, setCurrentSlide] = useState(0)
  const navigate = () => {
    history.push('/contact-us');
  }
  const handleClick = (number) => {
    setCurrentSlide(number);
  }

  return (
    <>
      <Helmet>
        <title>Products | {products[id].Name}</title>
        <meta name="description" content={`${products[id].Description1}`} />
        <meta name="keywords" content={`${products[id].Name} | Artivatic`} />
        <link rel="canonical" href={`/products/${name}`} />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={`https://artivatic.ai/products/${products[id].Name}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Artivatic | AI Insurance Products | ${products[id].Name}`}/>
        <meta property="og:description" content={`${products[id].Description1}`} />
        <meta property="og:image" content={`${products[id].product_logo}`} />
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>

        {/* Twitter Meta Tags  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="artivatic.ai" />
        <meta property="twitter:url" content={`https://artivatic.ai/products/${products[id].Name}`} />
        <meta name="twitter:title" content={`Artivatic | AI Insurance Products | ${products[id].Name}`} />
        <meta name="twitter:description" content={`${products[id].Description1}`} />
        <meta name="twitter:image" content={`${products[id].product_logo}`} />
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>




        {/* Product Schema  */}
        <script type="application/ld+json">
          {
            `{
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "${products[id].Name}",
              "image": "${products[id].product_logo}",
              "description": "${products[id].Description1}",
              "brand": {
                "@type": "Brand",
                "name": "Artivatic"
              },
              "offers": {
                "@type": "Offer",
                "url": "https://artivatic.ai/products/${name}"
              }
            }`}
        </script>
      </Helmet>
      <div id="product">
        <section className="product">
          <div className="wrapper">
            <div className="leftdiv">
              <h1 className="head">{products[id].Name}</h1>
              <img src={products[id].imgtag} className="rectangle mobile" alt=''></img>
              <h1 className="title">{products[id].Title}</h1>
              <p className="description">{products[id].Description1}</p>

              <button className="demo" onClick={navigate}>Get a Demo</button>
              <a className="learnMore" href={products[id].website_link} target="_blank">Learn More</a>
              <br />
              {products[id].CaseStudy && <label className="casestudy"><p>{products[id].CaseStudy}</p><div className="arrow-icon"><img src={blackRightArrowIcon} alt="" /></div></label>}

            </div>
            <img src={products[id].imgtag} className={`rectangle desktop ${products[id].Name === 'Provider Network' ? 'my_width' : ''}`} alt=''></img>
          </div>
        </section>
        {products[id].investor_logos.length > 0 ? (<section className="partners">
          <div className="wrapper">
            <Logomarquee
              Images={products[id].investor_logos.length > 0 ? products[id].investor_logos : ''}
              dir={'left'}
              speed={50}
              classes={{
                marqueeClass: 'marquee-margin-bottom-40',
              }}
            />
            {/* {products[id] && products[id].SubCategory && products[id].SubCategory.length > 1 && (<Services
            key={products[id]}
            id="from_products"
            title={products[id].SubCategoryTitle}
            list={products[id].SubCategory}
            button1={{
              Text: "Get Demo",
              Url: "/contact-us"
            }}
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
          ></Services>)} */}

          </div>
        </section>) : ''}
        {products[id] && products[id].SubCategory.length > 0 && (<section className="section-8 ">
          <div className="wrapper">
            <h1 className="heading">{`${products[id].Name} Designed for`}</h1>
            <button className="demo-btn btn btn--sm btn--dark" onClick={navigate}>
              <Link to="/contact-us" style={{ color: 'white' }}>Get a demo </Link>
            </button>

            <Carousel
              id="section_8__carousel"
              containerClassName="section_8__carousel__container"
              prevControlClassName="section_8__carousel__prev-control"
              nextControlClassName="section_8__carousel__next-control"
              fade="transparent"
              navColor="#22b966"
              className='section_8__carousel'
              dotNav={false}>
              {products[id] && products[id].SubCategory && products[id].SubCategory.length > 0 &&
                (products[id].SubCategory.map((item, index) => (
                  <button
                    key={index}
                    className="btn-nav btn select"
                    data-name="ausis"
                    onClick={() => handleClick(index)}
                  >
                    {item.name}
                  </button>
                )))}
            </Carousel>
          </div>
        </section>)}
        {products[id].SubCategory.length > 0 && <section className="product-detail">
          <div className="wrapper">
            <img src={((products[id].SubCategory && products[id].SubCategory.length > 0) ? products[id].SubCategory[currentSlide].img : RectangleDesk)} className="rectangle" alt=''></img>
            <div className="leftdiv">
              <h1 className="title">{products[id].Name} {((products[id].SubCategory && products[id].SubCategory.length > 0) ? " - " + products[id].SubCategory[currentSlide].name : "")} </h1>
              <p className="description">{(products[id].SubCategory && products[id].SubCategory.length > 0 && products[id].SubCategory[currentSlide].des ? products[id].SubCategory[currentSlide].des : '')}</p>
              {products[id].SubCategory && products[id].SubCategory.length > 0 && products[id].SubCategory[currentSlide].keyFeaturesData && (
                <ul className={`product_sub_category_listing ${products[id].SubCategory[currentSlide].name === 'SALES' ? 'my_flex' : ''} ${products[id].SubCategory[currentSlide].name === 'APIs' ? 'my_flex' : ''}`}>
                  {products[id].SubCategory && products[id].SubCategory.length > 0 && products[id].SubCategory[currentSlide].keyFeaturesData.map((item, index) => {
                    if (products[id].SubCategory[currentSlide].name === 'SALES' || products[id].SubCategory[currentSlide].name === 'APIs') {
                      return (
                        <li key={index} className="li_width_sales">{item}</li>
                      )
                    }
                    else {
                      return (
                        <li key={index}>{item}</li>
                      )
                    }
                  })}
                </ul>)
              }
              <a href={products[id].website_link} target="_blank"><button className="demo detail-demo">Get to know more</button></a>

            </div>

          </div>
        </section>
        }
        <div className="section13">
          <Section13
            from="product_details_page"
            imageDesk={(products[id].keyFeature ? products[id].keyFeature.deskImg : RectangleDesk)}
            imageMob={(products[id].keyFeature ? products[id].keyFeature.mobImg : RectangleMob)}
            title={
              {
                type: "spanmiddle",
                Text: "",
                span: " Key ",
                Text2: `Features of`,
                Text3: `${products[id].Name}`
              }
            }
            subtitle={products[id].keyFeature && products[id].keyFeature.title && products[id].keyFeature.title}
            // list={
            //   [
            //     { Title: "Title for this feature", Description: "Artivatic’s AI- Native SaaS Solutions for insurance & health services as modular building blocks powered by best-in-class API gateway. New age product offerings, dynamic pricing, automation, smart underwriting, smart " },
            //     { Title: "Title for this feature", Description: "Artivatic’s AI- Native SaaS Solutions for insurance & health services as modular building blocks powered by best-in-class API gateway. New age product offerings, dynamic pricing, automation, smart underwriting, smart " },
            //     { Title: "Title for this feature", Description: "Artivatic’s AI- Native SaaS Solutions for insurance & health services as modular building blocks powered by best-in-class API gateway. New age product offerings, dynamic pricing, automation, smart underwriting, smart " }
            //   ]
            // }
            list={products[id].keyFeature && products[id].keyFeature.data && products[id].keyFeature.data}
            button1={
              {
                Title: 'Request a Demo',
                Url: '/contact-us'
              }
            }
            button2={
              {
                Title: 'View Case study',
                Url: '/contact-us'
              }
            }
            classes={
              {
                sectionClass: 'grey-back',
                button2Class: 'btn-transparent'
              }
            }
            extraInfo={
              {
                Text: 'Request for more information',
                Url: '/contact-us'
              }
            }
          ></Section13>
        </div>
        <Banner
          title="Need to experience the product ?"
          buttonText="Take a quick Product Tour"
          navUrl={products[id].website_link}
          from="product_page"
        ></Banner>
        {products[id].resources_grow_section && <Section12
          title={products[id].resources_grow_section.title}
          tagline={products[id].resources_grow_section.tagline}
          des={products[id].resources_grow_section.des}
          date={products[id].resources_grow_section.date}
          img={products[id].resources_grow_section.img}
          link={products[id].resources_grow_section.link}
          resources={products[id].resources_section}
          from="Product_page"
        ></Section12>
        }

        <NewsLetterSection
          title="Lets build next-gen insurance & health products"
          btn1text="Schedule a Demo"
          btn1Route="/contact-us"
          btn2Route="/contact-us"
          btn2text="Request Pricing"
          subscribeBtn="true"
          mtop={products[id].resources_grow_section ? false : true}></NewsLetterSection>
      </div >
    </>
  );
}

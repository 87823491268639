import React from "react";
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer.js";
import Home from "./containers/Home/Home.js";
import Contact from "./containers/Contact/contact";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import ShowResource from "./containers/Resources/showResource/ShowResource.js";
import Product from "./containers/Product/product.js";
import Team from "./containers/team/team.js";
import Login from "./containers/Login/Login.js";
import Aboutus from "./containers/aboutus/aboutus";
import PurchaseOrder from "./containers/purchaseorder/purchaseorder";
import Investors from "./containers/investors/investors.js";
import Careers from "./containers/Career/career.js"
import JobDetails from "./containers/JobDetails/jobdetails.js";
import JobSubmit from "./containers/JobSubmit/jobsubmit";
import Partners from "./containers/partners/partners.js";
import Customer from "./containers/customer/customer.js";
import NewsAndMedia from "./containers/News and Media/NewsAndMedia";
import DataAndSecurity from "./containers/Data and Security/DataAndSecurity";
import CloudAPI from "./containers/cloudApi/cloud-api";
import Growth from "./containers/growth/growth.js";
import Solutions from "./containers/solutions/solutions.js";
import "./App.css";
import Details from "./containers/Details/Details.js";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.js";
import Resources from "./containers/Resources/Resources.js";
import PageNotFound from "./components/404/PageNotFound.js";
// import covid19 from "./containers/covid19/covid19.js";

function App() {


  return (
    <>
      <div className="App">
        <Router>
          <ScrollToTop />
          <Header />
          <main>
            <div className="generalmargintop">
              <Switch>
                <Route exact path="/" component={Home}></Route>
                <Route exact path={"/contact-us"} component={Contact}></Route>
                <Route exact path={"/teams"} component={Team}></Route>
                <Route exact path={"/about-us"} component={Aboutus}></Route>
                <Route exact path={"/investors"} component={Investors}></Route>
                <Route exact path={"/partners"} component={Partners}></Route>
                <Route exact path={"/career"} component={Careers}></Route>
                <Route exact path={"/purchase-order"} component={PurchaseOrder}></Route>
                <Route exact path={"/job-details/:id"} component={JobDetails}></Route>
                <Route exact path={"/job-submit/:jobid"} component={JobSubmit}></Route>
                <Route exact path={"/customers"} component={Customer}></Route>
                <Route exact path={"/news-media"} component={NewsAndMedia}></Route>
                <Route exact path={"/data-security/:id"} component={DataAndSecurity}></Route>
                <Route exact path={"/cloud-api"} component={CloudAPI}></Route>
                <Route exact path={"/growth"} component={Growth}></Route>
                <Route exact path={"/details"} component={Details} />
                <Route exact path={'/solutions/:type'} component={Solutions} />
                <Route exact path={'/resources/:type'} component={Resources} />
                <Route exact path={'/resources/:type/view/:tag/pdf'} component={ShowResource} />
                <Route exact path={'/user-login'} component={Login} />
                <Route exact path={"/products/:name"} component={Product}></Route>
                <Route exact path={"/page-not-found"} component={PageNotFound}></Route>
                <Route path="*">
                  <Redirect to="/page-not-found" />
                </Route>
                {/* <Route exact path="*" component={PageNotFound}></Route> */}

              </Switch>
            </div>
          </main>

          {/* <Route path={"/covid19"} component={covid19} /> */}
        </Router>
        <Footer />
      </div>
    </>
  );
}

export default App;

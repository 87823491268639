import React from "react";
import { Link } from 'react-router-dom';

import insuranceCloudImg from "../../assets/png/insurance-cloud-desk.webp";
import "./Section4.css";
export default function Section4() {
  return (
    <section className="section-4">
      <div className="wrapper">
        <h1 className="heading-1">The Insurance Cloud</h1>
        <hr className="hr-underline"></hr>
        <div className="imgh">
          <img src={insuranceCloudImg} alt="cloud-img" className="img" />
        </div>
        <h1 className="heading-2">
          <span style={{ color: "var(--primary" }}>400+ APIs </span>available
          for building <span style={{ color: "var(--primary" }}>Next-gen </span>
          Insurtech.
        </h1>
        <Link to="/about-us" className="btn btn--sm btn--toggle">
          Explore More
        </Link>
      </div>
    </section>
  );
}

import React from "react";
import Carousel from "../Carousel/carousel";
import brand1Img from "../../assets/vector/brands.svg"
import brand2Img from "../../assets/vector/brands2.svg"
import brandImg1 from "../../assets/png/brand1.png"
import brandImg2 from "../../assets/png/brand2.png"
import brandImg3 from "../../assets/png/brand3.png"
import brandImg4 from "../../assets/png/brand4.png"
import brandImg6 from "../../assets/png/brand6.png"
import brandImg5 from "../../assets/png/brand5.png"
import investor1 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 7.png'
import investor2 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 3.png'
import investor3 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 4.png'
import investor4 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 2.png'
import investor5 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 6.png'
import investor6 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 1.png'
import investor7 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 5.png'
import investorMain from '../../assets/Logos_Pictures/Investor Images/Renew Buy.png'
import "./section16.css"
import Logomarquee from "../Logomarquee/Logomarquee";
export default function Section16(props) {
  const { list, title, description, investorCarousel, button, classes } = props

  const brandsImg = [
    investor1, investor2, investor3, investor4, investor5, investor6, investor7
  ]

  return (
    <section className="section16">
      <div className="wrapper">
        <div className="row">
          <h1>{title}</h1>
          <hr className="hr"></hr>
          <img src={investorMain} className="investor_img"></img>
          {
            description ? (
              <p>{description}</p>
            ) : ( <p><span className="highme">RenewBuy </span><span> is a disruptive InsurTech player providing choice and convenience to customers buying Health, Life and Motor Insurance products, delivered through a trusted network of POSP (Point of Sales Person) advisors. RenewBuy has grown exponentially, because of its unique model of combining a complete digital experience for customers; to avail choice of insurers, comparison on price and features, and advice from trained, certified POSP advisors; from buying to renewals and servicing for claims. The entire experience is paperless and contactless and policy is issued instantly.</span></p>)
          }
          
          <div className={"row1 " + (classes && classes.row1Class)}>
            {list && list.map((value, index) => {
              return (
                <div className="col-md-2 padding-t-60" key={index}>
                  <img src={value} alt=''></img>
                </div>
              )
            })}
          </div>
          {
            investorCarousel ? (
              // <Carousel
              //   id="partners__carousel"
              //   containerClassName="partners__carousel__container"
              //   prevControlClassName="partners__carousel__prev-control"
              //   nextControlClassName="partners__carousel__next-control"
              //   fade="primary-vl"
              //   navColor="#22b966"
              //   className='product-carousel'
              //   dotNav={false}>
              //   <img src={brand1Img} className="brandImage" alt=''></img>
              //   <img src={brand2Img} className="brandImage" alt=''></img>
              //   <img src={brand2Img} className="brandImage" alt=''></img>
              // </Carousel>
              <>
                <Logomarquee
                  Images={brandsImg}
                  dir={'left'}
                  speed={50}
                  classes={classes}
                />
                <Logomarquee
                  Images={brandsImg}
                  dir={'right'}
                  speed={50}
                  classes={classes}
                />
              </>
            ) : ('')
          }
          {
            investorCarousel ? (
              <a href={button.Url} className="investor-link">{button.Text}</a>
            ) : ('')
          }
        </div>
      </div>
    </section>
  )
}
import React from "react";

import authImg from "../../assets/png/testimonial/person.png";
import testaklesh from '../../assets/png/testimonial/aklesh-singh.webp'
import testyogesh from '../../assets/png/testimonial/yogesh-R1RCM.webp'
import sbilife from '../../assets/png/testimonial/person1.jpg'
import bandhan from '../../assets/png/testimonial/Person2.jpg'
import testjatin from '../../assets/png/testimonial/Customer Testimonial Picture - Jatin Varshney.webp'
import greenGrid from "../../assets/vector/green-grid.svg";
import TestimonialCard from "../TestimonialCard/TestimonialCard";
import Carousel from "../../components/Carousel/carousel.js";
import "./Testimonial.css";
export default function Testimonial({ title, classes }) {

  return (
    <section className="testimonial-section">
      <div className="wrapper">
        {title && title.type === 'doublespan' && (
          <h1 className={"heading" + (classes ? classes.titleClass : '')}>
            <span style={{ color: "var(--primary)" }}>{title.Span1}</span>
            {title.Text1}
            <span style={{ color: "var(--primary)" }}>{title.Span2}</span>
            {title.Text2}
          </h1>
        )}
        {title && title.type === 'spanend' && (
          <h1 className={'heading' + (classes ? classes.titleClass : '')}>
            {title.Text1}
            <span style={{ color: "var(--primary)" }}>{title.Span1}</span>
          </h1>
        )

        }
        <h3>“See what our customers say”</h3>
        <hr className="hr-underline" />
        <div className="container">
          <img src={greenGrid} alt="green-grid" className="green-grid" />
          <div className="content">
            <Carousel
              id="testimonial__carousel"
              fade="transparent"
              navColor="#22b966"
              dotNav={true}
            >
              <TestimonialCard
                imgSrc={sbilife}
                name="Waman Gawde"
                designation="Senior VP & Head New Business, Underwriting, Claims Risk & FRM"
                address="SBI Life Insurance"
                text="“SBI Life Insurance has engaged Artivatic for Intelligent Document Processing using AI/ML Tech to assist in
                Medical Underwriting. The Solution deployed has been professional & smooth. We are satisfied & happy
                with the same”"
              />
              <TestimonialCard
                imgSrc={bandhan}
                name="Sandeep Kushwaha"
                designation="Senior Vice President & Head Analytics"
                address=" Aegon (Bandhan) Life Insurance"
                text="“The performance of the alternative Underwriting services are satisfactory and there are no adverse
                observations as regards to the performance of the services rendered by the Artivatic Data Labs Private
                Limited. Their services are prompt and complete..”"
              />
              <TestimonialCard
                imgSrc={testaklesh}
                name="Aklesh Singh"
                designation=" Innovation Manager"
                address="ICICI Prudential Life Insurance"
                text="“Artivatic’s product has shown promising results during the pilot phase. We were largely successful in decision making and risk profiling of new customers using their digital footprints and technology to read and access health records. Continuously working further with Artivatic to build new solutions to innovate insurance services”"
              />
              <TestimonialCard
                imgSrc={testyogesh}
                name="Yogesh Ingale"
                designation="Vice President"
                address="Product & Technology at R1 RCM"
                text="“I sincerely appreciate your efficient, gracious customer service, the level of detail and accountability you have demonstrated during ‘Proof of Concept’ project, and the way you conduct business as a whole. I look forward to continuing this relationship and wish luck for all your future endeavors.”"
              />
              <TestimonialCard
                imgSrc={testjatin}
                name="Jatin Varshney"
                designation="Senior Vice President & Head - Underwriting And Claims"
                address="Aditya Birla Sun Life Insurance"
                text="“This is to confirm that we are working hand in hand with Artivatic.ai on underwriting automation, with AUSIS and ProdX products customized for ABSLI. The project is making steady progress, and we are satisfied with the support that we are getting from the Artivatic team.”"
              />
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { Helmet } from 'react-helmet-async';
import "./investors.css";
import img from "../../assets/png/Rectangle 184.png";
import IntroductionSection from "../../components/IntroductionSection/IntroductionSection";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import Section14 from "../../components/section14/section14";
import teamImg1 from "../../assets/Logos_Pictures/Investors and Board Members/Layak Singh.png"
import teamImg2 from "../../assets/Logos_Pictures/Investors and Board Members/Puneet.png"
import teamImg3 from "../../assets/Logos_Pictures/Investors and Board Members/Abhijeet Kanetkar.png"
import teamImg4 from "../../assets/Logos_Pictures/Investors and Board Members/Devesh Joshi.png"
import teamImg5 from "../../assets/Logos_Pictures/Investors and Board Members/Sulbha Rai.png"
import Section15 from "../../components/section15/section15";
import Section16 from "../../components/section16/section16";
import brandImg1 from "../../assets/png/brand1.png"
import brandImg2 from "../../assets/png/brand2.png"
import brandImg3 from "../../assets/png/brand3.png"
import brandImg4 from "../../assets/png/brand4.png"
import brandImg5 from "../../assets/png/brand5.png"
import brandImg6 from "../../assets/png/brand6.png"
import investor1 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 7.png'
import investor2 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 3.png'
import investor3 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 4.png'
import investor4 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 2.png'
import investor5 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 6.png'
import investor6 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 1.png'
import investor7 from '../../assets/Logos_Pictures/Investor Images/Artivatic Investors Logo - 5.png'
import investorImg from '../../assets/Logos_Pictures/Board and Investor page.jpg'

export default function Investors() {
  var description = "Artivatic makes all your tools work better by letting you integrate \n industry-leading software and custom apps."
  var staffList = [
    {
      type: "tag",
      name: "Layak Singh",
      Designation: "Chief Executive Officer",
      Image: teamImg1
    },
    {
      type: "tag",
      name: "Puneet Tandon",
      Designation: "Chief Operating Officer",
      Image: teamImg2
    },
    {
      type: "tag",
      name: "Devesh Joshi",
      Designation: "Board Member & Director",
      Image: teamImg4
    },
    {
      type: "tag",
      name: "Sulbha Rai",
      Designation: "Board Member & Director",
      Image: teamImg5
    },
  ];
  return (
    <>
      <Helmet>
        <title>Artivatic | Investors And Board Members</title>
        <meta name="description" content="Meet Our Founders and Borad Members" />
        <meta name="keywords" content="Artivatic Investors,Founder and Board Members,RenewBuy."/>
        <link rel="canonical" href="/investors" />

         {/* Facebook Meta Tags */}
         <meta property="og:url" content={`https://artivatic.ai/investors`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Artivatic | Investors And Board Members`}/>
        <meta property="og:description" content={`Meet Our Founders and Borad Members`} />
        <meta property="og:image" content={`https://artivatic.ai/static/media/Renew%20Buy.98567f49.png`} />
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>

        {/* Twitter Meta Tags  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="artivatic.ai" />
        <meta property="twitter:url" content={`https://artivatic.ai/investors`} />
        <meta name="twitter:title" content={`Artivatic | Investors And Board Members`} />
        <meta name="twitter:description" content={`Meet Our Founders and Borad Members`} />
        <meta name="twitter:image" content={`https://artivatic.ai/static/media/Renew%20Buy.98567f49.png`} />
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>



      </Helmet>
      <div id="investors">
        <IntroductionSection
          title={{ type: "nospan", Text: "Artivatic products are super effective omnichannel marketing strategies and testing like no one else. Artivatic’s product has shown promising results during the pilot phase. " }}
          description={{ Text1: "Hannibal Mcdaniel - Product Manager & VP Product Management at Google" }}
          button1={{ Text: "Get in Touch Now", Url: "" }} image={investorImg}
          classes={{
            titleClass: 'quotes order-2 mt-60',
            imageClass: "order-1",
            divClass: 'prod-width',
            descriptionClass: 'order-3',
            buttonsClass: 'order-4',
          }}
        >
        </IntroductionSection>
        <Section16
          // list={[
          //   investor1, investor2, investor3, investor4, investor5, investor6, investor7
          // ]}
          title="Our Investors"
          // description={description}
          classes={{
            row1Class: 'padding-lr-88 gap-30 fl-justify-center'
          }}
        ></Section16>
        <Section15
          staffList={staffList}
          title="Meet Our Founder and Board"
        ></Section15>
        <Section14
          title1="We provide personalized insurance products based on customer goals"
          button1={{ Text: "Know more about Atrivatic", Url: "/about-us" }}
          title2="Wanna know about the work culture at Artivatic officie ?"
          button2={{ Text: "Check out our Work Culture", Url: "/teams" }}
          investor={true}
        >
        </Section14>
        <NewsLetter
          title="Do you want to be a part of our Investors community?"
          btn1text="Get in Touch"
          btn1Route="/contact-us"
        >
        </NewsLetter>
      </div >
    </>
  )
}
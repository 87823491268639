import React from 'react';
import './Nav.css';

const Nav = (props) => {
  const { mediaSelected, setMediaSelected } = props

  const changeMedia = (type) => {
    setMediaSelected(type)
  }
  // console.log(mediaSelected);
  return (
    <nav className="navigation">
      <button className={`nav-link ${mediaSelected === 'All' ? 'nav-link--active' : ''}`} onClick={() => changeMedia('All')}>All</button>
      <button className={`nav-link ${mediaSelected === 'News' ? 'nav-link--active' : ''}`} onClick={() => changeMedia('News')}>News</button>
      <button className={`nav-link ${mediaSelected === 'Press Release' ? 'nav-link--active' : ''}`} onClick={() => changeMedia('Press Release')}>Press Release</button>
     
    </nav>
  )
}

export default Nav;
import React from "react";

// import img from "../../assets/png/intro-section.png";
import "./section17.css";

export default function Section17(props) {
  const { title, description, lineStyle, list} = props
  return (
    <section className="work-value">
      <div className="wrapper">
        <div className="dot-grid-1"></div>
        <div className="dot-grid-2"></div>
        <div className="col-4">
          {title && (<h1>{title.Text}
            <span>{title.span}</span>
            {title.rest}
          </h1>
          )}
          <div className={'line ' + (lineStyle ? lineStyle : '')}></div>
          {description && (<p>{description}</p>)}
        </div>
        { list && list.map((value, index) => {
          return (
            <div key={index} className={'col-4 ' + (value.className ? value.className : '')}>
              <div className="box">
                <span></span>
                <h3>{value.Title}</h3>
                <p>{value.Description}</p>
              </div>
            </div>
          )
        })}
        
      </div>
    </section>
  )
}